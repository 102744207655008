<template>
<div class="pb-5">
  <div class="row">
    <div class="col-xl-12">
      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label">Titulo del estudio</label>
        <div class="col-lg-9 col-xl-9">
          <input class="form-control form-control-lg form-control-solid" name="title" type="text" placeholder="Nuevo estudio" :value="study.title" @input="UpdateFieldStudy"/>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {resetForm} from '@components/admin/study/register/formRegister.js'
import {mapState, mapActions} from 'vuex'
import {trim} from 'lodash'

export default {
  computed: {
    ...mapState({
      study: ({study}) => study.newStudy
    }),
  },
  methods: {
    ...mapActions('global', ['EditFieldState']),

    UpdateFieldStudy(e) {
      this.EditFieldState({
        state: 'study',
        type:'newStudy',
        field: e.target.name,
        value: trim(e.target.value),
      })
    }
  },
  deactivated(){
    resetForm()
  }
};
</script>
