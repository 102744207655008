/* Importaciones */
import { createRouter, createWebHistory } from 'vue-router'

import routes from './routes' // Llamado a las rutas

const history = createWebHistory(process.env.BASE_URL) /*
Activamos el modo 'history' con la url base */

const router = createRouter({ history, routes }) /* Creamos
e instanciamos las rutas con las opciones*/

export default router /* Exportamos para ser usado
en el 'main.js' en el inicio de la app */
