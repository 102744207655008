<template>
<div class="card card-custom">
  <div class="card-header flex-wrap py-5">
    <div class="card-title">
      <h3 class="card-label">Column Rendering
        <div class="text-muted pt-2 font-size-sm">custom colu rendering</div>
      </h3>
    </div>
    <div class="card-toolbar">
      <router-link :to="{ name:'register-study-program'}" class="btn btn-primary font-weight-bolder" tag="a">
        <i class="la la-plus"></i>Agregar nueva cuenta
      </router-link>

      <button type="button" class="btn btn-danger font-weight-bolder ml-2" v-show="selected.length > 0" data-toggle="modal" data-target="#staticBackdrop" >
        <i class="la la-trash"></i>Eliminar
      </button>

      <div class="modal fade" id="staticBackdrop" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Confirmación</h5>
            </div>
            <div class="modal-body">
              ¿ Desea eliminar los tipos de cuentas seleccionados ?.
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-light-primary font-weight-bold" data-dismiss="modal">No</button>
              <button type="button" class="btn btn-primary font-weight-bold" data-dismiss="modal" id="delete-selected" @click="DeleteStudyPrograms">Si</button>
            </div>
          </div>
        </div>
      </div>

		</div>
  </div>
  <div class="card-body">
      <table class="table table-separate table-checkable display responsive nowrap" id="study_program_table" style="width:100%">
      </table>
  </div>
</div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {
  name: 'datatable-index',
  computed:{
    ...mapState({
      selected: ({ studyProgram }) => studyProgram.selected,
    }),
  },
  methods: {
    ...mapActions('studyProgram', ['ShowAllStudyPrograms', 'DeleteStudyPrograms']),
  },
  mounted(){
    this.ShowAllStudyPrograms({ type: "initTable" })
  },
}
</script>
