import { guest } from '@middlewares/authenticated.js'
import Auth from '@layouts/Auth.vue'

export default [
  /* Rutas de auteticación o desautenticación */
  {
    path: '/admin',
    component: Auth,
    /* Rutas a partir del path principal */
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import('@views/admin/Login.vue'),
        beforeEnter: [guest], /* middlewares */
      },
      {
        path: 'signup',
        name: 'signup',
        component: () => import('@views/admin/Signup.vue'),
        beforeEnter: [guest], /* middlewares */
      },
      {
        path: 'forgot',
        name: 'forgot',
        component: () => import('@views/admin/Forgot.vue'),
        beforeEnter: [guest], /* middlewares */
      }
    ],
  },
]
