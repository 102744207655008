<template>
  <svg x="0px" y="0px" :width="size" :height="size" viewBox="0 0 172 172" :style="`fill:${color};`" class="d-inline">
    <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal">
      <path d="M0,172v-172h172v172z" fill="none"></path>
      <g>
        <path d="M2.15,53.75h167.7v64.5h-167.7z" fill="#ffeea3"></path>
        <path d="M167.7,55.9v60.2h-163.4v-60.2h163.4M172,51.6h-172v68.8h172v-68.8z" fill="#f5c276"></path>
        <path d="M2.15,23.65h167.7v30.1h-167.7z" fill="#f78f8f"></path>
        <path d="M167.7,25.8v25.8h-163.4v-25.8h163.4M172,21.5h-172v34.4h172v-34.4z" fill="#c74343"></path>
        <g>
          <path d="M2.15,118.25h167.7v30.1h-167.7z" fill="#f78f8f"></path>
          <path d="M167.7,120.4v25.8h-163.4v-25.8h163.4M172,116.1h-172v34.4h172v-34.4z" fill="#c74343"></path>
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
  export default {
    props:{
      color:{
        type:String,
        default: '#000000',
      },
      size:{
        type:String,
        default: '20',
      }
    }
  }
</script>
