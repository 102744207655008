/* Api: Url base y opciones */
import Api from '@services/api'

export default {

  /* Obtenemos una session del backend */
  session: async (token, next, end) => {

    /* Consumo de la endpoint */
    const resp = await Api.post(`/auth/session`, {token}) /*
    Obtenemos los datos de manera asincrona */

    /* Manejo de resultados */
    try { // Si todo va bien
      if(!!next) { /* si la funcion 'next' existe se ejecuta lo siguiente */
        let isEmptyData = Object.keys(resp.data).length === 0 /*
        Comprobamos que trae un objeto que no esta vacio */

        next(resp.data, !isEmptyData) /* Pasamos la data,
        y un valor que indique que no esta vacia la data */
      }
    }
    catch(e) { // En caso de errores
      console.error(`Error: Services authenticated - session:`, e) /*
      En caso de error mostrar en que modulo y servicio */

      /* si la funcion 'end' existe se ejecuta */
      if(!!end) end("error")
    }
    finally{ // Siempre que finalize hara lo siguiente
      /* si la funcion 'end' existe se ejecuta */
      if(!!end) end("finally")
    }
  },
  /*****--------------------------------------------******/

  /* Iniciamos session en el backend */
  login: async (data_, next, end) => {

    /* Consumo de la endpoint */
    const resp = await Api.post(`/auth/login`, data_) /*
    Obtenemos los datos de manera asincrona */

    /* Manejo de resultados */
    try { // Si todo va bien
      if(!!next) { /* si la funcion 'next' existe se ejecuta lo siguiente */
        let isEmptyData = Object.keys(resp.data).length === 0 /*
        Comprobamos que trae un objeto que no esta vacio */

        next(resp.data, !isEmptyData) /* Pasamos la data,
        y un valor que indique que no esta vacia la data */
      }
    }
    catch(e) { // En caso de errores
      console.error(`Error: Services authenticated - login:`, e) /*
      En caso de error mostrar en que modulo y servicio */

      /* si la funcion 'end' existe se ejecuta */
      if(!!end) end("error")
    }
    finally{ // Siempre que finalize hara lo siguiente
      /* si la funcion 'end' existe se ejecuta */
      if(!!end) end("finally")
    }
  },
  /*****--------------------------------------------******/

  /* Cerramos session en el backend */
  logout: async (next, end) => {

    /* Consumo de la endpoint */
    const resp = await Api.post(`/auth/logout`) /*
    Obtenemos los datos de manera asincrona */

    /* Manejo de resultados */
    try { // Si todo va bien
      if(!!next) { /* si la funcion 'next' existe se ejecuta lo siguiente */
        let isEmptyData = Object.keys(resp.data).length === 0 /*
        Comprobamos que trae un objeto que no esta vacio */

        next(resp.data, !isEmptyData) /* Pasamos la data,
        y un valor que indique que no esta vacia la data */
      }
    }
    catch(e) { // En caso de errores
      console.error(`Error: Services authenticated - logout:`, e) /*
      En caso de error mostrar en que modulo y servicio */

      /* si la funcion 'end' existe se ejecuta */
      if(!!end) end("error")
    }
    finally{ // Siempre que finalize hara lo siguiente
      /* si la funcion 'end' existe se ejecuta */
      if(!!end) end("finally")
    }
  },
  /*****--------------------------------------------******/
}
