import { snakeCase, camelCase, toLower, toUpper, upperFirst, merge } from 'lodash'
import { headerCallback, fnCreatedRow, templates } from './tableHtml.js'

/* Opciones por defecto */
const defaultOpt = {
  headerCallback, // Funcion para el selector de todo los campos
  fnCreatedRow,
  lengthMenu: [5, 10, 25, 50],
  order: [ [1, 'desc'] ], // Orden
  searchDelay: 500, // Espera de busqueda
  pageLength: 5, // Paginacion

  autoColumns: false,
  responsive: false,
  autoWidth: false,
  searching: true, // Busqueda
  stateSave: true, // Auto guarda donde nos quedamos y las opciones
  paging: true,
  info: true,

  columns:[], // Por defecto vaco
  columnDefs:[], // Por defecto vaco
}

/* Funcion base para todas las dataTable */
export const baseTb = ({ tbClass, options, actions, data, name }) => {

  return tbClass.DataTable({
    ...merge(options, defaultOpt),
    data: data,
    /* Columnas normales */
    columns: [
      { title: '', data: 'id' }, // seleccion
      { title: '#', data:'id' }, // conteo
      ...options.columns, // columnas
      { title: '', data: null }, // opciones de editar, eliminar, etc
    ],
    /* Columnas con modificación */
    columnDefs: [
      /* Selector multible */
      {
        targets: 0,
        width: '30px',
        className: 'dt-left',
        orderable: false,
        data: 'id',
        render: (id) => templates.checkbox(id),
      },
      ...options.columnDefs,

      /* Opciones de editar, eliminar, etc */
      {
        targets: -1,
        orderable: false,
        width: '125px',
        data: 'id',
        render: (data) => templates.buttonActions(data)
      }
    ]
  })
}

/* Funcion para ejecutar los botones editar y eliminar */
export const updateAndDeleteButtons = ({tb, tbClass, actions, state}) => {

  /* Variables con el modo snakeCase para usar en la mutación */
  let mutationSnakeCase = toUpper(snakeCase(state)) /* POR_EJEMPLO */
  let dispatchName = upperFirst(state) /* PorEjemplo */


  /*UPDATE *----------------* */

  /* Activar cuando se haga click en el boton de actualizar */
  tbClass.on('click', `.btn_update`, function () {
    $("tbody tr").removeClass(); /* removemos las clases */
    $(this).closest('tr').addClass(`selected_upd`); /* Agregamos esta clase */
  });

  /* Una ves que estemos en el boton y clickemos en actualizar */
  tbClass.on('click', `tbody tr .btn_update`, function () {
    let id = $(this).attr('id') /* Obtenemos el id */

    // let data = tb.row(`.selected_upd`, { page: 'current' }).data()
    /* Eliminamos dicha fila y actualizamos */

    /* Actualizamos en la base de datos */
    actions.dispatch(
      'global/RedirectToUpdate',
      { state, id },
      { root:true },
    )
  });


  /*DELETE *----------------* */

  /* Activar cuando se haga click en el boton de eliminar */
  tbClass.on('click', `.btn_delete`, function () {
    $("tbody tr").removeClass(); /* removemos las clases */
    $(this).closest('tr').addClass(`selected_del`); /* Agregamos esta clase */
  });

  /* Una ves que estemos en el boton y clickemos en eliminar */
  tbClass.on('click', `tbody tr .btn_delete`, function () {
    let id = $(this).attr('id') /* Obtenemos el id */

    tb.row(`.selected_del`, { page: 'current' }).remove().draw(false); /*
    Eliminamos dicha fila y actualizamos */

    // actions.commit(`DELETE_${mutationSnakeCase}`, {id}) /* Eliminamos de
    // la base de datos */
    actions.dispatch(
      `${camelCase(state)}/Delete${dispatchName}`,
      {id},
      {root:true},
    )
  });

}

/* detectamos cuando haya una seleccion en el selector de
todos los selectores */
export const multiSelectAndDelete = ({tb, tbClass, actions, state}) => {
  state = camelCase(state) /* porEjemplo */

  /* detectamos cuando haya una seleccion */
  tbClass.on('change', '.group-checkable', function () {

    /* seleccionamos y deseleccionamos todo */
    let set = $(this).closest('table').find('td:first-child .checkable');
    let checked = $(this).is(':checked'); /* Obtenemos el estado, si
    esta selecionado o deseleccionado */

    $(set).each(function () { /* Iteramos todo */

      const select = $(this).attr('value') /* guardamos el valor */

      if (checked) { /*  Si no esta seleccionado */
        $(this).prop('checked', true); /* Activamos */

        /* Agregamos la clase */
        $(this).closest('tr').addClass('active');

        /* Guardamos la seleccion en el store */
        actions.dispatch(
          'global/SelectState',
          { state, select },
          {root:true},
        )
      }
      else { /* Si esta seleccionado */
        $(this).prop('checked', false); /* Desactivamos */

        /* Removemos la clase */
        $(this).closest('tr').removeClass('active');

        /* Guardamos la deseleccion en el store */
        actions.dispatch(
          'global/SelectOnceState',
          { state, type:'del', select},
          {root:true},
        )
      }
    });
  });

  /* Cuando se leccione el boton de eliminar varias selecciones */
  $('#delete-selected').on('click', () => {
    /* Elimina y actualiza */
    tb.rows('.active').remove().draw(false);
  })

  /* detectamos cuando haya una seleccion */
  tbClass.on('change', 'tbody tr .checkbox', function () {
    let select = $(this).find("input").attr('value')

    /* Guardamos la seleccion en el store */
    actions.dispatch(
      'global/SelectState',
      {state, select},
      {root:true},
    )

    /* Activamos la clase*/
    $(this).parents('tr').toggleClass('active');
  });
}


/* Funcion para exportar todas las funciones en un objeto manejable */
export const exportsFunctions = ({ getTb, getStatus, name, initTable }) => {

  let nameTable = snakeCase(name) /* por_ejemplo */
  let nameState = upperFirst(name) /* PorEjemplo */
  let status = getStatus()

  return {

    /* Iniciar la tabla */
    [`initTb${nameState}`]: (functions, data) => {
      status = true; /* una ves iniciada la instancia
      decimos que ya esta montada la tabla con 'status'
      en true */

      $(() => { /* Instancia global en el documento html */
        let tbClass = $(`#${nameTable}_table`) /* Buscamos la tabla */
        initTable(tbClass, functions, data) /* Iniciamos la tabla */
      });
    },

    /* Funcion para agregar nuevas filas */
    [`addTb${nameState}`]: (data) => {

      /* si ya se monto la instancia entonces
      se ejecuta lo siguiente */
      if (status) {
        /* Agregamos y actualizamos la tabla */
        getTb().rows.add([data]).draw()
      }
    },

    /* Funcion para actualizar la tabla */
    [`updateTb${nameState}`]: (data) => {

      /* si ya se monto la instancia entonces
      se ejecuta lo siguiente */
      if (status) {
        /* Remplazamos la data actual por la nueva y
        actualizamos la tabla */
        getTb().row('.selected_upd', { page: 'current' }).data(data).draw(false)
      }
    },

    /* Funcion para refrescar la tabla */
    [`refreshTb${nameState}`]: () => {
      /* si ya se monto la instancia entonces
      se ejecuta lo siguiente */
      if (status) {
        const tb = getTb()

        /* refrescamos la tabla */
        tb.rows().invalidate('data').draw(false)
      }
    },

    /* Funcion para eliminar de la tabla */
    [`deleteTbData${nameState}`]: (tb, id) => {

      /* si ya se monto la instancia entonces
      se ejecuta lo siguiente */
      if (status) {
        /* Iteramos sobre toda la tabla */
        tb.row('#row_'+id).remove().draw(false)
      }
    },

    /* Funcion para eliminar de la tabla */
    [`updateTbData${nameState}`]: (tb, data) => {

      /* si ya se monto la instancia entonces
      se ejecuta lo siguiente */
      if (status) {
        /* Iteramos sobre toda la tabla */
        tb.row('#row_'+data.id, { page: 'all' }).data(data).draw(false)
      }
    }
  }
}

/* Funcion para modificar el input */
export const searchModify = (tb, name, value) => {
  name = snakeCase(name) /* por_ejemplo  */

  $(`#${name}_table_filter input[type=search]`).attr("placeholder", (value ||"Buscar"));
  /* buscamos el input de la instancia actual y agregar o
  modificar el atributo placeholder */
}

/* Funcion para insertar un dato y renderisar en la tabla */
export const renderField = (data, field) => {
  return (`
    <div class="d-flex align-items-center">
      ${ data ? data[field]: '<span style="color:red;">Vacio</span>' }
    </div>
  `)
}

/* Funcion para activar los botones de exportar */
export const exportButtons = (tb, name) => {
  name = snakeCase(name) /* por_ejemplo */

  /* Imprimir */
  $(`#export_print_${name}`).on('click', (e) => {
    e.preventDefault() /* Paramos el evento por defecto */
    tb.button(0).trigger() /* Activamos el evento
    para exportar */
  })

  /* Copia */
  $(`#export_copy_${name}`).on('click', (e) => {
    e.preventDefault() /* Paramos el evento por defecto */
    tb.button(1).trigger()  /* Activamos el evento
    para exportar */
  })

  /* Excel */
  $(`#export_excel_${name}`).on('click', (e) => {
    e.preventDefault() /* Paramos el evento por defecto */
    tb.button(2).trigger()  /* Activamos el evento
    para exportar */
  })

  /* CSV */
  $(`#export_csv_${name}`).on('click', (e) => {
    e.preventDefault() /* Paramos el evento por defecto */
    tb.button(3).trigger()  /* Activamos el evento
    para exportar */
  })

  /* PDF */
  $(`#export_pdf_${name}`).on('click', (e) => {
    e.preventDefault() /* Paramos el evento por defecto */
    tb.button(4).trigger()  /* Activamos el evento
    para exportar */
  })
}

/* Funcion para insertar un contador de cada fila */
export const countElements = (tb) => {

  /* Buscamos en la tabla */
  tb.on('order.dt search.dt', () => {

    /* Buscamos en la primera columna */
    tb.column(1).nodes().each((cell, i) => {
      /* Insertamos el numero de la fila */
      cell.innerHTML = i+1
    })
  }).draw(); /* Refrescamos */
}
