<template>

<div class="fixed block lg:hidden z-40" style="top: 50vh;left: -2px;" id="btn-active-menu-search">
  <button class="bg-white py-2 px-2 shadow-xl rounded-full inline-flex items-center">
    <IconArrowRightTwo color="#28aae1"/>
  </button>
</div>

<div class="fixed lg:static max-w-xs w-72 lg:w-3/12 space-y-4 lg:block z-50 lg:z-0 bg-white lg:bg-transparent px-5 lg:px-0 pb-5 lg:pb-0 lg:overflow-hidden overflow-scroll" style="height:93%; top:59px; left:-290px; min-width: 270px; font-size: 12px;" id="menu-filter-search">

  <div class="border border-gray-300 p-3 rounded-md mt-5 lg:mt-0">
    <h4 class="font-semibold text-base mb-2 text-gray-500"> Destinos de Canada </h4>

    <div class="pt-2" v-if="filterOfState.length">
      <fieldset class="border border-solid border-gray-300 px-3 pb-2 rounded-md">
        <legend class="text-sm" style="width: 60%;">Filter by provinces</legend>
        <ul class="list-disc space-y-2">
          <div v-for="(name, index) in filterOfState" class="w-full" :key="index">
            <div class="rounded-full cursor-pointer bg-one pr-4 py-1 text-white inline" @click="RemoveStateInFilter(index)">
              <IconClose size="24px" color="white" class="inline-flex -mt-1"/>
              <span class="inline pl-1">{{ startCase(name) }}</span>
            </div>
          </div>
        </ul>
      </fieldset>
    </div>

    <div class="pt-2" v-if="filterbyState.length">
      <fieldset class="border border-solid border-gray-300 px-3 pb-2 rounded-md">
        <legend class="text-sm" style="width: 60%;">Provinces of canada</legend>

        <ul class="list-disc space-y-3">
          <div v-for="(filterState, index) in filterbyState" class="w-full" :key="index">
          <div class="inline rounded-full cursor-pointer bg-one px-3 py-1 text-white" @click="AddStateInFilter(filterState.name)">
            {{ startCase(filterState.name) }}
            <span class="pl-2 font-extrabold">
              {{filterState.count}}
            </span>
          </div>
        </div>
        </ul>
        <!-- <div class="pt-2 text-blue-900">Ver más</div> -->

      </fieldset>
    </div>

    <div class="pt-2" v-if="!filterOfState.length && !filterbyState.length">
        <div class="flex w-100 relative px-3 pb-2">
          <Loading :active="filterbyState.length < 1 && empty" class="p-5"/>
          <div class="w-full p-1" v-if="filterbyState.length < 1 && !empty">
            <div class="flex text-xl font-semibold text-center">
              <IconWarning size="25px" color="#28aae1" class="mr-2"/> not available
            </div>
          </div>
        </div>
    </div>

  </div>

  <div class="border border-gray-300 p-3 rounded-md pb-3">
    <h4 class="font-semibold text-base mb-2 text-gray-500"> Other Destinations </h4>

    <div class="pt-2">
      <fieldset class="border border-solid border-gray-300 px-3 pb-2 rounded-md">
        <legend class="text-sm" style="width: 60%;">Country</legend>
        <div v-if="countryCount.length >= 1">
          <ul class="list-disc space-y-2">
            <template v-for="(country, index) in countryCount" :key="index">
              <router-link :to="goDestinationSearch(country.name)" tag="li" class="flex justify-between" @click="topDestination">
                <span class="hover:text-one">{{ startCase(country.name) }}</span>
                <span class="bg-one px-2 text-white text-center font-extrabold rounded-full">
                  {{country.count}}
                </span>
              </router-link>
            </template>
          </ul>
        </div>
        <div class="flex w-100 h-20 relative" v-else>
          <Loading :active="countryCount.length < 1"/>
        </div>
        <!-- <div class="pt-2 text-blue-900">Ver más</div> -->

      </fieldset>
    </div>
  </div>

</div>

<div class="fixed top-0 w-full h-full z-40 bg-black bg-opacity-40 hidden" id="bg-all-search"></div>

</template>


<script>
import { startCase, kebabCase, lowerCase, orderBy } from 'lodash'
import { mapState, mapActions, mapGetters } from 'vuex'
import Api from '@services/modules/headquarter.js'

import IconArrowRightTwo from '@components/icons/ArrowRightTwo.vue'
import IconWarning from '@components/icons/Warning.vue'
import IconClose from '@components/icons/Close.vue'
import Loading from '@components/Loading.vue'

export default {
  components:{
    IconArrowRightTwo,
    IconWarning,
    IconClose,
    Loading,
  },
  data(){
    return {
      empty:true,
    }
  },
  computed: {
    ...mapState({
      byState: ({ destination }) => destination.byState,
      countryCount: ({ destination }) => destination.countryCount,
      filterOfState: ({ destination }) => destination.filterOfState,
    }),
    ...mapGetters('destination', ['filterbyState']),
  },
  methods: {
    ...mapActions('destination', [
      'RemoveAllStateInFilter',
      'RemoveStateInFilter',
      'AddStateInFilter',
      'HeadquarterByCountriesCustom',
    ]),
    startCase(value) {
      return startCase(value)
    },
    topDestination() {
      this.RemoveAllStateInFilter()
      $( "#top-destination" ).trigger( "click" );
      $('#bg-all-search').removeClass('block')
      $('#bg-all-search').addClass('hidden')
      $('#menu-filter-search').css({left: '-290px', transition: 'left 0.4s'})
    },
    goDestinationSearch(name) {
      return {
        name: 'destination-search',
        params: {
          name: kebabCase(name)
        }
      }
    },
  },
  mounted() {
    setTimeout(()=> this.empty = false, 7000)
    this.HeadquarterByCountriesCustom()

    $(()=>{
      $('#bg-all-search').click(function(){
        $(this).removeClass('block')
        $(this).addClass('hidden')
        $('#menu-filter-search').css({left: '-290px', transition: 'left 0.4s'})
      })

      $('#btn-active-menu-search').click(function(){
        $('#bg-all-search').removeClass('hidden')
        $('#bg-all-search').addClass('block')
        $('#menu-filter-search').css({left: '0px', transition: 'left 0.4s'})
      })
    })
  },
}
</script>

<style scoped>
  .menu-filter-search{
    transition: all .4s;
    -moz-transition: all .4s;
    -webkit-transition:all .4s;
    -o-transition: all .4s;
  }
</style>
