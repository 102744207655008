import { kebabCase } from 'lodash'

let resolveImage = (name) => {
  return require(`@assets/images/carousel/${name}.jpg`)
}

let url = (country) => ({
  name: 'destination-search',
  params: {
    name: kebabCase(country),
    filter: true,
  },
})

export default [
  {
    title:'New York',
    url: url('united-states'),
    image: resolveImage(1),
  },
  {
    title:'Miami',
    url: url('united-states'),
    image: resolveImage(2),
  },
  {
    title:'Boston',
    url: url('united-states'),
    image: resolveImage(3),
  },
  {
    title:'Vancouver',
    url: url('canada'),
    image: resolveImage(4),
  },
  {
    title:'Londres',
    url: url('united-kingdom'),
    image: resolveImage(5),
  },
  {
    title:'California',
    url: url('united-states'),
    image: resolveImage(6),
  }
]
