<template>
<div class="container lg:px-24 mx-auto divide-y pt-10 pb-10">

  <div class="mb-4">
    <h1 class="text-2xl"><strong style="color: #05a4e8;">The best destinations</strong></h1>
    <p>Selected so you don't have to look for where to study, just choose one</p>
  </div>

  <div class="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-4 mt-3 pt-7">

    <template v-for="(destination, index) in destinations" :key="index">

      <router-link :to="destination.url" class="rounded-md overflow-hidden relative w-full lg:h-56 h-40" @click="topDestination">
        <div class="absolute w-full h-3/4 -bottom-12 bg-gradient-to-b from-transparent to-gray-800 z-10">
        </div>
        <img :src="destination.image" class="absolute w-full h-full object-cover" style="filter:brightness(0.7)">
        <div class="absolute bottom-0 w-full p-3 text-white z-20 font-semibold text-lg">
          {{destination.title}}
        </div>
      </router-link>
    </template>
  </div>

</div>
</template>

<script>
import destinations from '@services/data/location/destinations.js'

export default {
  data() {
    return {
      destinations,
    }
  },
  methods:{
    topDestination() {
      $( "#btn-top" ).trigger( "click" );
    },
  }
}
</script>
