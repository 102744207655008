<template>
  <select :name="name" @input="$emit('input', $event)"
    class="form-control form-control-lg form-control-solid" :value="value">
    <option hidden selected value="">Seleciona una cuenta...</option>
    <option :value="typeAccount.id" v-for="(typeAccount, index) in typeAccounts" :key="index">{{typeAccount.name}} - precio: {{typeAccount.price }}$</option>
  </select>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {
  name:'SelectTypeAccount',
  props:{
    value:{
      type:String,
      default:'',
    },
    name:{
      type:String,
      default:'',
    }
  },
  computed:{
    ...mapState({
      typeAccounts: ({ typeAccount }) => typeAccount.typeAccounts,
    }),
  },
  methods: {
    ...mapActions('typeAccount', ['ShowAllTypeAccounts']),

  },
  mounted(){
    this.ShowAllTypeAccounts()
  },
};
</script>
