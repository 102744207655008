<template>
<HeaderBackground />

<div class="divide-y px-3 sm:px-10">

  <Breadcrumb/>

  <div class="w-full grid gap-3 justify-center lg:flex lg:space-x-10 pt-5">

    <LeftActionBar/>
    <Content/>


  </div>
</div>
</template>

<script>
import Breadcrumb from '@components/guest/destinationSearch/Breadcrumb.vue'
import LeftActionBar from '@components/guest/destinationSearch/LeftActionBar.vue'
import HeaderBackground from '@components/guest/HeaderBackground.vue'
import Content from '@components/guest/destinationSearch/Content.vue'
import {mapActions} from 'vuex'

export default {
  components: {
    HeaderBackground,
    LeftActionBar,
    Breadcrumb,
    Content,
  },
  methods: {
    ...mapActions('destination', [
      'RemoveAllStateInFilter',
    ]),
  },
  beforeRouteLeave(to, from) {
    if(from.params?.filter){
      this.RemoveAllStateInFilter(0)
    }
  },
}
</script>
