import {upperFirst} from 'lodash'

import {
  baseTb,
  searchModify,
  countElements,
  exportsFunctions,
  multiSelectAndDelete,
  updateAndDeleteButtons
} from '@options/tables/index.js'

let name = 'study', tb = null, status = false

/* tbClass = '${name}_table' */

let initTable = (tbClass, actions, data) => {

  status = true
  tb = baseTb({
    name,
    tbClass: tbClass,
    data: data.studies,
    options: {
      columns: [
        { title: 'Titutlo', data: 'title' },
        { title: 'Descripción', data: 'description' },
      ],
    }
  })
  countElements()
  searchModify(tb, name)
  multiSelectAndDelete({tb, tbClass, actions, state:name })
  updateAndDeleteButtons({tb, tbClass, actions, state:name })
}

const getTb = () => tb
const getStatus = () => status

const getExport = exportsFunctions({
  getStatus,
  initTable,
  getTb,
  name,
})

getExport[`getTb${upperFirst(name)}`] = getTb

export default getExport
