/* Api: Url base y opciones */
import Api from '@services/api'

export default {

  /* Actualizamos un dato de la db */
  UpdateTime: async ({params, data}, next, end) => {

    /* Consumo de la endpoint */
    const resp = await Api.post(`/time/update/${params.id}`, data) /*
    Obtenemos los datos de manera asincrona */

    /* Manejo de resultados */
    try { // Si todo va bien
      if(!!next) { /* si la funcion 'next' existe se ejecuta lo siguiente */
        let isEmptyData = Object.keys(resp.data).length === 0  /*
        Comprobamos que trae un objeto que no esta vacio */

        next(resp.data, !isEmptyData) /* Pasamos la data,
        y un valor que indique que no esta vacia la data */
      }
    }
    catch(e) { // En caso de errores
      console.error(`Error: Services time - UpdateTime:`, e) /*
      En caso de error mostrar en que modulo y servicio */

      /* si la funcion 'end' existe se ejecuta */
      if(!!end) end("error")
    }
    finally{ // Siempre que finalize hara lo siguiente
      /* si la funcion 'end' existe se ejecuta */
      if(!!end) end("finally")
    }
  },
  /*****--------------------------------------------******/

  /* Registramos un nuevo dato en la db */
  CreateTime: async (body, next, end) => {

    /* Consumo de la endpoint */
    const resp = await Api.post(`/time/create`, body) /*
    Obtenemos los datos de manera asincrona */

    /* Manejo de resultados */
    try { // Si todo va bien
      if(!!next) { /* si la funcion 'next' existe se ejecuta lo siguiente */
        let isEmptyData = resp.data.length < 0 /* Verificamos que el array
        o datos no este vacio */

        next(resp.data, !isEmptyData) /* Pasamos la data,
        y un valor que indique que no esta vacia la data */
      }
    }
    catch(e) { // En caso de errores
      console.error(`Error: Services time - CreateTime:`, e) /*
      En caso de error mostrar en que modulo y servicio */

      /* si la funcion 'end' existe se ejecuta */
      if(!!end) end("error")
    }
    finally{ // Siempre que finalize hara lo siguiente
      /* si la funcion 'end' existe se ejecuta */
      if(!!end) end("finally")
    }
  },
  /*****--------------------------------------------******/

  /* Eliminamos un dato de la db */
  DeleteTime: async (params, next, end) => {

    /* Consumo de la endpoint */
    const resp = await Api.delete(`/time/delete/${params.id}`) /*
    Obtenemos los datos de manera asincrona */

    /* Manejo de resultados */
    try { // Si todo va bien
      if(!!next) { /* si la funcion 'next' existe se ejecuta lo siguiente */
        let isEmptyData = resp.data.length < 0 /* Verificamos que el array
        o datos no este vacio */
        next(resp.data, !isEmptyData) /* Pasamos la data,
        y un valor que indique que no esta vacia la data */
      }
    }
    catch(e) { // En caso de errores
      console.error(`Error: Services time - DeleteTime:`, e) /*
      En caso de error mostrar en que modulo y servicio */

      /* si la funcion 'end' existe se ejecuta */
      if(!!end) end("error")
    }
    finally{ // Siempre que finalize hara lo siguiente
      /* si la funcion 'end' existe se ejecuta */
      if(!!end) end("finally")
    }
  },
  /*****--------------------------------------------******/
}
