export default {
    "afghanistan": 0,
    "aland-islands": 1,
    "albania": 2,
    "algeria": 3,
    "american-samoa": 4,
    "andorra": 5,
    "angola": 6,
    "anguilla": 7,
    "antarctica": 8,
    "antigua-and-barbuda": 9,
    "argentina": 10,
    "armenia": 11,
    "aruba": 12,
    "australia": 13,
    "austria": 14,
    "azerbaijan": 15,
    "bahamas-the": 16,
    "bahrain": 17,
    "bangladesh": 18,
    "barbados": 19,
    "belarus": 20,
    "belgium": 21,
    "belize": 22,
    "benin": 23,
    "bermuda": 24,
    "bhutan": 25,
    "bolivia": 26,
    "bosnia-and-herzegovina": 27,
    "botswana": 28,
    "bouvet-island": 29,
    "brazil": 30,
    "british-indian-ocean-territory": 31,
    "brunei": 32,
    "bulgaria": 33,
    "burkina-faso": 34,
    "burundi": 35,
    "cambodia": 36,
    "cameroon": 37,
    "canada": 38,
    "cape-verde": 39,
    "cayman-islands": 40,
    "central-african-republic": 41,
    "chad": 42,
    "chile": 43,
    "china": 44,
    "christmas-island": 45,
    "cocos-keeling-islands": 46,
    "colombia": 47,
    "comoros": 48,
    "congo": 49,
    "congo-the-democratic-republic-of-the": 50,
    "cook-islands": 51,
    "costa-rica": 52,
    "cote-d-ivoire-ivory-coast": 53,
    "croatia-hrvatska": 54,
    "cuba": 55,
    "cyprus": 56,
    "czech-republic": 57,
    "denmark": 58,
    "djibouti": 59,
    "dominica": 60,
    "dominican-republic": 61,
    "timor-leste": 62,
    "ecuador": 63,
    "egypt": 64,
    "egipto": 64,
    "el-salvador": 65,
    "salvador": 65,
    "equatorial-guinea": 66,
    "linea-equatorial": 66,
    "eritrea": 67,
    "estonia": 68,
    "ethiopia": 69,
    "falkland-islands": 70,
    "faroe-islands": 71,
    "fiji-islands": 72,
    "finland": 73,
    "france": 74,
    "french-polynesia": 75,
    "french-southern-territories": 76,
    "gabon": 77,
    "gambia-the": 78,
    "georgia": 79,
    "germany": 80,
    "ghana": 81,
    "gibraltar": 82,
    "greece": 83,
    "greenland": 84,
    "grenada": 85,
    "guadeloupe": 86,
    "guam": 87,
    "guatemala": 88,
    "guernsey": 89,
    "guinea": 90,
    "guinea-bissau": 91,
    "guyana": 92,
    "haiti": 93,
    "heard-island-and-mc-donald-islands": 94,
    "honduras": 95,
    "hong-kong-s-a-r": 96,
    "hungary": 97,
    "iceland": 98,
    "india": 99,
    "indonesia": 100,
    "iran": 101,
    "iraq": 102,
    "ireland": 103,
    "israel": 104,
    "italy": 105,
    "jamaica": 106,
    "japan": 107,
    "jersey": 108,
    "jordan": 109,
    "kazakhstan": 110,
    "kenya": 111,
    "kiribati": 112,
    "korea-north": 113,
    "korea-south": 114,
    "kosovo": 115,
    "kuwait": 116,
    "kyrgyzstan": 117,
    "laos": 118,
    "latvia": 119,
    "lebanon": 120,
    "lesotho": 121,
    "liberia": 122,
    "libya": 123,
    "liechtenstein": 124,
    "lithuania": 125,
    "luxembourg": 126,
    "macau-s-a-r": 127,
    "macedonia": 128,
    "madagascar": 129,
    "malawi": 130,
    "malaysia": 131,
    "maldives": 132,
    "mali": 133,
    "malta": 134,
    "man-isle-of": 135,
    "marshall-islands": 136,
    "martinique": 137,
    "mauritania": 138,
    "mauritius": 139,
    "mayotte": 140,
    "mexico": 141,
    "micronesia": 142,
    "moldova": 143,
    "monaco": 144,
    "mongolia": 145,
    "montenegro": 146,
    "montserrat": 147,
    "morocco": 148,
    "mozambique": 149,
    "myanmar": 150,
    "namibia": 151,
    "nauru": 152,
    "nepal": 153,
    "netherlands-antilles": 154,
    "netherlands": 155,
    "new-caledonia": 156,
    "new-zealand": 157,
    "nicaragua": 158,
    "niger": 159,
    "nigeria": 160,
    "niue": 161,
    "norfolk-island": 162,
    "northern-mariana-islands": 163,
    "norway": 164,
    "oman": 165,
    "pakistan": 166,
    "palau": 167,
    "palestinian-territory-occupied": 168,
    "panama": 169,
    "papua-new-guinea": 170,
    "paraguay": 171,
    "peru": 172,
    "philippines": 173,
    "pitcairn-island": 174,
    "poland": 175,
    "portugal": 176,
    "puerto-rico": 177,
    "qatar": 178,
    "reunion": 179,
    "romania": 180,
    "russia": 181,
    "rwanda": 182,
    "saint-helena": 183,
    "saint-kitts-and-nevis": 184,
    "saint-lucia": 185,
    "saint-pierre-and-miquelon": 186,
    "saint-vincent-and-the-grenadines": 187,
    "saint-barthelemy": 188,
    "saint-martin-french-part": 189,
    "samoa": 190,
    "san-marino": 191,
    "sao-tome-and-principe": 192,
    "saudi-arabia": 193,
    "senegal": 194,
    "serbia": 195,
    "seychelles": 196,
    "sierra-leone": 197,
    "singapore": 198,
    "slovakia": 199,
    "slovenia": 200,
    "solomon-islands": 201,
    "somalia": 202,
    "south-africa": 203,
    "south-georgia-and-the-south-sandwich-islands": 204,
    "south-sudan": 205,
    "spain": 206,
    "españa": 206,
    "sri-lanka": 207,
    "sudan": 208,
    "suriname": 209,
    "svalbard-and-jan-mayen-islands": 210,
    "swaziland": 211,
    "sweden": 212,
    "switzerland": 213,
    "syria": 214,
    "taiwan": 215,
    "tajikistan": 216,
    "tanzania": 217,
    "thailand": 218,
    "togo": 219,
    "tokelau": 220,
    "tonga": 221,
    "trinidad-and-tobago": 222,
    "tunisia": 223,
    "turkey": 224,
    "turkmenistan": 225,
    "turks-and-caicos-islands": 226,
    "tuvalu": 227,
    "uganda": 228,
    "ukraine": 229,
    "united-arab-emirates": 230,
    "united-kingdom": 231,
    "united-states": 232,
    "united-states-minor-outlying-islands": 233,
    "uruguay": 234,
    "uzbekistan": 235,
    "vanuatu": 236,
    "vatican-city-state-holy-see": 237,
    "venezuela": 238,
    "vietnam": 239,
    "virgin-islands-british": 240,
    "virgin-islands-us": 241,
    "wallis-and-futuna": 242,
    "western-sahara": 243,
    "yemen": 244,
    "zambia": 245,
    "zimbabwe": 246
}
