<template>
  <select :name="name" @input="$emit('input', $event)" :value="value"
    class="form-control form-control-lg form-control-solid">
    <option hidden selected value="">Seleciona el Tipo...</option>
    <option :value="typeInstitution.id" v-for="(typeInstitution, index) in typeInstitutions" :key="index">{{typeInstitution.name}}</option>
  </select>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {
  name:'SelectTypeAccount',
  props:{
    value:{
      type:String,
      default:'',
    },
    name:{
      type:String,
      default:'',
    }
  },
  computed:{
    /* Llamamos a N cantidad de modulos del store */
    ...mapState({
      typeInstitutions: ({ typeInstitution }) => typeInstitution.typeInstitutions,
    }),
  },
  methods: {
    /* Llamamos a N cantidad de acciones del store */
    ...mapActions('typeInstitution', ['ShowAllTypeInstitutions']),

  },
  mounted(){
    /* Mostrar todos los tipos de instituciones */
    this.ShowAllTypeInstitutions()
  },
};
</script>
