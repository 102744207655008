let validation = []
let validate = {
  fields: {
    name: {
      validators: {
        notEmpty: {
          message: 'Ingresa el nombre del tipo de cuenta'
        }
      }
    },
    price: {
      validators: {
        notEmpty: {
          message: 'Debe proporcionar un precio'
        }
      }
    },
  },
  plugins: {
    trigger: new FormValidation.plugins.Trigger(),
    // Bootstrap Framework Integration
    bootstrap: new FormValidation.plugins.Bootstrap({
      //eleInvalidClass: '',
      eleValidClass: '',
    })
  }
}
let formEl, _avatar

let KTContactsUpdate = function() {

  let _initValidation = function(CreateTypeAccount) {

    validation.push(FormValidation.formValidation(formEl, validate))

    $('#create-form-type-account').on('click', function() {
      validation[0].validate().then(function(status) {
        if (status == 'Valid') {
          Swal.fire({
            text: "¡Todo es bueno! solo confirma",
            icon: "success",
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonText: "Registrar!",
            cancelButtonText: "No, cancelar",
            customClass: {
              confirmButton: "btn font-weight-bold btn-primary",
              cancelButton: "btn font-weight-bold btn-default"
            }
          }).then(() => {
            const errorUpdateForm = () => {
              Swal.fire({ //Notificacion de tipo de cuenta no registrada
                text: "No se pudo registrar el tipo de cuenta, intente en unos minutos",
                icon: "error",
                buttonsStyling: false,
                customClass: {
                  confirmButton: "btn font-weight-bold btn-primary",
                  cancelButton: "btn font-weight-bold btn-default"
                }
              })
            }
            const successUpdateForm = () => {
              Swal.fire({ //Notificacion de tipo de cuante correctamente
                text: "Tipo de cuenta actualizada!",
                icon: "success",
                buttonsStyling: false,
                customClass: {
                  confirmButton: "btn font-weight-bold btn-primary",
                  cancelButton: "btn font-weight-bold btn-default"
                }
              })
            }
            if (status == 'Valid') {
              CreateTypeAccount({
                successUpdateForm,
                errorUpdateForm,
              })
            }
          })
        }
      })
    })
  }

  return {
    init: function(CreateTypeAccount) {
      _initValidation(CreateTypeAccount)
    }
  }
}()

export default (CreateTypeAccount) => {
  $(function() {
    formEl = KTUtil.getById('create_form_type_account')
    KTContactsUpdate.init(CreateTypeAccount)
  })
}

export const resetForm = () => validation[0].resetForm(true)
