<template>
  <div class="d-flex flex-row flex-column-fluid page">
    <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
      <div class="d-flex flex-column flex-column-fluid" id="kt_content">
        <SubHeader />
        <div class="d-flex flex-column-fluid">
          <div class="container">
            <div class="card card-custom gutter-b">
              <div class="card-body p-0">
                <div class="wizard wizard-1">
                  <FormUpdate />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* Funcionalidad de actualizar y validar la data */
import InitValitateForm from "@components/admin/study/update/formUpdate.js"

/* Componentes */
import FormUpdate from '@components/admin/study/update/FormUpdate.vue'
import SubHeader from '@components/admin/study/update/SubHeader.vue'

/* funciones del vuex */
import { mapActions } from 'vuex'

export default {
  name: 'UpdateStudy',
  components: {
    SubHeader,
    FormUpdate,
  },
  methods:{
    /* Llamamos a N cantidad de acciones del store */
    ...mapActions('study', ['UpdateStudy', 'ShowStudy']),
  },
  mounted(){

    /* Iniciamos la data del componente actual si no se a montado
    el componente, pasamos el parametro: this.$route.params.id, con
    la finalidad de obtener el 'tipo de institucion' actual, para
    luego mostrar los datos en los inputs que se actuazaran. */
    this.ShowStudy(this.$route.params.id)

    /* Instanciamos la validacion de actualizar.
    Pasamos la accion UpdateStudy para internamente
    una ves validado todo, active la funcion y se actualize */
    InitValitateForm(this.UpdateStudy)
  }
}
</script>
