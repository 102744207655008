import { authenticared } from '@middlewares/authenticated.js'
import Dashboard from '@layouts/Dashboard.vue'

export default [
  /* Ruta de modulo tipos de cuenta */
  {
    path: '/admin/dashboard/modules/types/account',
    component: Dashboard,
    /* Rutas a partir del path principal */
     children: [
       {
         path: '', /* Ruta por defecto '/' pero no se coloca el slash */
         name: 'index-type-account',
         component: async () => await require("@views/admin/typeAccount/Index.vue"),
         beforeEnter: [authenticared], /* middlewares */
       },
       {
         path: 'add',
         name: 'add-type-account',
         component: async () => await require("@views/admin/typeAccount/Register.vue"),
         beforeEnter: [authenticared], /* middlewares */
       },
       {
         path: 'update/:id',
         name: 'update-type-account',
         component: async () => await require("@views/admin/typeAccount/Update.vue"),
         beforeEnter: [authenticared], /* middlewares */
       },
     ]
  }
]
