<template>
<!-- id="kt_header_menu_wrapper" -->
<div class="header-menu-wrapper header-menu-wrapper-left" :class="drawerMain ? 'header-menu-wrapper-on': null">
  <div id="kt_header_menu" class="header-menu header-menu-left header-menu-mobile header-menu-layout-default">
    <ul class="menu-nav">
      <router-link :to="{ name:'index-dashboard' }" custom v-slot="{ navigate, href, isActive }">
        <li class="menu-item" :class="{'menu-item-active' : isActive }" aria-haspopup="true">
          <a :href="href" @click="navigate" class="menu-link">
            <span class="navi-icon pr-2"><i class="la la-home text-white"></i></span>
            <span class="menu-text">Dashboard</span>
          </a>
        </li>
      </router-link>
      <Modules />
      <!-- <Pages /> -->
    </ul>
  </div>
</div>
</template>

<script>
import {
  mapState
} from 'vuex'
import Modules from './drawer/Modules.vue'
/* import Pages from './drawer/Pages.vue' */
export default {
  name: 'drawer-dashboard',
  components: {
    Modules,
    /* Pages, */
  },
  computed: {
    ...mapState({
      drawerMain: ({
        global
      }) => global.drawer.main,
    }),
  }
}
</script>
