let validation = []
let validate = {
	fields: {
		name: {
			validators: {
				notEmpty: {
					message: 'Porfavor ingresa el nombre del intituto'
				}
			}
		},
		// logo: {
		// 	validators: {
		// 		notEmpty: {
		// 			message: 'Porfavor agrega un logo'
		// 		}
		// 	}
		// },
		// hero_image: {
		// 	validators: {
		// 		notEmpty: {
		// 			message: 'Porfavor agrega una imagen tipo portada'
		// 		}
		// 	}
		// },
		alias: {
			validators: {
				notEmpty: {
					message: 'Debe proporcionar un alias'
				}
			}
		},
		type_institution_id: {
			validators: {
				notEmpty: {
					message: 'Porfavor seleciona el tipo de instituto'
				}
			}
		},
		language: {
			validators: {
				notEmpty: {
					message: 'Porfavor selecciona un lenguaje'
				}
			}
		},
		type_account_id:{
			validators: {
				notEmpty: {
					message: 'Porfavor selecciona el tipo de cuenta'
				}
			}
		}
	},
	plugins: {
		trigger: new FormValidation.plugins.Trigger(),
		// Bootstrap Framework Integration
		bootstrap: new FormValidation.plugins.Bootstrap({
			//eleInvalidClass: '',
			eleValidClass: '',
		})
	}
}
let formEl, _avatar

const differentUpdateForm = () => {
	Swal.fire({ //Notificacion de institución registrada correctamente
		text: "No hay datos modificados",
		icon: "warning",
		buttonsStyling: false,
		customClass: {
			confirmButton: "btn font-weight-bold btn-primary",
			cancelButton: "btn font-weight-bold btn-default"
		}
	})
}
const errorUpdateForm = ()=>{
	Swal.fire({ //Notificacion de institución no registrada
		text: "No se pudo Actualizar la institución, intente en unos minutos",
		icon: "error",
		buttonsStyling: false,
		customClass: {
			confirmButton: "btn font-weight-bold btn-primary",
			cancelButton: "btn font-weight-bold btn-default"
		}
	})
}
const successUpdateForm = ()=>{
	Swal.fire({ //Notificacion de institución registrada correctamente
		text: "Institución actualizada!",
		icon: "success",
		buttonsStyling: false,
		customClass: {
			confirmButton: "btn font-weight-bold btn-primary",
			cancelButton: "btn font-weight-bold btn-default"
		}
	})
	$("#btnDeleteLogo").trigger("click")
	$("#deleteCoverInstitution").trigger("click")
}

let KTContactsUpdate = function() {

  let _initValidation = function(UpdateInstitution) {

		validation.push(FormValidation.formValidation(formEl, validate))

    $('#update-form-institution').on('click', function () {
	    validation[0].validate().then(function(status) {
	      if (status == 'Valid') {
					UpdateInstitution({
						successUpdateForm,
						errorUpdateForm,
						differentUpdateForm
					})
	      }
	    })
    })
  }

	let _initAvatar = function () {
		_avatar = new KTImageInput('kt_contact_edit_avatar')
	}
  return {
    init: function(UpdateInstitution) {
      _initValidation(UpdateInstitution)
      _initAvatar()
    }
  }
}()

export default (UpdateInstitution) => {
  $(function() {
    formEl = KTUtil.getById('kt_contact_edit_form_')
    KTContactsUpdate.init(UpdateInstitution)
  })
}
