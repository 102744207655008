<template>
  <svg x="0px" y="0px" :width="size" :height="size" viewBox="0 0 24 24" :style="`fill:${color};`">
    <path d="M 12 2.0996094 L 1 12 L 4 12 L 4 21 L 10 21 L 10 15 L 14 15 L 14 21 L 20 21 L 20 12 L 23 12 L 12 2.0996094 z"></path>
  </svg>
</template>
<script>
  export default {
    props:{
      color:{
        type:String,
        default: '#000000',
      },
      size:{
        type:String,
        default: '24',
      }
    }
  }
</script>
