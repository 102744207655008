<template>
<svg x="0px" y="0px" :width="size" :height="size" viewBox="0 0 172 172" :style="`fill:${color};`" class="d-inline">
  <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none"
    text-anchor="none" style="mix-blend-mode: normal">
    <path d="M0,172v-172h172v172z" fill="none"></path>
    <g :fill="color">
      <path d="M86.04199,26.98698l-64.17806,62.84831l10.02214,10.2461l54.07194,-52.95215l54.11393,53.80599l10.10612,-10.1621zM85.986,67.38346l-64.23405,65.5638l10.24609,10.02214l54.01595,-55.13575l54.00195,54.81381l10.2181,-10.05013z"></path>
    </g>
  </g>
</svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#000000',
    },
    size: {
      type: String,
      default: '24',
    }
  }
}
</script>
