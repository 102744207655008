let validation = []
let validate = {
  fields: {
    name: {
      validators: {
        notEmpty: {
          message: 'Porfavor ingresa el nombre del intituto'
        }
      }
    },
    alias: {
      validators: {
        notEmpty: {
          message: 'Debe proporcionar un alias'
        }
      }
    },
    logo: {
      validators: {
        notEmpty: {
          message: 'Se requiere un logo'
        }
      }
    },
    hero_image: {
      validators: {
        notEmpty: {
          message: 'Se requiere una imagen de tipo portada'
        }
      }
    },
    type_institution_id: {
      validators: {
        notEmpty: {
          message: 'Porfavor seleciona el tipo de instituto'
        }
      }
    },
    language: {
      validators: {
        notEmpty: {
          message: 'Porfavor selecciona un lenguaje'
        }
      }
    },
    type_account_id:{
      validators: {
        notEmpty: {
          message: 'Porfavor selecciona el tipo de cuenta'
        }
      }
    }
  },
  plugins: {
    trigger: new FormValidation.plugins.Trigger(),
    // Bootstrap Framework Integration
    bootstrap: new FormValidation.plugins.Bootstrap({
      //eleInvalidClass: '',
      eleValidClass: '',
    })
  }
}

let formEl, _avatar

const errorCreateForm = () => {
  Swal.fire({ //Notificacion de tipo de cuenta no registrada
    text: "No se pudo registrar la institución, intente en unos minutos",
    icon: "error",
    buttonsStyling: false,
    customClass: {
      confirmButton: "btn font-weight-bold btn-primary",
      cancelButton: "btn font-weight-bold btn-default"
    }
  })
}
const successCreateForm = () => {
  Swal.fire({ //Notificacion de tipo de cuante correctamente
    text: "Institución actualizada!",
    icon: "success",
    buttonsStyling: false,
    customClass: {
      confirmButton: "btn font-weight-bold btn-primary",
      cancelButton: "btn font-weight-bold btn-default"
    }
  })
  $('#deleteLogoInstitution').trigger('click')
  $('#deleteCoverInstitution').trigger('click')
}

let KTContactsCreate = function() {

  let _initValidation = function(CreateInstitution) {

    validation.push(FormValidation.formValidation(formEl, validate))

    $('#create-form-institution').on('click', function() {
      validation[0].validate().then(function(status) {
        if (status == 'Valid') {
          if (status == 'Valid') {
            CreateInstitution({
              successCreateForm,
              errorCreateForm,
            })
          }
        }
      })
    })
  }
  let _initAvatar = function () {
		_avatar = new KTImageInput('kt_contact_add_avatar')
	}
  return {
    init: function(CreateInstitution) {
      _initValidation(CreateInstitution)
      _initAvatar()
    }
  }
}()


export default (CreateInstitution) => {
  $(function() {
    formEl = KTUtil.getById('create_form_institution')
    KTContactsCreate.init(CreateInstitution)
  })
}

export const resetForm = () => validation[0].resetForm(true)
