/* Api: Url base y opciones */
import Api from '@services/api'

export default {

  /* Obtenemos un dato de la db */
  OneTypeAccount: async (params, next, end) => {

    /* Consumo de la endpoint */
    const resp = await Api.get(`/types/account/${params.id}`)  /*
    Obtenemos los datos de manera asincrona */

    /* Manejo de resultados */
    try { // Si todo va bien
      if(!!next) { /* si la funcion 'next' existe se ejecuta lo siguiente */
        let isEmptyData = Object.keys(resp.data).length === 0 /*
        Comprobamos que trae un objeto que no esta vacio */

        if (!!next) next(resp.data, !isEmptyData) /* Pasamos la data,
        y un valor que indique que no esta vacia la data */
      }
    }
    catch(e) { // En caso de errores
      console.error(`Error: Services institution - OneTypeAccount:`, e) /*
      En caso de error mostrar en que modulo y servicio */

      /* si la funcion 'end' existe se ejecuta */
      if(!!end) end("error")
    }
    finally{  // Siempre que finalize hara lo siguiente
      /* si la funcion 'end' existe se ejecuta */
      if(!!end) end("finally")
    }
  },
  /*****--------------------------------------------******/

  /* Obtenemos todos los datos de la db */
  AllTypeAccounts: async (next, end) => {

    /* Consumo de la endpoint */
    const resp = await Api.get('/types/account/all') /*
    Obtenemos los datos de manera asincrona */

    /* Manejo de resultados */
    try { // Si todo va bien
      if(!!next) { /* si la funcion 'next' existe se ejecuta lo siguiente */
        let isEmptyData = Object.keys(resp.data).length === 0 /*
        Comprobamos que trae un objeto que no esta vacio */

        if (!!next) next(resp.data, !isEmptyData) /* Pasamos la data,
        y un valor que indique que no esta vacia la data */
      }
    }
    catch(e) { // En caso de errores
      console.error(`Error: Services institution - AllTypeAccounts:`, e) /*
      En caso de error mostrar en que modulo y servicio */

      /* si la funcion 'end' existe se ejecuta */
      if(!!end) end("error")
    }
    finally { // Siempre que finalize hara lo siguiente
      /* si la funcion 'end' existe se ejecuta */
      if(!!end) end("finally")
    }
  },
  /*****--------------------------------------------******/

  /* Registramos un nuevo dato en la db */
  CreateTypeAccount: async (data, next, end) => {

    /* Consumo de la endpoint */
    const resp = await Api.post('/types/account/create', data) /*
    Obtenemos los datos de manera asincrona */

    /* Manejo de resultados */
    try { // Si todo va bien
      if(!!next) { /* si la funcion 'next' existe se ejecuta lo siguiente */
        let isEmptyData = Object.keys(resp.data).length === 0 /*
        Comprobamos que trae un objeto que no esta vacio */

        if (!!next) next(resp.data, !isEmptyData) /* Pasamos la data,
        y un valor que indique que no esta vacia la data */
      }
    }
    catch(e) { // En caso de errores
      console.error(`Error: Services institution - CreateTypeAccount:`, e) /*
      En caso de error mostrar en que modulo y servicio */

      /* si la funcion 'end' existe se ejecuta */
      if(!!end) end("error")
    }
    finally { // Siempre que finalize hara lo siguiente
      /* si la funcion 'end' existe se ejecuta */
      if(!!end) end("finally")
    }
  },
  /*****--------------------------------------------******/

  /* Actualizamos un dato de la db */
  UpdateTypeAccount: async ({params, data}, next, end) => {

    /* Consumo de la endpoint */
    const resp = await Api.post(`/types/account/update/${params.id}`, data) /*
    Obtenemos los datos de manera asincrona */

    /* Manejo de resultados */
    try { // Si todo va bien
      if(!!next) { /* si la funcion 'next' existe se ejecuta lo siguiente */
        let isEmptyData = Object.keys(resp.data).length === 0 /*
        Comprobamos que trae un objeto que no esta vacio */

        if (!!next) next(resp.data, !isEmptyData) /* Pasamos la data,
        y un valor que indique que no esta vacia la data */
      }
    }
    catch(e) { // En caso de errores
      console.error(`Error: Services institution - UpdateTypeAccount:`, e) /*
      En caso de error mostrar en que modulo y servicio */

      /* si la funcion 'end' existe se ejecuta */
      if(!!end) end("error")
    }
    finally{ // Siempre que finalize hara lo siguiente
      /* si la funcion 'end' existe se ejecuta */
      if(!!end) end("finally")
    }
  },
  /*****--------------------------------------------******/

  /* Eliminamos un dato de la db */
  DeleteTypeAccount: async (params, next, end) => {

    /* Consumo de la endpoint */
    const resp = await Api.delete(`/types/account/delete/${params.id}`) /*
    Obtenemos los datos de manera asincrona */

    /* Manejo de resultados */
    try { // Si todo va bien
      if(!!next) { /* si la funcion 'next' existe se ejecuta lo siguiente */
        let isEmptyData = resp.data.length < 0 /* Verificamos que el array
        o datos no este vacio*/

        if (!!next) next(resp.data, !isEmptyData)  /* Pasamos la data,
        y un valor que indique que no esta vacia la data */
      }
    }
    catch(e) { // En caso de errores
      console.error(`Error: Services institution - DeleteTypeAccount:`, e) /*
      En caso de error mostrar en que modulo y servicio */

      /* si la funcion 'end' existe se ejecuta */
      if(!!end) end("error")
    }
    finally{
      // Siempre que finalize hara lo siguiente
        /* si la funcion 'end' existe se ejecuta */
      if(!!end) end("finally")
    }
  },
  /*****--------------------------------------------******/
}
