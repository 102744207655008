<template>
<div class="card card-custom">
  <div class="card-header flex-wrap py-5">
    <div class="card-title">
      <h3 class="card-label">Column Rendering
        <div class="text-muted pt-2 font-size-sm">custom colu rendering</div>
      </h3>
    </div>
    <div class="card-toolbar">

      <div class="dropdown dropdown-inline mr-2">
        <button type="button" class="btn btn-light-primary font-weight-bolder dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span class="svg-icon svg-icon-md">
            <!--begin::Svg Icon | path:assets/media/svg/icons/Design/PenAndRuller.svg-->
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" style="display:inline;">
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect x="0" y="0" width="24" height="24"></rect>
                <path
                  d="M3,16 L5,16 C5.55228475,16 6,15.5522847 6,15 C6,14.4477153 5.55228475,14 5,14 L3,14 L3,12 L5,12 C5.55228475,12 6,11.5522847 6,11 C6,10.4477153 5.55228475,10 5,10 L3,10 L3,8 L5,8 C5.55228475,8 6,7.55228475 6,7 C6,6.44771525 5.55228475,6 5,6 L3,6 L3,4 C3,3.44771525 3.44771525,3 4,3 L10,3 C10.5522847,3 11,3.44771525 11,4 L11,19 C11,19.5522847 10.5522847,20 10,20 L4,20 C3.44771525,20 3,19.5522847 3,19 L3,16 Z"
                  fill="#000000" opacity="0.3"></path>
                <path
                  d="M16,3 L19,3 C20.1045695,3 21,3.8954305 21,5 L21,15.2485298 C21,15.7329761 20.8241635,16.200956 20.5051534,16.565539 L17.8762883,19.5699562 C17.6944473,19.7777745 17.378566,19.7988332 17.1707477,19.6169922 C17.1540423,19.602375 17.1383289,19.5866616 17.1237117,19.5699562 L14.4948466,16.565539 C14.1758365,16.200956 14,15.7329761 14,15.2485298 L14,5 C14,3.8954305 14.8954305,3 16,3 Z"
                  fill="#000000"></path>
              </g>
            </svg>
            <!--end::Svg Icon-->
          </span>Exportar</button>
        <!--begin::Dropdown Menu-->
        <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
          <!--begin::Navigation-->
          <ul class="navi flex-column navi-hover py-2">
            <li class="navi-header font-weight-bolder text-uppercase font-size-sm text-primary pb-2">Todas las optiones:</li>
            <li class="navi-item">
              <a class="navi-link" id="export_print_institution">
                <span class="navi-icon">
                  <i class="la la-print"></i>
                </span>
                <span class="navi-text">Imprimir</span>
              </a>
            </li>
            <li class="navi-item">
              <a class="navi-link" id="export_copy_institution">
                <span class="navi-icon">
                  <i class="la la-copy"></i>
                </span>
                <span class="navi-text">Copiar lista</span>
              </a>
            </li>
            <li class="navi-item">
              <a class="navi-link" id="export_excel_institution">
                <span class="navi-icon">
                  <i class="la la-file-excel-o"></i>
                </span>
                <span class="navi-text">Excel</span>
              </a>
            </li>
            <li class="navi-item">
              <a class="navi-link" id="export_csv_institution">
                <span class="navi-icon">
                  <i class="la la-file-text-o"></i>
                </span>
                <span class="navi-text">CSV</span>
              </a>
            </li>
            <li class="navi-item">
              <a class="navi-link" id="export_pdf_institution">
                <span class="navi-icon">
                  <i class="la la-file-pdf-o"></i>
                </span>
                <span class="navi-text">PDF</span>
              </a>
            </li>
          </ul>
          <!--end::Navigation-->
        </div>
        <!--end::Dropdown Menu-->
      </div>

      <router-link :to="{ name:'register-institution'}" class="btn btn-primary font-weight-bolder" tag="a">
        <i class="la la-plus"></i>Registrar institucion
      </router-link>

      <button type="button" class="btn btn-danger font-weight-bolder ml-2" v-show="institutionSelected.length > 0" data-toggle="modal" data-target="#staticBackdrop" >
        <i class="la la-trash"></i>Eliminar
      </button>

      <div class="modal fade" id="staticBackdrop" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Confirmación</h5>
            </div>
            <div class="modal-body">
              ¿ Desea eliminar las instituciones seleccionadas ?.
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-light-primary font-weight-bold" data-dismiss="modal">No</button>
              <button type="button" class="btn btn-primary font-weight-bold" data-dismiss="modal" id="delete-selected" @click="DeleteInstitutions">Si</button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="card-body">
    <Loading :active="loading">
      <table class="table table-separate table-head-custom table-checkable" id="institution_table">
      </table>
    </Loading>
  </div>
</div>
</template>

<script>
import Api from '@services/api'
import Loading from '@components/Loading.vue'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'datatable-index',
  components:{
    Loading,
  },
  computed: {
    ...mapState({
      institutionSelected: ({ institution }) => institution.selected,
      loading: ({ institution }) => institution.lifecicly.loading.showAll,
    }),
  },
  methods: {
    ...mapActions('institution', ['ShowAllInstitutions', 'DeleteInstitutions']),
  },
  mounted() {
    this.ShowAllInstitutions({ type:'initTable' })
  },
}
</script>
