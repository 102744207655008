<template>
<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" :width="size" :height="size" viewBox="0 0 172 172" style=" fill:#000000;">
  <defs>
    <linearGradient x1="86" y1="21.26708" x2="86" y2="53.75" gradientUnits="userSpaceOnUse" id="color-1_XbkA4ZAheeHU_gr1">
      <stop offset="0" stop-color="#4fe5ff"></stop>
      <stop offset="1" stop-color="#2eb7ed"></stop>
    </linearGradient>
    <linearGradient x1="76.95567" y1="19.98425" x2="91.48967" y2="74.23233" gradientUnits="userSpaceOnUse" id="color-2_XbkA4ZAheeHU_gr2">
      <stop offset="0" stop-color="#32bdef"></stop>
      <stop offset="1" stop-color="#1ea2e4"></stop>
    </linearGradient>
    <linearGradient x1="84.14383" y1="32.49367" x2="87.85617" y2="46.33967" gradientUnits="userSpaceOnUse" id="color-3_XbkA4ZAheeHU_gr3">
      <stop offset="0" stop-color="#16538c"></stop>
      <stop offset="1" stop-color="#0176d0"></stop>
    </linearGradient>
    <linearGradient x1="86" y1="129" x2="86" y2="57.33333" gradientUnits="userSpaceOnUse" id="color-4_XbkA4ZAheeHU_gr4">
      <stop offset="0" stop-color="#0176d0"></stop>
      <stop offset="1" stop-color="#16538c"></stop>
    </linearGradient>
    <linearGradient x1="31.76983" y1="64.629" x2="47.0635" y2="121.70433" gradientUnits="userSpaceOnUse" id="color-5_XbkA4ZAheeHU_gr5">
      <stop offset="0" stop-color="#32bdef"></stop>
      <stop offset="1" stop-color="#1ea2e4"></stop>
    </linearGradient>
    <linearGradient x1="39.41667" y1="121.83333" x2="39.41667" y2="129" gradientUnits="userSpaceOnUse" id="color-6_XbkA4ZAheeHU_gr6">
      <stop offset="0.365" stop-color="#32bdef"></stop>
      <stop offset="0.49" stop-color="#2fb7e9"></stop>
      <stop offset="0.66" stop-color="#26a5d7"></stop>
      <stop offset="0.854" stop-color="#1789bb"></stop>
      <stop offset="1" stop-color="#096fa1"></stop>
    </linearGradient>
    <linearGradient x1="39.41667" y1="64.5" x2="39.41667" y2="57.33333" gradientUnits="userSpaceOnUse" id="color-7_XbkA4ZAheeHU_gr7">
      <stop offset="0.365" stop-color="#32bdef"></stop>
      <stop offset="0.49" stop-color="#2fb7e9"></stop>
      <stop offset="0.66" stop-color="#26a5d7"></stop>
      <stop offset="0.854" stop-color="#1789bb"></stop>
      <stop offset="1" stop-color="#096fa1"></stop>
    </linearGradient>
    <linearGradient x1="124.9365" y1="64.629" x2="140.23017" y2="121.70433" gradientUnits="userSpaceOnUse" id="color-8_XbkA4ZAheeHU_gr8">
      <stop offset="0" stop-color="#32bdef"></stop>
      <stop offset="1" stop-color="#1ea2e4"></stop>
    </linearGradient>
    <linearGradient x1="132.58333" y1="121.83333" x2="132.58333" y2="129" gradientUnits="userSpaceOnUse" id="color-9_XbkA4ZAheeHU_gr9">
      <stop offset="0.365" stop-color="#32bdef"></stop>
      <stop offset="0.49" stop-color="#2fb7e9"></stop>
      <stop offset="0.66" stop-color="#26a5d7"></stop>
      <stop offset="0.854" stop-color="#1789bb"></stop>
      <stop offset="1" stop-color="#096fa1"></stop>
    </linearGradient>
    <linearGradient x1="132.58333" y1="64.5" x2="132.58333" y2="57.33333" gradientUnits="userSpaceOnUse" id="color-10_XbkA4ZAheeHU_gr10">
      <stop offset="0.365" stop-color="#32bdef"></stop>
      <stop offset="0.49" stop-color="#2fb7e9"></stop>
      <stop offset="0.66" stop-color="#26a5d7"></stop>
      <stop offset="0.854" stop-color="#1789bb"></stop>
      <stop offset="1" stop-color="#096fa1"></stop>
    </linearGradient>
    <linearGradient x1="78.35317" y1="64.629" x2="93.64683" y2="121.70433" gradientUnits="userSpaceOnUse" id="color-11_XbkA4ZAheeHU_gr11">
      <stop offset="0" stop-color="#32bdef"></stop>
      <stop offset="1" stop-color="#1ea2e4"></stop>
    </linearGradient>
    <linearGradient x1="86" y1="121.83333" x2="86" y2="129" gradientUnits="userSpaceOnUse" id="color-12_XbkA4ZAheeHU_gr12">
      <stop offset="0.365" stop-color="#32bdef"></stop>
      <stop offset="0.49" stop-color="#2fb7e9"></stop>
      <stop offset="0.66" stop-color="#26a5d7"></stop>
      <stop offset="0.854" stop-color="#1789bb"></stop>
      <stop offset="1" stop-color="#096fa1"></stop>
    </linearGradient>
    <linearGradient x1="86" y1="64.5" x2="86" y2="57.33333" gradientUnits="userSpaceOnUse" id="color-13_XbkA4ZAheeHU_gr13">
      <stop offset="0.365" stop-color="#32bdef"></stop>
      <stop offset="0.49" stop-color="#2fb7e9"></stop>
      <stop offset="0.66" stop-color="#26a5d7"></stop>
      <stop offset="0.854" stop-color="#1789bb"></stop>
      <stop offset="1" stop-color="#096fa1"></stop>
    </linearGradient>
    <linearGradient x1="86" y1="129" x2="86" y2="139.75" gradientUnits="userSpaceOnUse" id="color-14_XbkA4ZAheeHU_gr14">
      <stop offset="0" stop-color="#4fe5ff"></stop>
      <stop offset="1" stop-color="#2eb7ed"></stop>
    </linearGradient>
    <linearGradient x1="86" y1="139.75" x2="86" y2="150.5" gradientUnits="userSpaceOnUse" id="color-15_XbkA4ZAheeHU_gr15">
      <stop offset="0" stop-color="#4fe5ff"></stop>
      <stop offset="1" stop-color="#2eb7ed"></stop>
    </linearGradient>
  </defs>
  <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none"
    text-anchor="none" style="mix-blend-mode: normal">
    <path d="M0,172v-172h172v172z" fill="none"></path>
    <g>
      <path d="M86,21.26708l-68.08333,32.48292h136.16667z" fill="url(#color-1_XbkA4ZAheeHU_gr1)"></path>
      <path
        d="M154.08333,57.33333h-136.16667c-1.73433,0 -3.22142,-1.247 -3.526,-2.95267c-0.30458,-1.71283 0.65933,-3.38983 2.28975,-3.99183l68.08333,-32.48292c0.79908,-0.29383 1.68058,-0.29383 2.47608,0l68.08333,32.48292c1.63042,0.602 2.59433,2.28258 2.28975,3.99183c-0.30817,1.70567 -1.79525,2.95267 -3.52958,2.95267zM35.83333,50.16667h100.33333l-50.16667,-25.08333z"
        fill="url(#color-2_XbkA4ZAheeHU_gr2)"></path>
      <circle cx="24" cy="11" transform="scale(3.58333,3.58333)" r="2" fill="url(#color-3_XbkA4ZAheeHU_gr3)"></circle>
      <rect x="10" y="16" transform="scale(3.58333,3.58333)" width="28" height="20" fill="url(#color-4_XbkA4ZAheeHU_gr4)"></rect>
      <rect x="9" y="18" transform="scale(3.58333,3.58333)" width="4" height="16" fill="url(#color-5_XbkA4ZAheeHU_gr5)"></rect>
      <path d="M46.58333,121.83333h-14.33333c-1.978,0 -3.58333,1.60533 -3.58333,3.58333v3.58333h21.5v-3.58333c0,-1.978 -1.60533,-3.58333 -3.58333,-3.58333z" fill="url(#color-6_XbkA4ZAheeHU_gr6)"></path>
      <path d="M50.16667,57.33333h-21.5v3.58333c0,1.978 1.60533,3.58333 3.58333,3.58333h14.33333c1.978,0 3.58333,-1.60533 3.58333,-3.58333z" fill="url(#color-7_XbkA4ZAheeHU_gr7)"></path>
      <rect x="35" y="18" transform="scale(3.58333,3.58333)" width="4" height="16" fill="url(#color-8_XbkA4ZAheeHU_gr8)"></rect>
      <path d="M139.75,121.83333h-14.33333c-1.978,0 -3.58333,1.60533 -3.58333,3.58333v3.58333h21.5v-3.58333c0,-1.978 -1.60533,-3.58333 -3.58333,-3.58333z" fill="url(#color-9_XbkA4ZAheeHU_gr9)"></path>
      <path d="M143.33333,57.33333h-21.5v3.58333c0,1.978 1.60533,3.58333 3.58333,3.58333h14.33333c1.978,0 3.58333,-1.60533 3.58333,-3.58333z" fill="url(#color-10_XbkA4ZAheeHU_gr10)"></path>
      <rect x="22" y="18" transform="scale(3.58333,3.58333)" width="4" height="16" fill="url(#color-11_XbkA4ZAheeHU_gr11)"></rect>
      <path d="M93.16667,121.83333h-14.33333c-1.978,0 -3.58333,1.60533 -3.58333,3.58333v3.58333h21.5v-3.58333c0,-1.978 -1.60533,-3.58333 -3.58333,-3.58333z" fill="url(#color-12_XbkA4ZAheeHU_gr12)"></path>
      <path d="M96.75,57.33333h-21.5v3.58333c0,1.978 1.60533,3.58333 3.58333,3.58333h14.33333c1.978,0 3.58333,-1.60533 3.58333,-3.58333z" fill="url(#color-13_XbkA4ZAheeHU_gr13)"></path>
      <path d="M21.5,139.75h129v-7.16667c0,-1.978 -1.60533,-3.58333 -3.58333,-3.58333h-121.83333c-1.978,0 -3.58333,1.60533 -3.58333,3.58333z" fill="url(#color-14_XbkA4ZAheeHU_gr14)"></path>
      <path d="M14.33333,150.5h143.33333v-7.16667c0,-1.978 -1.60533,-3.58333 -3.58333,-3.58333h-136.16667c-1.978,0 -3.58333,1.60533 -3.58333,3.58333z" fill="url(#color-15_XbkA4ZAheeHU_gr15)"></path>
    </g>
  </g>
</svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#000000',
    },
    size: {
      type: String,
      default: '24',
    }
  }
}
</script>
