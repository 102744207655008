/* Importamos de dataTableInstitution para eliminar de la tabla cuando haya
un cambio en los datos que estan siendo usados de este modulo */
import dataTableHeadquarter from '@components/admin/headquarter/index/dataTable'
const { updateTbDataHeadquarter, deleteTbDataHeadquarter, getTbHeadquarter } = dataTableHeadquarter

/* Utiliades y ayudantes */
import { keyBy, cleanObject } from '@tools/util'
import { cloneDeep, remove, findIndex, update } from 'lodash'

/* Servicios de consumo de api */
import Api from '@services/modules/institution.js'

/* Importamos las funciones para actualizar, agregar e iniciar la dataTable de ese modulo */
import DTInstitution from '@components/admin/institution/index/dataTable'
const { initTbInstitution, addTbInstitution, updateTbInstitution } = DTInstitution

/* Funcion para iniciar la dataTable */
const initTable = (state, payload) => {
  const {commit, dispatch} = payload // extraemos

  /* Preguntamos si el tipo es 'initTable' para
  posteriomente montar la tabla */
  if(payload.type == "initTable") {

    /* se monta la dataTable */
    initTbInstitution(
      {commit, dispatch}, /* funciones para
      llamar a mutations y actions*/
      { institutions: state.institutions } /* Datos
      para mostrar en tabla*/
    )
  }
}

export default {
  namespaced: true, //hace que sea global el modulo en vuex
  state: () => ({
    lifecicly:{ //Ciclo de vida del componente
      mounted:{ //Cuando se monta
        /* Hacen referencia a las actions */
        showAll:false,
        show:false,
        edit:false,
      },
      watcher:{
        /* Cuando hay que calcular un cambio
        en tiempo real */
        change:false,
      },
      loading:{
        /* Se Usan para muestran los una espera
        de una operacion hasta que termine */
        register:false,
        update:false,
        showAll:true,
      },
    },

    newInstitution:{ //Registrar - solo uno
      type_institution_id: '',
      type_account_id: '',
      language:'',
      alias: '',
      name:'',

      hero_image: null,
      logo: null,
    },

    copyInstitution:{}, /* Copia para comparar
    o cancelar y volver al estado original */

    institution:{}, //solo uno
    selected:[], /* Se usa para almacenar los ids de la
    dataTable cuando seleccionamos multiples 'checkable'
    que se quieres eliminar */

    institutions: [], //todos
    $institutions: {}, /* $ -> significa que esto sera
    un objeto, para acceder rapidamente a las propiedades
    sin afectar el rendimiendo con un find, map, o forEach */
  }),
  mutations: {

    /* Llamamos a todos los datos para insertar e inicia la tabla */
    SHOW_ALL_INSTITUTIONS(state, payload){

      /* Si no se a montado el componente lo montamos llamando a la
      Api e insertando la data en la dataTable */
      if (!state.lifecicly.mounted.showAll) { //negacion

        state.lifecicly.mounted.showAll = true  /* establecemos
        en 'true' para que se sepa que ya la data esta, y no
        hay que volver a ejecutar un llamado a la Api */

        /*LLamado a la Api */
        Api.AllInstitutions(
          /* Obtenemos la data y un validador */
          (data, /*validate*/) => {
            state.institutions = data /* Insertamos la data y
            la ordenamos por el nombre */
            state.$institutions = keyBy(data, 'id') /* Insertamos
            la data transformandola en objeto y con el id como llave
            por defecto -> $objeto['numero_de_id'].propiedad */

            initTable(state, payload) /* Instanciamos la dataTable
            e insertamos los datos*/
          },
          (type) => {
            /* Si hay un error type sera 'error', si no sera 'finally'.
            Si no se espesfica nada por defecto siempre es finally */
            if (type=="error") {
              initTable(state, payload) /* Instanciamos la dataTable
              e insertamos los datos aun si esta vacia la data */
            }

            state.lifecicly.loading.showAll = false /* Desactivamos
            el loading */
          }
        )
      } else initTable(state, payload) /* De lo contrario ya se
      llamo  a la Api y la data ya existe, no hace falta volverla
      a llamar, solo hay que insertarla en la dataTable */
    },
    /*****----------------------------------------------------*****/

    /* Llamamos un dato para almacenar y visualizar dicho dato actual */
    SHOW_INSTITUTION(state, payload) {

      /* Si no venimo de darle al boton edit en la dataTable
      entonces llamamos Api */
      if (!state.lifecicly.mounted.edit) { //negacion

        /* Si no se a montado el componente actual, entonces
        llamamos la Api y guardamos la data */
        if (!state.lifecicly.mounted.show) { //negacion

          state.lifecicly.mounted.show = true /* establecemos
          en 'true' para que se sepa que ya la data esta, y no
          hay que volver a ejecutar un llamado a la Api */

          /*LLamado a la Api */
          Api.OneInstitution(
            { id: payload }, // id para buscar dato en db
            /* Obtenemos la data y un validador */
            (data, /*validate*/) => {
              state.institution = data /* Insertamos la data */
              state.copyInstitution = cloneDeep(data) /* hacemos
              una copia para que cuando se modifique la data no afecte
              la relacion con la original y para poder restablece la data
              si se modifica, con esta copia */
            }
          )
        }
      }
    },
    /*****----------------------------------------------------*****/

    /* Registramos un dato en la db y lo agregamos a la dataTable */
    REGISTER_INSTITUTION(state, payload){

      //Obligatorio para enviar imagenes al servidor
      let newInstForm = new FormData()

      /* Recorremos los campos para insertarlos en un objeto */
      for (const field in state.newInstitution) {
        //Insertamos la data recorriendo sus campos
        newInstForm.append(field, state.newInstitution[field])/*
        Agregamos el nombre del campo y el valo del campo */
      }
      state.lifecicly.loading.register = true /* Activamos el loading */

      /*LLamado a la Api */
      Api.CreateInstitution(
        newInstForm, // data del form a insertar en db
        /* Obtenemos la data y un validador */
        (data, validate) => {
          if (validate) { //Verificar que no venga vacia

            state.$institutions[data.id] = data /* creamos la nueva
            propiedad en el objeto y le asignamos la data */
            state.institutions.push(data) /* Insertamos el nuevo dato
            en el array que contiene todos los datos existente */

            addTbInstitution(data) /* Agregamos a la tabla la data
            y refrescamos */

            /* Limpiamos los campos */
            state.newInstitution.hero_image = null
            state.newInstitution.logo = null /* Esto es por que da
            un error al ser un objeto */
            cleanObject(state.newInstitution)

            payload.successCreateForm() /* Mostramos un
            mensaje de success */
          }
          else payload.errorCreateForm() /* Mostramos un
            mensaje de error */
        },
        (type) => {
          /* Si hay un error type sera 'error', si no sera 'finally'.
          Si no se espesfica nada por defecto siempre es finally */

          if(type=="error") payload.errorCreateForm() /* Mostramos un
          mensaje de error */
          state.lifecicly.loading.register = false /* Desactivamos
          el loading */
        }
      )
    },
    /*****----------------------------------------------------*****/

    /* Actualizamos un dato en la db y en a la dataTable */
    UPDATE_INSTITUTION(state, payload) {
        const tbHeadquarter = getTbHeadquarter()
        const { $state } = payload

        if (state.lifecicly.watcher.change) {
          //Obligatorio para enviar imagenes al servidor
          let updateInstForm = new FormData()

          /* Recorremos los campos para insertarlos en un objeto */
          for (const field in state.institution) {
            //Insertamos la data recorriendo sus campos
            updateInstForm.append(field, state.institution[field]) /*
            Agregamos el nombre del campo y el valo del campo */
          }

          state.lifecicly.loading.update = true /* Activamos el loading */
          let id = state.institution.id /* Guardamos el id actual
          en una variable para no tener que llamar objeto tan largo */

          /*LLamado a la Api */
          Api.UpdateInstitution(
            { params: { id }, data:updateInstForm}, /* Data para
            actualizar en db y id para identificar el que se actualizara */
            /* Obtenemos la data y un validador */
            (data, validate) => {
              if (validate) { //Verificamos que no venga vacia

                state.lifecicly.watcher.change = false /* Una ves ya esten
                listos los cambios y se envien la db, se vuelve a hacer,
                una copia de la nueva modificación*/

                // Obtenemos el index por medio de id del elemento
                let index = findIndex(state.institutions, {id})

                map($state['headquarter'].headquarters, (headquarter, index) => {
                  if (headquarter.institution_id == id) {
                    $state['headquarter'].$headquarters[headquarter.id].institution = data
                    $state['headquarter'].headquarters[index].institution = data
                    updateTbDataHeadquarter(tbHeadquarter, headquarter)
                  }
                })

                //Remplazamos la informacion por la que se actualizo
                state.institutions.splice(index, 1, data)

                state.institution = data /* Actualizamos los datos
                del campo del store que lo amerite */
                state.$institutions[id] = data /* $ -> buscamos y
                remplazamos el objeto */

                /* Copia para comparar cuando se quiera volver a actualizar,
                la finalidad es saber si hay cambios y permitir actualizar
                o restablece los cambios echos si no se quiere actualizar */
                state.copyInstitution = cloneDeep(data)

                updateTbInstitution(data) /* Actualizamos la dataTable
                insertando la informacion actualizada */

                payload.successUpdateForm() /* Mostramos un
                mensaje de success */
              }
              else payload.errorUpdateForm() /* Mostramos un
              mensaje de error */
            },
            (/*type, validate*/) => {
              /* Si hay un error type sera 'error', si no sera 'finally'.
              Si no se espesfica nada por defecto siempre es finally */

              state.lifecicly.loading.update = false /*
              Desactivamos el loading */
            }
          )
        } else payload.differentUpdateForm() /* Mostramos un
        mensaje de no haber cambios en el form */
    },
    /*****----------------------------------------------------*****/

    /* Eliminamos un dato en la db y en a la dataTable */
    DELETE_INSTITUTION(state, payload){
      const tbHeadquarter = getTbHeadquarter()
      const { $state } = payload
      /*LLamado a la Api */
      Api.DeleteInstitution(
        {id: payload.id }, /* Pasamos el id
        para eliminar solo ese dato */
        /* Podemos obtener la data y un validador */
        (/*data, validate*/) => {

          remove($state['headquarter'].headquarters, (headquarter) => {
            if (headquarter.institution_id == payload.id) {

              /* Eliminar de la dataTable de un modulo que usa
              datos en una o varias columnas de este modulo actual */
              deleteTbDataHeadquarter(tbHeadquarter, headquarter.id)

              delete $state['headquarter'].$headquarters[headquarter.id]
              return headquarter
            }
          })

          delete state.$institutions[payload.id] /* Eliminamos
          la propiedad del objeto */
          remove(state.institutions, { id: payload.id }) /* Removemos
          el dato del array de datos */
        }
      )
    },
    /*****----------------------------------------------------*****/

    /* Eliminamos varios datos de la db y de la dataTable */
    DELETE_MANY_INSTITUTIONS(state){
      const tbHeadquarter = getTbHeadquarter()
      const { $state } = payload
      let end = 0 // iniciamos un contador en 0

      /* Recorremos el array que contiene los ids que se
      seleccionaron para ser eliminados en la dataTable */
      state.selected.forEach(async (id) => {
        end++ // aumentamos el contador

        /*LLamado a la Api */
        Api.DeleteInstitution(
          { id }, /* Pasamos el id para eliminar solo ese dato */
          /* Podemos obtener la data y un validador */
          (/*data, validate*/) => {

            remove($state['headquarter'].headquarters, (headquarter) => {
              if (headquarter.institution_id == payload.id) {

                /* Eliminar de la dataTable de un modulo que usa
                datos en una o varias columnas de este modulo actual */
                deleteTbDataHeadquarter(tbHeadquarter, headquarter.id)

                delete $state['headquarter'].$headquarters[headquarter.id]
                return headquarter
              }
            })

            delete state.$institutions[id] /* Eliminamos la
            propiedad del objeto */
            remove(state.institutions, { id }) /* Removemos
            el dato del array de datos */
            deleteTbHeadquarter(id, 'institution_id') /* Eliminar de
            la dataTable de un modulo que usa datos en una o varias columnas
            de este modulo actual */
          }
        )
      })
      state.selected = [] /* Vaciamos una ves ya esten eliminados
      los ids que estaban aqui */
    },
  },
  actions: {
    /* Llamamos a todos los datos */
    ShowAllInstitutions({ commit, dispatch }, payload) {
      /* Payload -> type == "initTable" o null */
      commit('SHOW_ALL_INSTITUTIONS', { commit, dispatch, ...payload })
    },
    /* Llamamos a un solo dato */
    ShowInstitution({ commit }, payload) {
      /* Payload -> 'number': un id del dato que se quiere obtener */
      commit('SHOW_INSTITUTION', payload)
    },
    /* Registramos a un solo dato */
    RegisterInstitution({ commit }, payload) {
      /* payload -> successRegisterForm: ƒ, errorRegisterForm: ƒ */
      commit('REGISTER_INSTITUTION', payload)
    },
    /* Actualizamos a un solo dato */
    UpdateInstitution({ commit, rootState }, payload) {
      const $state = rootState
      /* payload -> successUpdateForm: ƒ, errorUpdateForm: ƒ, differentUpdateForm: ƒ */
      commit('UPDATE_INSTITUTION', {commit, $state, ...payload})
    },
    /* Eliminamos varios datos */
    DeleteInstitutions({ commit, rootState }) {
      const $state = rootState
      /* Para eliminar no se necesita id por que en el array 'selected'
      estan los ids que se seleccionaron para ser eliminados esos datos */
      commit('DELETE_MANY_INSTITUTIONS', { $state })
    },
    /* Eliminamos varios datos */
    DeleteInstitution({ commit, rootState }, payload) {
      const $state = rootState
      commit('DELETE_INSTITUTION', { $state, ...payload })
    },
  }
}
