<template>
<div class="form form-group row">
  <label class="col-xl-3 col-lg-3 col-form-label">Selecciona una institucion</label>
  <div class="typeahead col-lg-9 col-xl-9">
      <input class="form-control form-control-lg form-control-solid" id="kt_typeahead_search" placeholder="Busca por nombre o alias" @input="inputValue" name="institution_id"/>
      <span class="icon-append">
        <i class="flaticon2-search-1 icon-md"></i>
      </span>
      <span class="icon-preppend" id="clean-input" v-show="emptyInput">
        <i class="la la-close icon-lg"></i>
      </span>
    </div>
</div>
<div v-show="compareValuesInput">
  <slot></slot>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { watchEffect } from 'vue'

import initSearchInstitution from './search.js'
export default {
  name: '',
  emits:['select'],
  data() {
    return {
      value: '$',
      input: '',
    }
  },
  computed: {
    ...mapState({
      institutions: ({ institution }) => institution.institutions,
      headquarter: ({ headquarter }) => headquarter.newHeadquarter,
    }),
    emptyInput(){
      return this.input != ''
    },
    compareValuesInput(){
      return this.input == this.value
    }
  },
  methods: {
    ...mapActions('institution', ['ShowAllInstitutions']),
    inputValue({ target }) {
      this.input = target.value
    },
    selectInstituionId(id, value) {
      this.value = value
      this.$emit('select', id)
    },
  },
  mounted() {
    this.ShowAllInstitutions()
    watchEffect(() => {
      if(this.institutions.length){
        initSearchInstitution(
        this.institutions,
        {
          selectInstituionId: this.selectInstituionId,
          inputValue: this.inputValue,
        },
        )
      }
    })
  },
}
</script>

<style media="screen">
  #kt_typeahead_search{
    padding-left: 35px;
    padding-right: 30px;
  }
  .icon-append{
    position: absolute;
    top: 10px;
    left: 22px;
  }
  .icon-preppend{
    position: absolute;
    top: 11px;
    right: 22px;
  }
  .typeahead .tt-menu{
    padding: 20px !important;
    width: 100%;
  }
  .typeahead .tt-hint{
    padding-left: 35px;
  }
</style>
