<template>
<div class="destails uk-active">

  <div class="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">

    <button class="card hover:shadow-md" @click="goToTab('locations')">
      <div class="w-full flex items-start p-5 rounded-lg space-x-3 justify-between">
        <div class="text-left">
          <h3 class="text-lg font-semibold text-one">Locations</h3>
          <div class="flex space-x-2 items-center text-sm pt-0.5">
            View Locations
          </div>
        </div>
        <IconLocation size="40px" color="#28aae1"/>
      </div>
    </button>

    <button class="card hover:shadow-md" @click="goToTab('programs')">
      <div class="w-full flex items-start p-5 rounded-lg space-x-3 justify-between">
        <div class="text-left">
          <h3 class="text-lg font-semibold text-one">Programs</h3>
          <div class="flex space-x-2 items-center text-sm pt-0.5">
            View Programs
          </div>
        </div>
        <IconGraduation size="50px" color="#28aae1" />
      </div>
    </button>

    <button class="card hover:shadow-md" @click="goToTab('gallery')">
      <div class="w-full flex items-start p-5 rounded-lg space-x-3 justify-between">
        <div class="text-left">
          <h3 class="text-lg font-semibold text-one">Gallery</h3>
          <div class="flex space-x-2 items-center text-sm pt-0.5">
            View Gallery
          </div>
        </div>
        <IconGallery size="50px" color="#28aae1"/>
      </div>
    </button>
  </div>

  <p class="mt-10 text-justify" v-html="$headquarters[id]?.description"></p>

  <div class="grid grid-cols-2 gap-2 mt-10">
    <div v-for="(image, index) in $headquarters[id]?.gallery.slice(0,4)">
      <div :style="imgStyle(image)"></div>
    </div>
  </div>
</div>
</template>

<script>
import IconGraduation from '@components/icons/Graduation.vue'
import IconLocation from '@components/icons/Location.vue'
import IconGallery from '@components/icons/Gallery.vue'
import {kebabCase} from 'lodash'
import {mapState} from 'vuex'

const url_base = 'https://you-worldwide.com/api/v1'

export default {
  components: {
    IconGraduation,
    IconLocation,
    IconGallery,
  },
  data(){
    return {
      id:'',
    }
  },
  computed: {
    ...mapState({
      $headquarters: ({ headquarter }) => headquarter.$headquarters,
    }),
  },
  methods: {
    goToTab(name) {
      $(`#${name}`).trigger("click");
    },
    imgStyle(img) {
      let url = '', nameFile = ''

      if (img) {
        let logoName = img || 'placeholder.png'
        let arr = ["about-1-jpeg","about-2-jpeg","about-3-jpeg","about-4-jpeg"]

        if (arr.includes(img)) nameFile = 'test'
        else nameFile = kebabCase(this.$headquarters[this.id]?.name) || 'placeholder'

        url = `${url_base}/headquarter/gallery/${nameFile}/${logoName}`
      }

      return {
        backgroundImage: `url(${url})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        height: '250px',
        width: '100%',
      }
    }
  },
  mounted(){
    this.id = this.$route.params.id
  },
  watch:{
    $route(to, from){
      this.id = to.params?.id
    }
  }
}
</script>
