<template>
<div class="w-full">

  <div class="mb-4">
    <div class="text-xl font-semibold"> A whole world of institutions to review </div>
    <div class="text-sm mt-1"> Explore the best studies or courses in the best institutions, even with stay. </div>
  </div>

  <!-- <nav class="cd-secondary-nav border-b md:m-0 -mx-4 nav-small">
    <ul>
      <li class="active"><a href="#" class="lg:px-2"> online </a></li>
      <li><a href="#" class="lg:px-2"> presencial </a></li>
    </ul>
  </nav> -->

  <Paginate :items="filterByCountry" v-if="filterByCountry.length >= 1">
    <template v-slot="{headquarter}">

        <div class="h-48 md:h-auto md:w-60 flex-none bg-cover rounded-t-md md:rounded-t-none md:rounded-l-md text-center overflow-hidden" :style="`background-image: url(${imageCover(headquarter)})`">
        </div>

        <div class="w-full bg-white px-4 pt-2 pb-3 flex flex-col relative rounded-b-md md:rounded-b-none md:rounded-r-md">

          <div class="flex absolute -top-7 md:top-2 right-3 bg-white border border-gray-300 rounded px-2 py-1">
            <strong class="text-xs pr-2 pt-1">Lodgings</strong>
            <IconHotelReserve size="24" />
          </div>

          <div class="w-full mb-10">
            <div class="text-one font-bold text-xl md:text-2xl p-0 md:pr-36">
              {{headquarter.name}}
            </div>

            <p class="mb-3 text-xs">
              <span>{{ capitalize(headquarter.country) }} - {{ capitalize(headquarter.state) }} - {{capitalize(headquarter.city) }}</span>
            </p>

            <p class="text-gray-700 text-sm">
              <ul class="grid grid-cols-1" v-if="!headquarter.study_programs?.length">
                <li><i class="uil-check mr-2"></i>For Juniors</li>
                <li><i class="uil-check mr-2"></i>Instensive Chinese course</li>
              </ul>
              <ul :class="`grid grid-cols-1`" v-else>
                <li v-for="(study_program, index_st) in headquarter.study_programs.slice(0, 5)" key="index_st">
                  <i class="uil-check mr-2"></i>{{$studies[study_program.study_id]?.title}}
                </li>
              </ul>
            </p>
          </div>

          <div class="flex gap-2 absolute bottom-2 right-3">
              <router-link class="bg-two text-xs text-white font-semibold py-1 px-4 border border-two rounded" @click="scrollTop()" :to="{name: 'profile-institution', params: { name:headquarter.name, id:headquarter.id} }">
                 View Profile
              </router-link>
              <a href="#" class="bg-transparent text-xs text-two font-semibold py-1 px-3 border border-two rounded"> Prices </a>
          </div>
        </div>
    </template>
  </Paginate>

  <div class="flex w-100 h-40 relative" v-else>
    <Loading :active="filterByCountry.length < 1 && empty" />
    <div class="w-full pt-8 " v-if="filterByCountry.length < 1 && !empty">
      <div class="text-xl font-semibold line-clamp-2 leading-8
      text-center border border-gray p-10 rounded-md">
        <IconWarning size="40px" color="#28aae1" /> Sorry, this country does not have institutions available
      </div>
    </div>
  </div>

</div>
</template>

<script>
import IconHotelReserve from '@components/icons/HotelReserve.vue'
import Paginate from '@components/guest/Paginate.vue'
import Loading from '@components/Loading.vue'
import IconWarning from '@components/icons/Warning.vue'

import { mapState, mapActions, mapGetters } from 'vuex'
import Api from '@services/modules/headquarter.js'
import { lowerCase, capitalize } from 'lodash'
const url_base = 'https://you-worldwide.com/api/v1'

export default {
  components: {
    IconHotelReserve,
    IconWarning,
    Paginate,
    Loading,
  },
  data() {
    return {
      empty: true,
    }
  },
  computed: {
    ...mapState({
      $studies: ({
        study
      }) => study.$studies,
    }),
    ...mapGetters('destination', ['filterByCountry']),
  },
  methods: {
    ...mapActions('study', ['ShowAllStudies']),
    ...mapActions('destination', ['HeadquarterByCountries']),
    scrollTop() {
      $("#btn-top").trigger("click")
    },
    imageCover(headquarter) {
      let url = ''

      let logoName = headquarter.cover || 'placeholder.png'
      url = `${url_base}/headquarter/cover/${logoName}`

      return url
    },
    numberColums(arr){
      if ( arr.length <= 3 ) return 1
      else return 2
    },
    capitalize(value) {
      return capitalize(value)
    },
  },
  watch: {
    $route(to) {
      this.HeadquarterByCountries({
        params: {
          name: lowerCase(to.params.name)
        }
      })
    }
  },
  mounted() {
    this.scrollTop()
    this.HeadquarterByCountries({
      params: {
        name: lowerCase(this.$route.params.name)
      }
    })
    setTimeout(() => this.empty = false, 7000)
    this.ShowAllStudies()
  }
}
</script>


<style scoped>
.cd-secondary-nav li.active a,
.cd-secondary-nav li.uk-active a {
  border-bottom: 3px solid #28aae1 !important;
}

.cd-secondary-nav li.active a,
.cd-secondary-nav li.uk-active a,
.cd-secondary-nav li:hover a {
  color: #28aae1 !important;
}
</style>
