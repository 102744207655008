<template>
<div class="pb-5">
  <div class="row">
    <div class="col-xl-12">
      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label">Logo institucional</label>
        <div class="col-lg-9 col-xl-9">
          <div class="image-input image-input-outline" id="kt_contact_add_avatar">
            <div class="image-input-wrapper" :style="logoPlaceholder"></div>
            <label class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="change" data-toggle="tooltip" title="" data-original-title="Change avatar">
              <i class="fa fa-pen icon-sm text-muted"></i>
              <input type="file" name="logo" accept=".png, .jpg, .jpeg" @input="UpdateImageIntitution" />
              <input type="hidden" name="profile_avatar_remove"/>
            </label>
            <span class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="cancel" data-toggle="tooltip" title="Cancel avatar"
                @click="deleteIntitutionImage" id="deleteLogoInstitution">
              <i class="ki ki-bold-close icon-xs text-muted"></i>
            </span>
          </div>
        </div>
      </div>

      <UploadHerouImage @input="UpdateImageIntitution" />

      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label">Nombre del Instituto</label>
        <div class="col-lg-9 col-xl-9">
          <input class="form-control form-control-lg form-control-solid" name="name" type="text" placeholder="Instituto" :value="institution.name" @input="UpdateFieldIntitution"/>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label">Alias Del Instituto</label>
        <div class="col-lg-9 col-xl-9">
          <input class="form-control form-control-lg form-control-solid" name="alias" type="text" placeholder="@Alias" :value="institution.alias" @input="UpdateFieldIntitution"/>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label">Tipo de instituto</label>
        <div class="col-lg-9 col-xl-9">

          <SelectTypeInstitution name="type_institution_id" @input="UpdateFieldIntitution" :value="institution.type_institution_id" />

          <div class="fv-plugins-message-container"></div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label">Seleccionar idioma principal</label>
        <div class="col-lg-9 col-xl-9">
          <select name="language"
            class="form-control form-control-lg form-control-solid"
            @input="UpdateFieldIntitution" :value="institution.language">
            <option hidden selected value="">Seleciona el Idioma...</option>
            <option value="in">INGLÉS</option>
            <option value="es">ESPAÑOL</option>
            <option value="it">ITALIANO</option>
            <option value="fra">FRANCÉS</option>
            <option value="man">MANDARÍN</option>
            <option value="ale">ALEMÁN</option>
          </select>
          <div class="fv-plugins-message-container"></div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label">Tipo de Cuenta</label>
        <div class="col-lg-9 col-xl-9">

          <SelectTypeAccount :name="'type_account_id'" @input="UpdateFieldIntitution" :value="institution.type_account_id"/>

          <div class="fv-plugins-message-container"></div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import SelectTypeAccount from '@components/admin/typeAccount/Select.vue'
import SelectTypeInstitution from '@components/admin/typeInstitution/Select.vue'
import UploadHerouImage from '@components/admin/institution/UploadHerouImage.vue'

import {mapState, mapActions} from 'vuex'

export default {
  components:{
    UploadHerouImage,
    SelectTypeAccount,
    SelectTypeInstitution,
  },
  computed: {
    ...mapState({
      institution: ({institution}) => institution.newInstitution,
      typeInstitutions: ({ typeInstitution }) => typeInstitution.typeInstitutions,
      typeAccounts: ({ typeAccount }) => typeAccount.typeAccounts,
    }),

    logoPlaceholder() {
      return `
        border-radius:50%;
        background-position: center;
        background-image: url(${require('@assets/images/placeholder.png')});
      `
    },
  },
  methods: {
    ...mapActions('global', ['EditFieldState', 'DelFieldState']),
    ...mapActions('typeInstitution', ['ShowAllTypeInstitutions']),
    ...mapActions('typeAccount', ['ShowAllTypeAccounts']),

    UpdateImageIntitution(e) {
      this.EditFieldState({
        state: 'institution',
        type: 'newInstitution',
        field: e.target.name,
        value: e.target.files[0],
      })
    },
    deleteIntitutionImage() {
      this.DelFieldState({
        state: 'institution',
        type: 'newInstitution',
        field: 'logo',
      })
    },
    UpdateFieldIntitution(e) {
      this.EditFieldState({
        state: 'institution',
        type:'newInstitution',
        field: e.target.name,
        value: e.target.value,
      })
    }
  },
  mounted(){
    this.ShowAllTypeInstitutions()
    this.ShowAllTypeAccounts()
  },
};
</script>
