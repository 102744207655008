<template>
<div class="tube-card px-4" uk-toggle="cls: tube-card p-4; mode:media; media:640">

  <h4 class="py-3 px-5 border-b mb-4">
    <strong class="text-recommendation">Recommended destinations to study at a distance</strong>
  </h4>

  <div class="relative uk-slider" uk-slider="autoplay: true; finited: true;">

    <div class="uk-slider-container md:px-1 px-2 py-3">
      <ul class="uk-slider-items uk-child-width-1-3@m uk-child-width-1-2 uk-grid-small uk-grid" style="transform: translate3d(-323.875px, 0px, 0px);">
        <template v-for="(recommendation, index) in recommendations" :key="index">

          <li tabindex="1">
            <router-link :to="recommendation.url" @click="activeFilterByState(recommendation.title)">
              <div class="w-full md:h-40 h-28 overflow-hidden rounded-lg relative">
                <img :src="recommendation.image" alt="" class="w-full h-full absolute inset-0 object-cover">
              </div>
            </router-link>
            <div class="pt-3 font-semibold line-clamp-2">{{recommendation.title}}</div>
          </li>

        </template>
      </ul>

      <a class="uk-position-center-left uk-position-small bg-white flex items-center justify-center p-2 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white" uk-slider-item="previous">
        <IconBack/>
      </a>
      <a class="uk-position-center-right uk-position-small bg-white flex items-center justify-center p-2 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white uk-invisible" uk-slider-item="next">
        <IconForward/>
      </a>
    </div>

  </div>

</div>
</template>

<script>
import recommendations from '@services/data/location/recommendations.js'
import IconForward from '@components/icons/Forward.vue'
import IconBack from '@components/icons/Back.vue'
import {lowerCase} from 'lodash'
import {mapActions} from 'vuex'

export default {
  components:{
    IconBack,
    IconForward
  },
  data(){
    return {
      recommendations,
    }
  },
  methods:{
    ...mapActions('destination', [
      'AddStateInFilter'
    ]),
    activeFilterByState(state){
      let stateN = lowerCase(state)
      this.AddStateInFilter(stateN)
      $( "#btn-top" ).trigger( "click" );
    }
  },
}
</script>

<style scoped>
  .text-recommendation {
    font-size: 20px;
    color: #05a4e8;
  }
  @media (max-width: 640px) {
    .text-recommendation{
      font-size: calc(4vw - 0.5%);
    }
  }
</style>
