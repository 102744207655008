<template>
<div class="block relative">
  <div class="overflow-hidden lg:-mt-20" tabindex="-1" style="filter: brightness(0.5);">
    <div :style="background" class="object-cover uk-cover w-full h-custom"></div>
  </div>
  <div class="absolute bottom-1 md:-bottom-10 left-5 md:left-20">
    <div class="flex flex-wrap p-1">

      <div class="overflow-hidden bg-white rounded-lg mb-4 shadow-md w-custom">
        <div :style="imageInstitution" class="h-48 object-cover w-full"></div>
      </div>
      <div class="flex-auto flex items-center">

        <div class="mt-1 md:-mt-5 ml-3">
          <h1 class="text-lg lg:text-3xl md:text-2xl font-bold text-white uppercase">{{$headquarters[id]?.name}}</h1>
          <span class="inline text-xl lg:text-3xl text-white uppercase"> {{ $headquarters[id]?.country }} {{ showGuion($headquarters[id]?.state)}} {{showGuion($headquarters[id]?.city) }}</span>
        </div>

      </div>

    </div>
  </div>
</div>
</template>

<script>
import location from '@components/guest/Location.vue'
import {mapState} from 'vuex'

const url_base = 'https://you-worldwide.com/api/v1'

export default {
  components: {
    location
  },
  data(){
    return {
      id:'',
    }
  },
  computed: {
    ...mapState({
      $headquarters: ({ headquarter }) => headquarter.$headquarters,
    }),
    background() {
      let url = ''

      if (this.$headquarters[this.id]) {
        let logoName = this.$headquarters[this.id]?.institution.hero_image || 'placeholder.png'
        let nameFile = ''
        if (logoName == 'placeholder.png') nameFile = 'placeholder'

        url = `${url_base}/institution/hero/${logoName}`
      }

      return {
        backgroundImage: `url(${url})`,
        backgroundPosition: '50% 32%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }
    },
    imageInstitution() {
      let url = ''
      if (this.$headquarters[this.id]?.institution.logo) {
        let logoName = this.$headquarters[this.id]?.institution.logo || 'placeholder.png'
        url = `${url_base}/institution/logo/${logoName}`
      }
      return {
        backgroundImage: `url(${url})`,
        //width: '420px',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '95% auto',
        //height: '220px',
      }
    }
  },
  methods:{
    showGuion(name){
      if (name) return ' - '+name;
    },
  },
  mounted(){
    this.id = this.$route.params.id
  },
  watch:{
    $route(to, from){
      this.id = to.params?.id
    }
  }
}
</script>

<style scoped>
.w-custom{
  max-width: 230px;
  width: 230px;
}
.h-custom {
  height: 65vh;
}

@media(max-width: 768px){
  .h-custom {
    height: 45vh;
  }
}

.point {
  position: absolute;
  width: 100%;
  top: 25vh;
  display: block;
  text-align: center;
}

.image-logo {
  display: inline;
  width: 250px;
}

.portada {
  position: absolute;
  bottom: -45px;
  left: 100px;
}
</style>
