import {isEmpty, upperFirst} from 'lodash'

import {
  baseTb,
  renderField,
  searchModify,
  countElements,
  exportButtons,
  exportsFunctions,
  multiSelectAndDelete,
  updateAndDeleteButtons
} from '@options/tables/index.js'

const renderLogo = (logo) => {
  let img = isEmpty(logo) ? `${url}/placeholder.png` : `${url}/${logo}`
  return (`
    <div class="d-flex align-items-center">
      <div class="symbol symbol-60 flex-shrink-0">
        <img src="${img}" alt="${img}"
        style="border-radius:50%; border:1px solid #3445e5; width:60px; height:60px;">
      </div>
    </div>
  `)
  }

let url = `https://you-worldwide.com/api/v1/institution/logo`

let name = 'institution', tb = null, status = false
/* tbClass = '${name}_table' */

const initTable = (tbClass, actions, data) => {

  status = true
  tb = baseTb({
    name,
    tbClass: tbClass,
    data: data.institutions,
    options: {
      lengthMenu: [5, 10, 25, 50, 100, 200, 500],
      buttons: [
				'print',
				'copyHtml5',
				'excelHtml5',
				'csvHtml5',
				'pdfHtml5',
		],
      columns: [
        { title: 'Logo', data: 'logo', orderable: false }, /*2*/
        { title: 'Id', data: 'id' },
        { title: 'Nombre', data: 'name' },
        { title: 'Alias', data: 'alias' },
        { title: 'Lenguaje', data: 'language' },
        { title: 'Tipo de institución', data: 'type_institution' },
        { title: 'Tipo de cuenta', data: 'type_account' },
      ],
      columnDefs: [
        {
          targets: 2,
          width:'100px',
          render: (data) => renderLogo(data)
        },
        {
          targets: 7,
          render: (data) => renderField(data, 'name')
        },
        {
          targets: 8,
          render: (data) => renderField(data, 'name')
        },
      ]
    }
  })
  countElements(tb)
  exportButtons(tb, name)
  searchModify(tb, name, 'Buscar institucion')
  multiSelectAndDelete({ tb, tbClass, actions, state: name })
  updateAndDeleteButtons({ tb, tbClass, actions, state: name })
}

const getTb = () => tb
const getStatus = () => status

let getExport = exportsFunctions({
  initTable,
  getStatus,
  getTb,
  name,
})

getExport[`getTb${upperFirst(name)}`] = getTb

export default getExport
