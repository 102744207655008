import { authenticared } from '@middlewares/authenticated.js'
import Dashboard from '@layouts/Dashboard.vue'

export default [
  /* Ruta de modulo estudio */
  {
    path: '/admin/dashboard/modules/study',
    component: Dashboard,
     children: [
       {
         path: '',
         name: 'index-study',
         component: async () => await require("@views/admin/study/Index.vue"),
         beforeEnter: [authenticared],
       },
       {
         path: 'add',
         name: 'add-study',
         component: async () => await require("@views/admin/study/Register.vue"),
         beforeEnter: [authenticared],
       },
       {
         path: 'update/:id',
         name: 'update-study',
         component: async () => await require("@views/admin/study/Update.vue"),
         beforeEnter: [authenticared],
       },
     ]
  }
]
