import {upperFirst} from 'lodash'

import {
  baseTb,
  searchModify,
  countElements,
  exportsFunctions,
  multiSelectAndDelete,
  updateAndDeleteButtons
} from '@options/tables/index.js'

let name = 'studyProgram', tb = null, status = false
/* tbClass = '${name}_table' */

let renderField = (data, field) => {
  return (`
    <div class="d-flex align-items-center">
      ${ data[field] }
    </div>
  `)
}

let initTable = (tbClass, actions, data) => {

  status = true
  tb = baseTb({
      name,
      tbClass: tbClass,
      data: data.studyPrograms,
      options: {
        columns: [
        {
            title: 'institucion',
            data: 'institution'
          },
          {
            title: 'sede',
            data: 'headquarter'
          },
          {
            title: 'Estudio',
            data: 'study'
          },
          {
            title: 'Moneda',
            data: 'currency'
          },
          {
            title: 'Modalidad',
            data: 'modality'
          },
          {
            title: 'Precio de suscripción',
            data: 'registration_price'
          },
          {
            title: 'Precio de envio',
            data: 'shipping_price'
          },
        ],
        columnDefs: [
        {
            targets: 4,
            data: 'study',
            render: (data) => renderField(data, 'title')
          },
          {
            targets: 5,
            data: 'currency',
            render: (data) => renderField(data, 'code')
          },
          {
            targets: 3,
            data: 'headquarter',
            render: (data) => renderField(data, 'name')
          },
          {
            targets: 2,
            data: 'institution',
            render: (data) => renderField(data, 'name')
          },
        ]
      }
    })
  countElements(tb)
  searchModify(tb, name)
  multiSelectAndDelete({tb, tbClass, actions, state: name })
  updateAndDeleteButtons({tb, tbClass, actions, state: name })

}

const getTb = () => tb
const getStatus = () => status

const getExport = exportsFunctions({
  getStatus,
  initTable,
  getTb,
  name
})

getExport[`getTb${upperFirst(name)}`] = getTb

export default getExport
