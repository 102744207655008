<template>
<li class="menu-item menu-item-submenu" :class="{'menu-item-active': subMenu}" data-menu-toggle="hover" aria-haspopup="true">
  <a href="#" class="menu-link menu-toggle">
    <span class="menu-icon">
      <i class="la la-institution"></i>
    </span>
    <span class="menu-text">Sedes</span>
    <i class="menu-arrow"></i>
  </a>
  <div class="menu-submenu menu-submenu-classic menu-submenu-right">
    <ul class="menu-subnav">
      <template v-for="(menu, index) in menu.headquarters" :key="index">
        <router-link custom :to="{name:menu.to}" v-slot="{ navigate, href, isExactActive }">
          <li class="menu-item" :class="{'menu-item-active': subMenu==false ? subMenu=isExactActive: isExactActive }" aria-haspopup="true">
            <a :href="href" @click="navigate" class="menu-link">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">{{menu.title}}</span>
            </a>
          </li>
        </router-link>
      </template>
    </ul>
  </div>
</li>
</template>

<script>
export default {
  data(){
    return {
      subMenu:false,
      menu:{
        headquarters:[
          {
            title: 'Ver sedes',
            to: 'index-headquarter',
          },
          {
            title: 'Registrar nueva sede',
            to: 'register-headquarter',
          },
        ],
      }
    }
  },
  watch: {
     $route() {
       this.subMenu = false
     }
  },
}
</script>
