/* Importando lo esencial para ejecutar la app */
import { createHead } from '@vueuse/head'
import { createApp, h, ref } from 'vue'

import App from '@/App.vue'  //componente
import router from '@router' //rutas
import store from '@store'   //vuex store - no persistente

// Estilos
import "@assets/css/global.css"

/* Instanciar para añadir funcionalidad
y ejecutar la app */
const app = createApp(App)

const head = createHead()

/*Agregar plugins*/
app.use(head)
app.use(store)
app.use(router)

/*Arrancar la app*/
app.mount('#kt_body')
