/* Utiliades y ayudantes */
import { orderBy, findIndex, cloneDeep, remove } from 'lodash'
import { keyBy, cleanObject } from '@tools/util'

/* Servicios de consumo de api */
import Api from '@services/modules/headquarter'

/* Importamos las funciones para actualizar, agregar e iniciar la dataTable de este modulo */
import DTHeadquarter from '@components/admin/headquarter/index/dataTable'
const { initTbHeadquarter, addTbHeadquarter, updateTbHeadquarter, refreshTbHeadquarter } = DTHeadquarter

/* Funcion para iniciar la dataTable */
const initTable = (state, payload) => {
  const {commit, dispatch} = payload // extraemos

  /* Preguntamos si el tipo es 'initTable' para
  posteriomente montar la tabla */
  if (payload.type == "initTable") {

    /* se monta la dataTable */
    initTbHeadquarter(
      {commit, dispatch}, /* funciones para
      llamar a mutations y actions*/
      { headquarters: state.headquarters } /* Datos
      para mostrar en tabla */
    )
  }
}

export default {
  namespaced: true, //hace que sea global el modulo en vuex
  state: () => ({
    lifecicly:{ //Ciclo de vida del componente
      mounted:{ //Cuando se monta
        /* Hacen referencia a las actions */
        showAll:false,
        show:false,
        edit:false,
      },
      watcher:{
        /* Cuando hay que calcular un cambio
        en tiempo real */
        change:false,
      },
      loading:{
        /* Se Usan para muestran los una espera
        de una operacion hasta que termine */
        register:false,
        update:false,
        showAll:true,
      },
    },

    newHeadquarter:{ //Registrar - solo uno
      institution_id: '',
      social_media:[],
      description:'',
      postcode:'',
      address:'',
      country:'',
      state:'',
      phone:'',
      cover: null,
      city:'',
      name:'',
      web:'',
    },

    gallery:[],

    copyHeadquarter:{}, /* Copia para comparar
    o cancelar y volver al estado original */
    headquarter:{}, //solo uno
    selected:[], /* Se usa para almacenar los ids de la
    dataTable cuando seleccionamos multiples 'checkable'
    que se quieres eliminar */

    headquarters: [], //todos
    $headquarters: {}, /* $ -> significa que esto sera
    un objeto, para acceder rapidamente a las propiedades
    sin afectar el rendimiendo con un find, map, o forEach */
  }),
  mutations: {

    /* Llamamos varios datos para almacenar y visualizar dichos datos */
    SHOW_ALL_HEADQUARTERS(state, payload){
      if (!state.lifecicly.mounted.showAll) { //negacion

        state.lifecicly.mounted.showAll = true  /* establecemos
        en 'true' para que se sepa que ya la data esta, y no
        hay que volver a ejecutar un llamado a la Api */

        /*LLamado a la Api */
        Api.AllHeadquarters(
          /* Obtenemos la data y un validador */
          (data, /*validate*/) => {
            state.headquarters = data /* Insertamos la data y
            la ordenamos por el nombre */
            state.$headquarters = keyBy(data, 'id') /* Insertamos
            la data transformandola en objeto y con el id como llave
            por defecto -> $objeto['numero_de_id'].propiedad */

            initTable(state, payload) /* Instanciamos la dataTable
            e insertamos los datos */
          },
          (type) => {
            /* Si hay un error type sera 'error', si no sera 'finally'.
            Si no se espesfica nada por defecto siempre es finally */
            if (type=="error") {
              initTable(state, payload) /* Instanciamos la dataTable
              e insertamos los datos aun si esta vacia la data */
            }

            state.lifecicly.loading.showAll = false /* Desactivamos
            el loading */
          }
        )
      } else initTable(state, payload) /* De lo contrario ya se
      llamo  a la Api y la data ya existe, no hace falta volverla
      a llamar, solo hay que insertarla en la dataTable */
    },
    /*****----------------------------------------------------*****/

    /* Llamamos un dato para almacenar y visualizar dicho dato actual */
    SHOW_HEADQUARTER(state, payload) {

      /* Si no venimo de darle al boton edit en la dataTable
      entonces llamamos Api */
      if (!state.lifecicly.mounted.edit) {  //negacion

        /* Si no se a montado el componente actual, entonces
        llamamos la Api y guardamos la data */
        if (!state.lifecicly.mounted.show) { //negacion

          state.lifecicly.mounted.show = true /* establecemos
          en 'true' para que se sepa que ya la data esta, y no
          hay que volver a ejecutar un llamado a la Api */

          Api.OneHeadquarter(
            { id: payload }, // id para buscar dato en db
            /* Obtenemos la data y un validador */
            (data, /*validate*/) => {
              state.headquarter = data /* Insertamos la data */
              state.copyHeadquarter = cloneDeep(data) /* Insertamos
              la data transformandola en objeto y con el id como llave
              por defecto -> $objeto['numero_de_id'].propiedad */
            }
          )
        }
      }
    },
    /*****----------------------------------------------------*****/

    /* Registramos un dato en la db y lo agregamos a la dataTable */
    REGISTER_HEADQUARTER(state, payload){

      //Obligatorio para enviar imagenes al servidor
      let newHeadForm = new FormData()

      /* Recorremos los campos para insertarlos en un objeto */
      for (const field in state.newHeadquarter) {
        //Insertamos la data recorriendo sus campos
        newHeadForm.append(field, state.newHeadquarter[field])/*
        Agregamos el nombre del campo y el valo del campo */
      }

      state.lifecicly.loading.register = true /* Activamos el loading */

      /*LLamado a la Api */
      Api.CreateHeadquarter(
        newHeadForm, // data del form a insertar en db
        /* Obtenemos la data y un validador */
        (data, validate) => {
          if (validate) { //Verificar que no venga vacia

            state.$headquarters[data.id] = data /* creamos la nueva
            propiedad en el objeto y le asignamos la data */
            state.headquarters.push(data) /* Insertamos el nuevo dato
            en el array que contiene todos los datos existente */

            addTbHeadquarter(data) /* Agregamos a la tabla la data
            y refrescamos */

            payload.commit('UPLOAD_GALLERY', data.id)

            /* Limpiamos los campos */
            state.newHeadquarter.cover = null
            cleanObject(state.newHeadquarter)

            payload.successUpdateForm() /* Mostramos un
            mensaje de success */
          }
          else {
            payload.errorUpdateForm() /* Mostramos un
              mensaje de error */
          }
        },
        (type) => {
          /* Si hay un error type sera 'error', si no sera 'finally'.
          Si no se espesfica nada por defecto siempre es finally */

          if(type=="error") payload.errorUpdateForm() /* Mostramos un
          mensaje de error */
          state.lifecicly.loading.register = false /* Desactivamos
          el loading */
        }
      )
    },
    /*****----------------------------------------------------*****/

    /* Registramos un dato en la db y lo agregamos a la dataTable */
    UPLOAD_GALLERY(state, payload){

      //Obligatorio para enviar imagenes al servidor
      let newGalForm = new FormData()

      /* Recorremos los campos para insertarlos en un objeto */
      state.gallery.map((image, index) => {
        //Insertamos la data recorriendo sus campos
        newGalForm.append(index, image) /*
        Agregamos el nombre del campo y el valo del campo */

      })

      /*LLamado a la Api */
      Api.UploadGallery(
        {id: payload, form: newGalForm }, // data del form a insertar en db
        /* Obtenemos la data y un validador */
        (data) => { },
      )
    },

    /* Actualizamos un dato en la db y en a la dataTable */
    UPDATE_HEADQUARTER(state, payload) {

      /* Preguntamos si no hay cambios echos, esta variable cambia
      cuando se compara la copia con el objeto del form y no son iguales */
      if (state.lifecicly.watcher.change) {
        //Obligatorio para enviar imagenes al servidor
        let updateHeadForm = new FormData()

        /* Recorremos los campos para insertarlos en un objeto */
        for (const field in state.headquarter) {
          //Insertamos la data recorriendo sus campos
          updateHeadForm.append(field, state.headquarter[field]) /*
          Agregamos el nombre del campo y el valo del campo */
        }

        state.lifecicly.loading.update = true /* Activamos el loading */
        let id = state.headquarter.id /* Guardamos el id actual
        en una variable para no tener que llamar objeto tan largo */

        /*LLamado a la Api */
        Api.UpdateHeadquarter(
          { params: { id }, data:updateHeadForm },/* Data para
          actualizar en db y id para identificar el que se actualizara */
          /* Obtenemos la data y un validador */
          (data, validate) => {
            if (validate) { //Verificamos que no venga vacia

              state.lifecicly.watcher.change = false /* Una ves ya esten
              listos los cambios y se envien la db, se vuelve a hacer,
              una copia de la nueva modificación */

              // Obtenemos el index por medio de id del elemento
              let index = findIndex(state.headquarters, {id})

              //Remplazamos la informacion por la que se actualizo
              state.headquarters.splice(index, 1, data)

              state.headquarter = data /* Actualizamos los datos
              del campo del store que lo amerite */
              state.$headquarters[id] = data /* $ -> buscamos y
              remplazamos el objeto */

              /* Copia para comparar cuando se quiera volver a actualizar,
              la finalidad es saber si hay cambios y permitir actualizar
              o restablece los cambios echos si no se quiere actualizar */
              state.copyHeadquarter = cloneDeep(data)

              updateTbHeadquarter(data) /* Actualizamos la dataTable
              insertando la informacion actualizada */

              payload.successUpdateForm() /* Mostramos un
              mensaje de success */

            }
            else payload.errorUpdateForm() /* Mostramos un
            mensaje de error */
          },
          (/*type, validate*/) => {
            /* Si hay un error type sera 'error', si no sera 'finally'.
            Si no se espesfica nada por defecto siempre es finally */

            state.lifecicly.loading.update = false /*
            Desactivamos el loading */
          }
        )
      } else payload.differentUpdateForm() /* Mostramos un
      mensaje de no haber cambios en el form */
    },
    /*****----------------------------------------------------*****/

    /* Eliminamos un dato en la db y en a la dataTable */
    DELETE_HEADQUARTER(state, payload){

      /*LLamado a la Api */
      Api.DeleteHeadquarter(
        {id: payload.id }, /* Pasamos el id para eliminar
        solo ese dato */
        /* Podemos obtener la data y un validador */
        (/*data, validate*/) => {
          delete state.$headquarters[payload.id] /* Eliminamos
          la propiedad del objeto */
          remove(state.headquarters, {id: payload.id }) /* Removemos
          el dato del array de datos */
        }
      )
    },
    /*****----------------------------------------------------*****/

    /* Eliminamos varios datos de la db y de la dataTable */
    DELETE_MANY_HEADQUARTERS(state){
      let end = 0 // iniciamos un contador en 0

      /* Recorremos el array que contiene los ids que se
      seleccionaron para ser eliminados en la dataTable */
      state.selected.forEach(async (id) => {
        end++ // aumentamos el contador
        Api.DeleteHeadquarter(
          { id }, /* Pasamos el id para eliminar solo ese dato */
          /* Podemos obtener la data y un validador */
          (/*data, validate*/) => {
            delete state.$headquarters[id] /* Eliminamos la
            propiedad del objeto */
            remove(state.headquarters, { id }) /* Removemos
            el dato del array de datos */
          }
        )
      })
      state.selected = [] /* Vaciamos una ves ya esten eliminados
      los ids que estaban aqui */
    },

  },
  actions: {
    /* Llamamos a todos los datos */
    ShowAllHeadquarters({ commit, dispatch }, payload) {
      /* Payload -> type == "initTable" o null */
      commit('SHOW_ALL_HEADQUARTERS', { commit, dispatch, ...payload })
    },
    /* Llamamos a un solo dato */
    ShowHeadquarter({ commit }, payload) {
      /* Payload -> 'number': un id del dato que se quiere obtener */
      commit('SHOW_HEADQUARTER', payload)
    },
    /* Registramos a un solo dato */
    RegisterHeadquarter({ commit }, payload) {
      /* payload -> successRegisterForm: ƒ, errorRegisterForm: ƒ */
      commit('REGISTER_HEADQUARTER', { commit, ...payload })
    },
    /* Actualizamos a un solo dato */
    UpdateHeadquarter({ commit }, payload) {
      /* payload -> successUpdateForm: ƒ, errorUpdateForm: ƒ, differentUpdateForm: ƒ */
      commit('UPDATE_HEADQUARTER', {commit, ...payload})
    },
    /* Eliminamos varios datos */
    DeleteHeadquarters({ commit }) {
      /* Para eliminar no se necesita id por que en el array 'selected'
      estan los ids que se seleccionaron para ser eliminados esos datos */
      commit('DELETE_MANY_HEADQUARTERS')
    },
    DeleteHeadquarter({ commit }, payload) {
      commit('DELETE_HEADQUARTER', payload)
    },
  }
}
