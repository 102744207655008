<template>
  <div class="d-flex flex-row flex-column-fluid page">
    <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
      <div class="d-flex flex-column flex-column-fluid" id="kt_content">
        <SubHeader />
        <div class="d-flex flex-column-fluid">
          <div class="container">
            <div class="card card-custom gutter-b">
              <div class="card-body p-0">
                <div class="wizard wizard-1">
                  <FormRegister />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* Funcionalidad de registrar y validad la data */
import InitValitateForm from "@components/admin/studyProgram/register/formRegister.js"

/* Componentes */
import FormRegister from '@components/admin/studyProgram/register/FormRegister.vue'
import SubHeader from '@components/admin/studyProgram/register/SubHeader.vue'

/* funciones del vuex */
import { mapActions } from 'vuex'

export default {
  name: 'RegisterStudyProgram',
  components: {
    SubHeader,
    FormRegister,
  },
  methods:{
    /* Llamamos a N cantidad de acciones del store */
    ...mapActions('studyProgram', ['RegisterStudyProgram']),
  },
  mounted(){
    /* Instanciamos la validacion del registro.
    Pasamos la accion RegisterStudyProgram para internamente
    una ves validado todo, active la funcion y se registre */
    InitValitateForm(this.RegisterStudyProgram)
  }
}
</script>
