let validation = []
let validate = {
  fields: {
    // name: {
    //   validators: {
    //     notEmpty: {
    //       message: 'Ingresa el nombre de la sede'
    //     }
    //   }
    // },
    phone: {
      validators: {
        notEmpty: {
          message: 'Debe proporcionar un numero de telefono'
        }
      }
    },
    address: {
      validators: {
        notEmpty: {
          message: 'Porfavor ingresa una dirreccion'
        }
      }
    },
    country: {
      validators: {
        notEmpty: {
          message: 'Debe haber un pais'
        }
      }
    },
    // state: {
    //   validators: {
    //     notEmpty: {
    //       message: 'Debe haber un estado'
    //     }
    //   }
    // },
    // city: {
    //   validators: {
    //     notEmpty: {
    //       message: 'Debe haber una ciudad'
    //     }
    //   }
    // },
    postcode: {
      validators: {
        notEmpty: {
          message: 'Debe haber una ciudad'
        }
      }
    },
    // description: {
    //   validators: {
    //     notEmpty: {
    //       message: 'Porfavor pon una descripcion o reseña historica'
    //     }
    //   }
    // },
    institution_id: {
      validators: {
        notEmpty: {
          message: 'Este campo es obligatorio'
        }
      }
    },
  },
  plugins: {
    trigger: new FormValidation.plugins.Trigger(),
    // Bootstrap Framework Integration
    bootstrap: new FormValidation.plugins.Bootstrap({
      //eleInvalidClass: '',
      eleValidClass: '',
    })
  }
}
let formEl, _avatar

const differentUpdateForm = () => {
  Swal.fire({ //Notificacion de institución registrada correctamente
    text: "No hay datos modificados",
    icon: "warning",
    buttonsStyling: false,
    customClass: {
      confirmButton: "btn font-weight-bold btn-primary",
      cancelButton: "btn font-weight-bold btn-default"
    }
  })
}
const errorUpdateForm = () => {
  Swal.fire({ //Notificacion de tipo de cuenta no registrada
    text: "No se pudo actualizadar la sede, intente en unos minutos",
    icon: "error",
    buttonsStyling: false,
    customClass: {
      confirmButton: "btn font-weight-bold btn-primary",
      cancelButton: "btn font-weight-bold btn-default"
    }
  })
}
const successUpdateForm = () => {
  Swal.fire({ //Notificacion de tipo de cuante correctamente
    text: "Sede actualizada!",
    icon: "success",
    buttonsStyling: false,
    customClass: {
      confirmButton: "btn font-weight-bold btn-primary",
      cancelButton: "btn font-weight-bold btn-default"
    }
  })
  $('#deleteCover').trigger('click')
}

let KTContactsUpdate = function() {

  let _initValidation = function(UpdateHeadquarter) {

    validation.push(FormValidation.formValidation(formEl, validate))

    $('#update-button-headquarter').on('click', function() {
      validation[0].validate().then(function(status) {
        if (status == 'Valid') {
          UpdateHeadquarter({
            successUpdateForm,
            errorUpdateForm,
						differentUpdateForm
          })
        }
      })
    })
  }

  return {
    init: function(UpdateHeadquarter) {
      _initValidation(UpdateHeadquarter)
    }
  }
}()

export default (UpdateHeadquarter) => {
  $(function() {
    formEl = KTUtil.getById('update-form-headquarter')
    KTContactsUpdate.init(UpdateHeadquarter)
  })
}

export const resetForm = () => validation[0].resetForm(true)
