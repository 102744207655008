<template>
  <Loading :active="loading" :full="true">
    <Header>
      <router-view v-slot="{ Component }">
        <keep-alive>
          <component :is="Component" />
        </keep-alive>
      </router-view>
    </Header>

    <DrawerUser />
    <DrawerPanel />
    <DrawerModelChat />
    <Overlay />
  </Loading>
</template>

<script>
import { useHead } from '@vueuse/head'
import { ref } from 'vue'

/* Componentes */
import DrawerModelChat from '@components/admin/dashboard/DrawerModelChat.vue'
import DrawerPanel from '@components/admin/dashboard/DrawerPanel.vue'
import DrawerUser from '@components/admin/dashboard/DrawerUser.vue'
import Content from '@components/admin/dashboard/Content.vue'
import Overlay from '@components/admin/dashboard/Overlay.vue'
import Header from '@components/admin/dashboard/Header.vue'
import Loading from '@components/Loading.vue'


export default {
  components: {
    DrawerModelChat,
    Loading,
    DrawerPanel,
    DrawerUser,
    Content,
    Overlay,
    Header,
  },
  setup(){
    const loading = ref(true)

    useHead({
      link: [
        { href: 'https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700', rel: 'stylesheet' },
        { href: '/css/admin/plugins.bundle.min.css', rel: 'stylesheet' },
        { href: '/css/admin/prismjs/prismjs.min.bundle.css', rel: 'stylesheet' },
        { href: '/css/admin/style.bundle.min.css', rel: 'stylesheet' },
      ],
      script:[
        { src: '/js/admin/scripts.bundle.min.js' },
        { src: '/js/admin/custom.min.js' },
        { src: '/js/admin/pagination.min.js' },
        { src: '/js/admin/prismjs.bundle.min.js' },
        { src: '/js/admin/datatables.bundle.min.js'}
      ]
    })

    setTimeout(() => { 
      loading.value = false
    }, 1700)
    
    return {
      loading
    }
  }
}
</script>
