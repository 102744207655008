import { authenticared } from '@middlewares/authenticated.js'
import Dashboard from '@layouts/Dashboard.vue'

export default [
  /* Ruta de modulo institucion */
  {
    path: '/admin/dashboard/modules/institutions',
    component: Dashboard,
     /* Rutas a partir del path principal */
     children: [
       {
         path: '', /* Ruta por defecto '/' pero no se coloca el slash */
         name: 'index-institution',
         component: async () => await require("@views/admin/institution/Index.vue"),
         beforeEnter: [authenticared], /* middlewares */
       },
       {
         path: 'register',
         name: 'register-institution',
         component: async () => await require("@views/admin/institution/Register.vue"),
         beforeEnter: [authenticared], /* middlewares */
       },
       {
         path: 'update/:id',
         name: 'update-institution',
         component: async () => await require("@views/admin/institution/Update.vue"),
         beforeEnter: [authenticared], /* middlewares */
       },
     ]
  }
]
