<template>
<footer class="body-font mt-10 absolute bottom-0 w-full">
  <div class="pt-2" style="background-color:#05a4e8;">
    <div class="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
      <p class="text-sm text-center sm:text-left text-white">
        © 2019 You Worldwide | All rights reserved.
      </p>
      <span class="inline-flex sm:ml-auto justify-center sm:justify-start">
        <a href="#">
          <i class="fab fa-facebook-f text-white"></i>
        </a>
        <a href="#" class="ml-3">
          <i class="fab fa-instagram text-white"></i>
        </a>
      </span>
    </div>
  </div>
</footer>
</template>
