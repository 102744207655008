<template>
<HeaderMobile />
<div class="d-flex flex-column flex-root">
  <div class="d-flex flex-row flex-column-fluid page">
    <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
      <div id="kt_header" class="header flex-column header-fixed">
        <SubHeaderUp />
        <SubHeaderDown />
      </div>
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <slot></slot>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import SubHeaderDown from './header/SubHeaderDown.vue'
import HeaderMobile from './header/HeaderMobile.vue'
import SubHeaderUp from './header/SubHeaderUp.vue'
export default {
  name: 'header-dashboard',
  components: {
    SubHeaderDown,
    HeaderMobile,
    SubHeaderUp,
  },
}
</script>
