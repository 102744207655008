<template>
<div class="container divide-y pt-20">
  <div class="pb-3">
    <h1 class="text-2xl"><strong style="color: #05a4e8;">Contact</strong></h1>
    <p>contact us if you have any request.</p>
  </div>

  <div class="flex flex-wrap py-5 pb-10 contact-color">
    <form class="w-full md:w-3/5 md:pr-7" id="contact-form">

      <div class="grid grid-cols-2 gap-3 mb-6">
        <div class="rounded border border-gray-300">
          <input class="w-full appearance-none px-3 border text-grey-darker leading-tight" name="name" placeholder="Name" />
        </div>
        <div class="rounded border border-gray-300">
          <input class="py-3 px-2 text-md" name="lastname" placeholder="Last name" />
        </div>
      </div>

      <div class="grid grid-cols-3 gap-3 mb-6">
        <div class="rounded border border-gray-300">
          <input class="w-full appearance-none px-3 border text-grey-darker" name="email" placeholder="Email" />
        </div>
        <div class="rounded border border-gray-300">
          <input class="w-full appearance-none px-3 border text-grey-darker" name="phone" placeholder="Phone" />
        </div>
        <div class="rounded border border-gray-300">
          <input class="w-full appearance-none px-3 border text-grey-darker" name="residence" placeholder="Residence" />
        </div>
      </div>

      <div class="mb-6 rounded border border-gray-300">
        <textarea class="w-full appearance-none p-3 border text-grey-darker" rows="3" name="comments" placeholder="Comments"></textarea>
      </div>

      <div class="mb-8">
        <button class="w-full block text-white bg-one rounded border-md p-2" style="background-color: #05a4e8;" id="contact-form-button">
          <IconEmail color="white" /> Submit
        </button>
      </div>

    </form>

    <div class="w-full md:w-2/5">
      <p>
        <IconEarth color="#05a4e8" /> You Worldwide.
      </p>
      <p class="md:w-80">
        <IconMapMarker color="#05a4e8" /> 11521 Concord Pike #301, Wilmington, DE 19803, County of New Castle. U.S.A.
      </p>
      <p>
        <IconEmail color="#05a4e8" /> Email: <strong>hello@you-worldwide.com</strong>
      </p>
    </div>
  </div>

</div>
</template>

<script>
import IconEmail from '@components/icons/Email.vue'
import IconMapMarker from '@components/icons/MapMarker.vue'
import IconEarth from '@components/icons/Earth.vue'

//import { resetForm } from '@components/guest/contact/formContact.js'
//import formContac from '@components/guest/contact/formContact.js'
/* import {trim} from 'lodash' */


const test = () => console.log('enviado')

export default {
  components: {
    IconMapMarker,
    IconEmail,
    IconEarth,
  },
  /*watch:{
    $route(){
      resetForm()
    }
  },*/
  mounted() {
    //formContac(test)
  },
};
</script>

<style scoped>
.contact-color .form-control {
  border-color: #bbb7b7;
}

.contact-color input::placeholder {
  color: #9a9a9a;
}

.contact-color textarea::placeholder {
  color: #9a9a9a;
}
</style>
