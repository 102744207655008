<template>
<div class="form-group row">
  <label class="col-xl-3 col-lg-3 col-form-label">Cover image</label>
  <div class="col-lg-9 col-xl-9">
    <input type="file" accept=".jpg, .jpeg, .png" name="cover" @change="showPreview" id="uploadCoverImage" class="hidden">

    <div class="relative">
      <div style="filter: brightness(0.7);">
        <div class="z-10 flex justify-content-center" style="width:30%">Click upload Image</div>
        <div class="uk-cover rounded-md" :style="styleImg" @click="clickImages"></div>
      </div>
      <span class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow absolute"
      style="bottom:0px; left:28%;" v-show="image?.url" @click="deleteImage" id="deleteCover">
        <i class="ki ki-bold-close icon-xs text-muted"></i>
      </span>
    </div>

  </div>
</div>
</template>

<script>
import IconClose from '@components/icons/Close.vue'

const placeholder = 'https://via.placeholder.com/720x575'

export default {
  props:{
    value:{
      type:String,
      default: '',
    }
  },
  data() {
    return {
      image: '',
    };
  },
  components: {
    IconClose,
  },
  computed: {
    styleImg(){
      return {
        backgroundImage: `url(${ this.image?.url || this.value || placeholder })`,
        width: '30%',
        height: '35vh',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        cursor: 'pointer',
      }
    },
  },
  methods: {
    clickImages() {
      $('#uploadCoverImage').trigger('click');
    },
    showPreview(event) {
      if (event.target.files.length > 0) {

        let arr = {}
        let url = URL.createObjectURL(event.target.files[0]);
        this.image = { url }
        this.$emit('input', event)
      }
    },
    deleteImage(){
      this.image = ''
      this.$emit('delete', 'cover')
    }
  },
}
</script>

<style scoped>
.btn-delete-image {
  position: absolute;
  top: -22px;
  right: -22px;
  z-index: 200;
  cursor: pointer;
}
</style>
