<template>
<div id="kt_header_mobile" class="header-mobile header-mobile-fixed">
  <div class="d-flex align-items-center">
    <!--begin::Logo-->
    <a href="index.html" class="mr-7">
      <img alt="Logo" src="@public/media/logos/logo-letter-5.png" class="max-h-30px">
    </a>
    <!--end::Logo-->
    <!--begin::Dropdown-->
    <div class="header-switcher dropdown mr-2">
      <a href="#" class="btn btn-icon border-0" data-toggle="dropdown" data-offset="0px,1px" aria-haspopup="true" aria-expanded="false">
        <span class="svg-icon svg-icon-xl">
          <!--begin::Svg Icon | path:@public/media/svg/icons/Shopping/Box2.svg-->
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <rect x="0" y="0" width="24" height="24"></rect>
              <path
                d="M4,9.67471899 L10.880262,13.6470401 C10.9543486,13.689814 11.0320333,13.7207107 11.1111111,13.740321 L11.1111111,21.4444444 L4.49070127,17.526473 C4.18655139,17.3464765 4,17.0193034 4,16.6658832 L4,9.67471899 Z M20,9.56911707 L20,16.6658832 C20,17.0193034 19.8134486,17.3464765 19.5092987,17.526473 L12.8888889,21.4444444 L12.8888889,13.6728275 C12.9050191,13.6647696 12.9210067,13.6561758 12.9368301,13.6470401 L20,9.56911707 Z"
                fill="#000000"></path>
              <path
                d="M4.21611835,7.74669402 C4.30015839,7.64056877 4.40623188,7.55087574 4.5299008,7.48500698 L11.5299008,3.75665466 C11.8237589,3.60013944 12.1762411,3.60013944 12.4700992,3.75665466 L19.4700992,7.48500698 C19.5654307,7.53578262 19.6503066,7.60071528 19.7226939,7.67641889 L12.0479413,12.1074394 C11.9974761,12.1365754 11.9509488,12.1699127 11.9085461,12.2067543 C11.8661433,12.1699127 11.819616,12.1365754 11.7691509,12.1074394 L4.21611835,7.74669402 Z"
                fill="#000000" opacity="0.3"></path>
            </g>
          </svg>
          <!--end::Svg Icon-->
        </span>
      </a>
      <div class="dropdown-menu p-0 m-0 dropdown-menu-md dropdown-menu-left">
        <!--begin::Navigation-->
        <ul class="navi navi-hover py-5">
          <li class="navi-item">
            <a href="#" class="navi-link">
              <span class="navi-icon">
                <i class="flaticon2-drop"></i>
              </span>
              <span class="navi-text">New Group</span>
            </a>
          </li>
          <li class="navi-item">
            <a href="#" class="navi-link">
              <span class="navi-icon">
                <i class="flaticon2-list-3"></i>
              </span>
              <span class="navi-text">Contacts</span>
            </a>
          </li>
          <li class="navi-item">
            <a href="#" class="navi-link">
              <span class="navi-icon">
                <i class="flaticon2-rocket-1"></i>
              </span>
              <span class="navi-text">Groups</span>
              <span class="navi-link-badge">
                <span class="label label-light-primary label-inline font-weight-bold">new</span>
              </span>
            </a>
          </li>
          <li class="navi-item">
            <a href="#" class="navi-link">
              <span class="navi-icon">
                <i class="flaticon2-bell-2"></i>
              </span>
              <span class="navi-text">Calls</span>
            </a>
          </li>
          <li class="navi-item">
            <a href="#" class="navi-link">
              <span class="navi-icon">
                <i class="flaticon2-gear"></i>
              </span>
              <span class="navi-text">Settings</span>
            </a>
          </li>
          <li class="navi-separator my-3"></li>
          <li class="navi-item">
            <a href="#" class="navi-link">
              <span class="navi-icon">
                <i class="flaticon2-magnifier-tool"></i>
              </span>
              <span class="navi-text">Help</span>
            </a>
          </li>
          <li class="navi-item">
            <a href="#" class="navi-link">
              <span class="navi-icon">
                <i class="flaticon2-bell-2"></i>
              </span>
              <span class="navi-text">Privacy</span>
              <span class="navi-link-badge">
                <span class="label label-light-danger label-rounded font-weight-bold">5</span>
              </span>
            </a>
          </li>
        </ul>
        <!--end::Navigation-->
      </div>
    </div>
    <!--end::Dropdown-->
  </div>
  <!--begin::Toolbar-->
  <div class="d-flex align-items-center">
    <!-- activar con vainilla: id="kt_header_mobile_toggle" -->
    <button class="btn p-0 burger-icon ml-4" @click="DrawerToggle('main')">
      <span></span>
    </button>

    <!-- id="kt_header_mobile_topbar_toggle" -->
    <button class="btn p-0 ml-2" @click="GeneralToggle('topbar')">
      <span class="svg-icon svg-icon-xl">
        <!--begin::Svg Icon | path:@public/media/svg/icons/General/User.svg-->
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <polygon points="0 0 24 0 24 24 0 24"></polygon>
            <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
            <path
              d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
              fill="#000000" fill-rule="nonzero"></path>
          </g>
        </svg>
        <!--end::Svg Icon-->
      </span>
    </button>

  </div>
  <!--end::Toolbar-->
</div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  methods: {
    ...mapActions('global', [
      'DrawerToggle',
      'GeneralToggle',
    ]),
  },
}
</script>

<style lang="css" scoped>
</style>
