<template>
  <Loading :active="loading" :full="true">
    <div class="d-flex flex-column flex-root" style="height: inherit;">
      <div class="login login-3 login-signin-on d-flex flex-row-fluid" id="kt_login">
        <div class="d-flex flex-center bgi-size-cover bgi-no-repeat flex-row-fluid" style="background: #3445e5;">
          <div class="login-form text-center text-white p-7 position-relative overflow-hidden">
            <div class="d-flex flex-center mb-15">
              <a href="#">
                <img :src="logo" class="max-h-100px" alt="" />
              </a>
            </div>
            <router-view v-slot="{ Component }">
              <keep-alive>
                <component :is="Component" />
              </keep-alive>
            </router-view>
          </div>
        </div>
      </div>
    </div>
  </Loading>
</template>

<script>
import Loading from '@components/Loading.vue'

import { useHead } from '@vueuse/head'
import { ref } from 'vue'

/* Endpoint */
let logo = `https://you-worldwide.com/web/gss/Img/logo-trans-400B.png`

export default {
  components: {
    Loading,
  },
  setup(){
    const loading = ref(true)
    const logo = ref(logo)
      
    useHead({
      link: [
        { href: 'https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700', rel: 'stylesheet' },
        { href: '/css/admin/style.bundle.min.css', rel: 'stylesheet' },
        { href: '/css/admin/login-3.min.css', rel: 'stylesheet' },
      ],
    })

    setTimeout(() => {
      loading.value = false
    }, 1500)

    return {
      loading,
      logo
    }
  }
}
</script>
