/* Utiliades y ayudantes */
import { isEqual, at, isEmpty, update, cloneDeep, remove, upperFirst, kebabCase } from 'lodash'
import { addOrRemove, addOrRemoveOnce } from '@tools/util'
import router from '@router'

export default {
  namespaced: true, //hace que sea global el modulo en vuex
  state: () => ({
    drawer:{ //menus para abrir y cerrar modals, etc
      main: false,
      user: false,
      panel: false,
      modalChat: false,
      select:null // guardamos el nombre del elemento que se esta ejecutando
    },
    overlay:false, // modo pantalla de fondo oscuro
    topbar:false,
  }),
  mutations: {
    /* Activamos un menu segun su nombre */
    DRAWER_TOGGLE(state, name) {
      /* Obtenemos la llave o key del objeto y Verificamos
      que sea igual a name */
      if (Object.keys(state.drawer).includes(name)) {

        /* Asignamos el valor opuesto */
        state.drawer[name] = !state.drawer[name]

        /* Si no se encuenta la palabra 'modalChat' entonces
        ejecuta lo siguiente */
        if (!['modalChat'].includes(name)) {

          state.overlay = !state.overlay // Asignamos el valor opuesto
          state.drawer.select = name // Asignamos el nombre
        }
      } else console.error('DRAWER_TOGGLE name no exit:', name); /* Si
      no hay un name emitimos un error */
    },
    /*****----------------------------------------------------*****/

    /* Desactivamos el menu segun su nombre */
    DRAWER_TOGGLE_OVERLAY(state) {
      let name = state.drawer.select // Asignamos lo seleccionado
      state.drawer[name] = !state.drawer[name] // Asignamos el valor opuesto

      state.overlay = !state.overlay // Asignamos el valor opuesto
      state.drawer.select = null // Vaciamos
    },
    /*****----------------------------------------------------*****/

    /* Activar cualquier menu solo por el nombre */
    GENERAL_TOGGLE(state, name) {

      /* Preguntamos si existe en el array el nombre que ingresamos */
      if (Object.keys(state).includes(name)) {
        state[name] = !state[name] // Asignamos el valor opuesto
      } else console.error('GENERAL_TOGGLE name no exit:', name);/* Si
      no hay un name emitimos un error */
    },
    /*****----------------------------------------------------*****/

    /* Agregamos un data a un array de cualquier modulo */
    ADD_ARRAY_STATE(_, payload) {
      const { $state, value, field, type } = payload // Extraemos

      /* Verificamos que el campo 'field' exista en el modulo
      y que no este vacio ni nulo */
      if (!isEmpty(at($state[type], field))) {
        if (!!value) // Verificamos que no este vacio
        $state[type][field].push(value) //Insertamos la data
      }
      else console.error('ADD_FIELD_STATE: field not exits') /*
      De lo contrario si no se encuentra el campo damos un error*/
    },
    /*****----------------------------------------------------*****/

    /* Eliminamos un data de un array de cualquier modulo */
    REMOVE_ARRAY_STATE(_, payload) {
      const { $state, index, field, type } = payload // Extraemos

      /* Verificamos que el campo 'field' exista en el modulo
      y que no este vacio ni nulo */
      if (!isEmpty(at($state[type], field))) {
        remove($state[type][field], {index}) //Removemos la data
      }
      else console.error('ADD_FIELD_STATE: field not exits') /*
      De lo contrario si no se encuentra el campo damos un error*/
    },
    /*****----------------------------------------------------*****/

    /* Actualizamos un campo de cualquier modulo */
    EDIT_FIELD_STATE(_, payload) {
      const { $state, value, field, type } = payload // Extraemos

      /* Verificamos que el campo 'field' exista en el modulo
      y que no este vacio ni nulo */
      if (!isEmpty(at($state[type], field))) {
        if (!!value) // Verificamos que no este vacio
        update($state[type], field, (val) => val = value) /*
        Actualizamos el campo del modulo */
      }
      else console.error('EDIT_FIELD_STATE: field not exits') /*
      De lo contrario si no se encuentra el campo damos un error */
    },
    /*****----------------------------------------------------*****/

    /* Actualizamos un dato de cualquier modulo */
    EDIT_TYPE_STATE(_, payload) {
      const { $state, value, type } = payload // Extraemos

      /* Verificamos que la data 'type' exista en el modulo
      y que no este vacio ni nulo */
      if (!isEmpty(at($state, type))) {
        if (!!value) // Verificamos que no este vacio
        update($state, type, (val) => val = value) /*
        Actualizamos el dato del modulo */
      }
      else console.error('EDIT_TYPE_STATE: type not exits') /*
      De lo contrario si no se encuentra el dato damos un error */
    },
    /*****----------------------------------------------------*****/

    /* Eliminamos un dato de cualquier modulo */
    DEL_FIELD_STATE(_, payload) {
      let { $state, value, field, type } = payload // Extraemos

      /* Verificamos que el campo 'field' exista en el modulo
      y que no este vacio ni nulo */
      if (!isEmpty(at($state[type], field))) {
        update($state[type], field, (val) => val = value || '') /*
        Vaciamos el campo del modulo */
      }
      else console.error('DEL_FIELD_STATE: field not exits') /*
      De lo contrario si no se encuentra el campo damos un error */
    },
    /*****----------------------------------------------------*****/

    /* Redireccionamos a una url, pasando parametros */
    REDIRECT_TO_UPDATE(_, payload) {
      let { $state, id, state } = payload // Extraemos
      let data = {}

      switch(state){
        case 'study':{
          data = $state[`$studies`][id]
          break
        }
        default:{
          data = $state[`$${state}s`][id]
          break
        }
      }

      $state[`${state}`] = data /* Asignamos la data */

      let stateUp = upperFirst(state) /* Ejemplo de: por Ejemplo = Por Ejemplo */
      $state[`copy${stateUp}`] = cloneDeep(data) /* Copia
      para comparar cuando se quiera actualizar, la finalidad es saber si hay
      cambios y permitir actualizar o restablece los cambios */

      if(state == 'studyProgram') {
        $state[`${state}`].times.map(
          (time, index) => time['index'] = index
        )
      }

      $state.lifecicly.mounted.edit = true /* Volvemos 'true' para
      indicar en otra funcion que ya la data se paso por el modo
      edit y no hay que llamar a la Api */

      state = kebabCase(state) /* Ejemplo: por-ejemplo */
      router.push({ //Redireccionamos a la ruta
        name: `update-${state}`,
        params: { id } /* Id de la ruta para el
        componente 'update${name}' */
      })
    },
    /*****----------------------------------------------------*****/

    /* Comparamos datos para determinar si hay cambios */
    WATCHER_CHANGE_IN_STATE(_, payload){
      let { mutated, copy } = payload.type // Extraemos
      let { $state } = payload // Extraemos

      /* Si no son iguales los datos entonces ejecuta lo siguiente */
      if (!isEqual($state[copy], $state[mutated])) {
        if (!$state.lifecicly.watcher.change) /* mientras
        sea false entonces devuelve un true */
        $state.lifecicly.watcher.change = true /* Decimos que
        hay un cambio al colocarlo en 'true' */
      } else {
        if ($state.lifecicly.watcher.change) /* Mientras
        sea verdadero */
        $state.lifecicly.watcher.change = false /* Decimos que
        no hay un cambio */
      }
    },
    /*****----------------------------------------------------*****/

    /* Seleccionamos agregando o removiendo un dato */
    SELECT_STATE(_, payload){
      const { $state } = payload // Extraemos
      addOrRemove($state.selected, payload.select) /*
      Pasamos el dato que contiene los objetos 'selected', luego pasamos
      lo agregado 'select', si ya existe lo elimina */
    },
    /*****----------------------------------------------------*****/

    /* Seleccionamos una sola vez y agregando o removemos un dato */
    SELECT_ONCE_STATE(_, payload){
      const { $state } = payload // Extraemos

      addOrRemoveOnce($state.selected, payload.select, payload.type) /*
      Se efectua solo una vez: Pasamos el dato que contiene los
      objetos 'selected', luego pasamos lo agregado 'select', si
      ya existe lo elimina */
    },
    /*****----------------------------------------------------*****/
  },
  actions: {
    /* Llamamos el activador de un menu */
    DrawerToggle({ commit }, payload) {
      /* Payload -> name */
      commit('DRAWER_TOGGLE', payload)
    },
    /* Llamamos al desactivador de un menu */
    DrawerToggleOverlay({ commit }) {
      commit('DRAWER_TOGGLE_OVERLAY')
    },
    /* Llamamos al activador de menu general */
    GeneralToggle({ commit }, payload) {
      /* Payload -> name */
      commit('GENERAL_TOGGLE', payload)
    },
    /* Insertamos un dato en el array */
    AddArrayState({ commit, rootState }, payload) {
      const $state = rootState[payload.state] /*
      Obtenemos el store global y accedemos al modulo */
      commit('ADD_ARRAY_STATE', {$state, ...payload})
    },
    /* Removemos o agregamos un dato en un array */
    RemoveArrayState({ commit, rootState }, payload) {
      const $state = rootState[payload.state] /*
      Obtenemos el store global y accedemos al modulo */
      commit('REMOVE_ARRAY_STATE', {$state, ...payload})
    },
    /* Actualizamos un dato */
    EditTypeState({ commit, rootState }, payload) {
      const $state = rootState[payload.state] /*
      Obtenemos el store global y accedemos al modulo */
      commit('EDIT_TYPE_STATE', {$state, ...payload})
    },
    /* Actualizamos un campo */
    EditFieldState({ commit, rootState }, payload) {
      const $state = rootState[payload.state] /*
      Obtenemos el store global y accedemos al modulo */
      commit('EDIT_FIELD_STATE', {$state, ...payload})
    },
    /* Vaciamos un campo */
    DelFieldState({ commit, rootState }, payload) {
      const $state = rootState[payload.state] /*
      Obtenemos el store global y accedemos al modulo */
      commit('DEL_FIELD_STATE', {$state, ...payload})
    },
    /* Redirigimos a una ruta */
    RedirectToUpdate({ commit, rootState }, payload) {
      const $state = rootState[payload.state] /*
      Obtenemos el store global y accedemos al modulo */
      commit('REDIRECT_TO_UPDATE', {$state, ...payload})
    },
    /* Verificamos cambios entre objetos */
    WatcherChangeInState({ commit, rootState }, payload) {
      const $state = rootState[payload.state] /*
      Obtenemos el store global y accedemos al modulo */
      commit('WATCHER_CHANGE_IN_STATE', {$state, ...payload})
    },
    /* Agregando o removiendo */
    SelectState({ commit, rootState }, payload) {
      const $state = rootState[payload.state] /*
      Obtenemos el store global y accedemos al modulo */
      commit('SELECT_STATE', {$state, ...payload})
    },
    /* Agregando o removiendo solo una vez */
    SelectOnceState({ commit, rootState }, payload) {
      const $state = rootState[payload.state] /*
      Obtenemos el store global y accedemos al modulo */
      commit('SELECT_ONCE_STATE', {$state, ...payload})
    },
  }
}
