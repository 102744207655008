/* Utiliades y ayudantes */
import { orderBy, lowerCase } from 'lodash'

/* Servicios de consumo de api */
import Api from '@services/modules/headquarter.js'

export default {
  namespaced: true, //hace que sea global el modulo en vuex
  state: () => ({
    headquarterByInstitution: [],
    headquarterByCountry: [], // todos por pais
    headquarterByState: [], // todos por stado
    countryCount: [], //todos por pais para contar instituciones
    filterOfState: [], // array para filtrar
  }),
  getters: {
    filterbyState: (state) => {
      return state.headquarterByState.filter( destination => {
        let name = lowerCase(destination.name)
        if (!state.filterOfState.includes(name)) {
          return destination
        }
      })
    },
    filterByCountry: (state) => {
      const headquarters = state.headquarterByCountry.filter(headquarter => {
        let stateName = lowerCase(headquarter.state)
        if (state.filterOfState.includes(stateName)) {
          return headquarter
        }
      })

      if (headquarters.length >= 1) return headquarters
      else return state.headquarterByCountry
    },
  },
  mutations: {

    /* Llamamos varios datos para almacenar y visualizar dichos datos */
    HEADQUARTER_BY_COUNTRIES(state, payload) {
      Api.AllByCountry(
        payload.params, /* Data para obtener por pais */
        /* Obtenemos la data y un validador */
        (data, /*validate*/) => {

          state.headquarterByCountry = data /* Insertamos la data */

          let states = []

          data.map(({ state }) => {
            if (!states.includes(state)) {
              states.push(state)
            }
          })

          states = states.map((name) => {
            let count = 0

            data.map(({ state }) => {
              if (state == name) count++
            })

            return { name, count }
          })

          state.headquarterByState = orderBy(states, ['count'], ['desc'])

        }
      )
    },
    /*****----------------------------------------------------*****/


    /* Llamamos varios datos para almacenar y visualizar dichos datos */
    HEADQUARTER_BY_INSTITUTION(state, payload) {
      Api.AllByInstitution(
        {id:payload}, /* Data para obtener por pais */
        /* Obtenemos la data y un validador */
        (data, /*validate*/) => {
          state.headquarterByInstitution = data /* Insertamos la data */
        }
      )
    },
    /*****----------------------------------------------------*****/

    /* Llamamos varios datos para almacenar y visualizar dichos datos */
    HEADQUARTER_BY_COUNTRIES_CUSTOM(state) {
      Api.SomeByCountry(
        /* Obtenemos la data y un validador */
        (data, /*validate*/) => {
          /* Insertamos la data y la ordenamos por el nombre */
          state.countryCount = orderBy(data, ['count'], ['desc'])
        }
      )
    },
    /*****----------------------------------------------------*****/


    /*  */
    ADD_STATE_IN_FILTER(state, payload) {
      state.filterOfState.push(lowerCase(payload))
    },
    /*****----------------------------------------------------*****/


    /*  */
    REMOVE_STATE_IN_FILTER(state, payload) {
      state.filterOfState.splice(payload, 1)
    },
    /*****----------------------------------------------------*****/

    /*  */
    REMOVE_ALL_STATE_IN_FILTER(state) {
      state.filterOfState = []
    },
    /*****----------------------------------------------------*****/

  },
  actions: {
    /* Llamamos a todos los datos */
    HeadquarterByCountries({ commit }, payload) {
      commit('HEADQUARTER_BY_COUNTRIES', payload)
    },
    /* Llamamos a todos los datos */
    HeadquarterByInstitution({ commit }, payload) {
      commit('HEADQUARTER_BY_INSTITUTION', payload)
    },
    /* Llamamos a todos los datos */
    HeadquarterByCountriesCustom({ commit }) {
      commit('HEADQUARTER_BY_COUNTRIES_CUSTOM')
    },
    /* Llamamos a todos los datos */
    AddStateInFilter({ commit }, payload) {
      /* payload = name */
      commit('ADD_STATE_IN_FILTER', payload)
    },
    /* Llamamos a todos los datos */
    RemoveStateInFilter({ commit }, payload) {
      /* payload = index */
      commit('REMOVE_STATE_IN_FILTER', payload)
    },

    /* Llamamos a todos los datos */
    RemoveAllStateInFilter({ commit }) {
      commit('REMOVE_ALL_STATE_IN_FILTER')
    },
  }
}
