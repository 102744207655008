<template>
<div class="locations">

  <div class="sm:grid sm:grid-cols-2 md:block gap-4">
      <div class="max-w-lg md:max-w-4xl md:flex shadow-md border border-gray-300 rounded-md relative" style="min-width:180px">

        <div class="h-48 md:h-auto md:w-60 flex-none bg-cover rounded-t-md md:rounded-t-none md:rounded-l-md text-center overflow-hidden" :style="`background-image: url(${imageCover($headquarters[id])})`">
        </div>

        <div class="w-full bg-white px-4 pt-2 pb-3 flex flex-col relative rounded-b-md md:rounded-b-none md:rounded-r-md">

          <div class="flex absolute -top-7 md:top-2 right-3 bg-white border border-gray-300 rounded px-2 py-1">
            <strong class="text-xs pr-2 pt-1">Lodgings</strong>
            <IconHotelReserve size="24" />
          </div>

          <div class="w-full mb-10">
            <div class="text-one font-bold text-xl md:text-2xl p-0 md:pr-36">
              {{$headquarters[id]?.name}}
            </div>

            <p class="mb-3 text-xs">
              <span>{{ capitalize($headquarters[id]?.country) }} - {{ capitalize($headquarters[id]?.state) }} - {{capitalize($headquarters[id]?.city) }}</span>
            </p>

            <p class="text-gray-700 text-sm">
              <ul class="grid grid-cols-1" v-if="!$headquarters[id]?.study_programs.length">
                <li><i class="uil-check mr-2"></i>For Juniors</li>
                <li><i class="uil-check mr-2"></i>Instensive Chinese course</li>
              </ul>
              <ul :class="`grid grid-cols-1`" v-else>
                <li v-for="(study_program, index_st) in $headquarters[id]?.study_programs" :key="index_st">
                  <div v-if="$studies[study_program.study_id].title">
                    <i class="uil-check mr-2"></i>{{$studies[study_program.study_id]?.title}}
                  </div>
                </li>
              </ul>
            </p>
          </div>

          <div class="flex gap-2 absolute bottom-2 right-3">
              <!-- <router-link class="bg-two text-xs text-white font-semibold py-1 px-4 border border-two rounded" @click="scrollTop()" :to="{name: 'profile-institution', params: { name:headquarter.name, id:headquarter.id} }">
                 View Profile
              </router-link> -->
              <a href="#" class="bg-transparent text-xs text-two font-semibold py-1 px-3 border border-two rounded"> Prices </a>
          </div>
        </div>
      </div>
  </div>

  <div class="w-full grid mt-10">
    <div class="my-4">
      <h3 class="text-gray-500 text-xl">Other headquarter</h3>

      <div class="w-full pt-4" style="height: auto;">

        <span class="keyword text-one d-flex" v-for="(country, index) in hiddenFilterOfCountry" :key="index" @click="addRemove(country)">
          <IconClose size="20px" color="red" style="margin-top:8px; margin-left:7px; margin-right:-8px;" />
          <span class="keyword-text pl-4"> {{country}}</span>
        </span>
        <span class="keyword text-one" v-for="(country, index) in contentOfCountry" :key="index" @click="addRemove(country)">
          <span class="keyword-text pl-4">{{country}}</span>
        </span>
      </div>
    </div>

    <div class="w-full">
      <div class="sm:grid sm:grid-cols-2 md:block gap-4">
        <template v-for="(headquarter, ind) in filterByInstitution" :key="ind">
          <div class="max-w-lg md:max-w-4xl md:flex shadow-md border border-gray-300 rounded-md relative mb-5" style="min-width:180px" v-show="headquarter.name != $headquarters[id]?.name">

            <div class="h-48 md:h-auto md:w-60 flex-none bg-cover rounded-t-md md:rounded-t-none md:rounded-l-md text-center overflow-hidden" :style="`background-image: url(${imageCover(headquarter)})`">
            </div>

            <div class="w-full bg-white px-4 pt-2 pb-3 flex flex-col relative rounded-b-md md:rounded-b-none md:rounded-r-md">

              <div class="flex absolute -top-7 md:top-2 right-3 bg-white border border-gray-300 rounded px-2 py-1">
                <strong class="text-xs pr-2 pt-1">Lodgings</strong>
                <IconHotelReserve size="24" />
              </div>

              <div class="w-full mb-10">
                <div class="text-one font-bold text-xl md:text-2xl p-0 md:pr-36">
                  {{headquarter.name}}
                </div>

                <p class="mb-3 text-xs">
                  <span>{{ capitalize(headquarter.country) }} - {{ capitalize(headquarter.state) }} - {{capitalize(headquarter.city) }}</span>
                </p>

                <p class="text-gray-700 text-sm">
                  <ul class="grid grid-cols-1" v-if="!headquarter.study_programs.length">
                    <li><i class="uil-check mr-2"></i>For Juniors</li>
                    <li><i class="uil-check mr-2"></i>Instensive Chinese course</li>
                  </ul>
                  <ul :class="`grid grid-cols-1`" v-else>
                    <li v-for="(study_program, index_st) in headquarter.study_programs.slice(0, 4)" :key="index_st">
                      <div v-if="$studies[study_program.study_id].title">
                        <i class="uil-check mr-2"></i>{{$studies[study_program.study_id]?.title}}
                      </div>
                    </li>
                  </ul>
                </p>
              </div>

              <div class="flex gap-2 absolute bottom-2 right-3">
                  <router-link class="bg-two text-xs text-white font-semibold py-1 px-4 border border-two rounded" @click="scrollTop()" :to="{name: 'profile-institution', params: { name:headquarter.name, id:headquarter.id} }">
                     View Profile
                  </router-link>
              </div>
            </div>
          </div>
        </template>
      </div>

    </div>

  </div>
</div>
</template>

<script>
import IconHotelReserve from '@components/icons/HotelReserve.vue'
import IconClose from '@components/icons/Close.vue'
import { watchEffect } from 'vue'
import { mapState, mapActions } from 'vuex'
import { lowerCase, capitalize, uniq } from 'lodash'
import { addOrRemove } from '@tools/util.js'

const url_base = 'https://you-worldwide.com/api/v1'

export default {
  data() {
    return {
      id: '',
      contentOfCountrySet: new Set(),
      contentOfCountry: [],
      filterOfCountry: [],
      hiddenFilterOfCountry: [],
    }
  },
  components: {
    IconHotelReserve,
    IconClose,
  },
  computed: {
    ...mapState({
      headquarterByInstitution: ({ destination }) => destination.headquarterByInstitution,
      $headquarters: ({ headquarter }) => headquarter.$headquarters,
      $studies: ({ study }) => study.$studies,
    }),
    filterByInstitution() {
      const headquarters = this.headquarterByInstitution.filter(headquarter => {
        let stateName = lowerCase(headquarter.country)
        if (this.filterOfCountry.includes(stateName)) {
          return headquarter
        }
      })

      if (headquarters.length >= 1) return headquarters
      else return this.headquarterByInstitution
    },
  },
  methods: {
    ...mapActions('destination', ['HeadquarterByInstitution']),
    capitalize(value) {
      return capitalize(value)
    },
    scrollTop() {
      $("#btn-top").trigger("click")
    },
    imageCover(headquarter) {
      let url = ''

      if (headquarter) {
        let logoName = headquarter?.cover || 'placeholder.png'
        url = `${url_base}/headquarter/cover/${logoName}`
      }

      return url
    },
    mapOfCountry() {
      this.headquarterByInstitution.map(headquarter => {
        let stateName = lowerCase(headquarter.country)
        if (headquarter.country != this.$headquarters[this.id]?.country)
          this.contentOfCountrySet.add(stateName)
      })
    },
    addRemove(value) {
      addOrRemove(this.hiddenFilterOfCountry, value)
      addOrRemove(this.filterOfCountry, value)
      addOrRemove(this.contentOfCountry, value)
    }
  },
  mounted() {
    this.id = this.$route.params.id
    watchEffect(()=> {
      if(this.$headquarters[this.id]){
        this.HeadquarterByInstitution(this.$headquarters[this.id].institution_id)
        this.mapOfCountry()
        setTimeout(()=> {
          this.contentOfCountry = [...this.contentOfCountrySet]
        }, 800)
      }
    })
  },
  watch: {
    $route(to, from) {
      this.id = to.params?.id

      this.contentOfCountrySet.clear()
      this.hiddenFilterOfCountry = []
      this.mapOfCountry()
      this.filterOfCountry = []
      setTimeout(()=> {
        this.contentOfCountry = [...this.contentOfCountrySet]
      }, 800)
    }
  }
}
</script>
