/* Funcion para crear text editors con opciones en ves
un text-area */
let activeTextEditor = (nameClass) => {
  new Quill(
    `#${nameClass}`, /* Instancia de la clase para
    montar el componente */
    { // Opciones
      modules: {
        toolbar: [
          [{ header: [1, 2, false] }],
          ['bold', 'italic', 'underline'],
          ['image', 'code-block']
        ]
      },
      placeholder: 'Coloca un texto aqui...',
      theme: 'snow' // or 'bubble'
    }
  )
}

/* Funcion de exportado para usar
por fuera */
export const textEditor = (nameClass) => {
  $(() => activeTextEditor(nameClass));
}
