/* funciones del vuex */
import { createStore } from 'vuex'

/* Importamos todos los stores */
import typeInstitution from '@modules/typeInstitutionStore.js'
import studyProgram from '@modules/studyProgramStore.js'
import institution from '@modules/institutionStore.js'
import headquarter from '@modules/headquarterStore.js'
import destination from '@modules/destinationStore.js'
import typeAccount from '@modules/typeAccountStore.js'
import globalStore from '@modules/globalStore.js'
import currency from '@modules/currencyStore.js'
import study from '@modules/studyStore.js'
import auth from '@modules/authStore.js'
import time from '@modules/timeStore.js'

/* Instanciamos los stores para que puedan
ser utilizados en toda la app */
export default createStore({
  modules: {
    global: globalStore,
    typeInstitution,
    studyProgram,
    institution,
    headquarter,
    destination,
    typeAccount,
    currency,
    study,
    auth,
    time,
  }
})
