<template>
  <Loading :active="loading" size="xl" :full="true"/>

  <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
    <div class="col-xl-12 col-xxl-7">
      <form class="form" id="create_form_institution">

        <Inputs/>

        <div class="d-flex justify-content-between border-top pt-10">
          <div>
            <button type="button" class="btn btn-success font-weight-bolder text-uppercase px-9 py-4" id="create-form-institution">
              Aceptar
            </button>
          </div>
        </div>

      </form>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import Inputs from './formRegister/Inputs.vue'
import Loading from '@components/Loading.vue'

import { resetForm } from '@components/admin/institution/register/formRegister.js'

export default {
  name:'WisardForm',
  components:{
    Inputs,
    Loading,
  },
  computed:{
    ...mapState({
      loading: ({institution}) => institution.lifecicly.loading.register
    })
  },
  watch:{
    $route(to, from){
      if (to.fullPath == "/dashboard/modules/institutions"){
        resetForm()
      }
    }
  }
}
</script>
