/* Importamos de dataTableInstitution para refrescar la tabla cuando haya
un cambio en los datos que estan siendo usados de este modulo */
import dataTableInstitution from '@components/admin/institution/index/dataTable'
const { refreshTbInstitution } = dataTableInstitution

/* Utiliades y ayudantes */
import { orderBy, findIndex, cloneDeep, remove } from 'lodash'
import { keyBy, cleanObject } from '@tools/util'

/* Servicios de consumo de api */
import Api from '@services/modules/typeInstitution'

/* Importamos las funciones para actualizar, agregar e iniciar la dataTable de ese modulo */
import DTTypeInstitution from '@components/admin/typeInstitution/index/dataTable'
const { initTbTypeInstitution, addTbTypeInstitution, updateTbTypeInstitution } = DTTypeInstitution

/* Funcion para iniciar la dataTable */
const initTable = (state, payload) => {
  const {commit, dispatch} = payload // extraemos

  /* Preguntamos si el tipo es 'initTable' para
  posteriomente montar la tabla */
  if (payload.type == "initTable") {

    /* se monta la dataTable */
    initTbTypeInstitution(
      {commit, dispatch}, /* funciones para
      llamar a mutations y actions*/
      { typeInstitutions: state.typeInstitutions } /* Datos
      para mostrar en tabla*/
    )
  }
}

export default {
  namespaced: true, //hace que sea global el modulo en vuex
  state: () => ({
    lifecicly: { //Ciclo de vida del componente
      mounted:{ //Cuando se monta
        /* Hacen referencia a las actions */
        showAll:false,
        show:false,
        edit:false,
      },
      watcher:{
        /* Cuando hay que calcular un cambio
        en tiempo real */
        change:false,
      },
      loading:{
        /* Se Usan para muestrarlos como una espera
        de una operacion hasta que termine */
        register:false,
        update:false,
        showAll:true,
      },
    },

    newTypeInstitution:{ //Registrar - solo uno
      name:'',
    },
    copyTypeInstitution:{}, /* Copia para comparar
    o cancelar y volver al estado original */

    typeInstitution:{}, //solo uno
    selected:[], /* Se usa para almacenar los ids de la
    dataTable cuando seleccionamos multiples 'checkable'
    que se quieres eliminar */

    typeInstitutions: [], //todos
    $typeInstitutions: {}, /* $ -> significa que esto sera
    un objeto, para acceder rapidamente a las propiedades
    sin afectar el rendimiendo con un find, map, o forEach */
  }),
  mutations: {

    /* Llamamos a todos los datos para insertar e inicia la tabla */
    SHOW_ALL_TYPE_INSTITUTIONS(state, payload){

      /* Si no se a montado el componente lo montamos llamando a la
      Api e insertando la data en la dataTable */
      if (!state.lifecicly.mounted.showAll) { //negacion

        state.lifecicly.mounted.showAll = true /* establecemos
        en 'true' para que se sepa que ya la data esta, y no
        hay que volver a ejecutar un llamado a la Api */

        /*LLamado a la Api */
        Api.AllTypeInstitutions(
          /* Obtenemos la data y un validador */
          (data, /*validate*/) => {
            state.typeInstitutions = orderBy(data, ['name'],['asc']) /*
            Insertamos la data y la ordenamos por el nombre */
            state.$typeInstitutions = keyBy(data, 'id') /* Insertamos
            la data transformandola en objeto y con el id como llave
            por defecto -> $objeto['numero_de_id'].propiedad */
            initTable(state, payload) /* Instanciamos la dataTable
            e insertamos los datos*/
          },
          (type) => {
            /* Si hay un error type sera 'error', si no sera 'finally'.
            Si no se espesifica nada por defecto siempre es finally */
            if (type=="error") {
              initTable(state, payload) /* Instanciamos la dataTable
              e insertamos los datos aun si esta vacia la data */
            }

            state.lifecicly.loading.showAll = false /* Desactivamos
            el loading */
          }
        )
      } else initTable(state, payload) /* De lo contrario ya se
      llamo  a la Api y la data ya existe, no hace falta volverla
      a llamar, solo hay que insertarla en la dataTable */
    },
    /*****----------------------------------------------------*****/

    /* Llamamos un dato para almacenar y visualizar dicho dato actual */
    SHOW_TYPE_INSTITUTION(state, payload) {

      /* Si no venimo de darle al boton edit en la dataTable
      entonces llamamos Api */
      if (!state.lifecicly.mounted.edit) { //negacion

        /* Si no se a montado el componente actual, entonces
        llamamos la Api y guardamos la data */
        if (!state.lifecicly.mounted.show) { //negacion

          state.lifecicly.mounted.show = true /* establecemos
          en 'true' para que se sepa que ya la data esta, y no
          hay que volver a ejecutar un llamado a la Api */

          /*LLamado a la Api */
          Api.OneTypeInstitution(
            { id: payload }, // id para buscar dato en db
            /* Obtenemos la data y un validador */
            (data, /*validate*/) => {
              state.copyTypeInstitution = cloneDeep(data) /* hacemos
              una copia para que cuando se modifique la data no afecte
              la relacion con la original y para poder restablece la data
              si se modifica, con esta copia */
              state.typeInstitution = data /* Insertamos la data */
            }
          )
        }
      }
    },
    /*****----------------------------------------------------*****/

    /* Registramos un dato en la db y lo agregamos a la dataTable */
    REGISTER_TYPE_INSTITUTION(state, payload){
      state.lifecicly.loading.register = true /* Activamos el loading */

      /*LLamado a la Api */
      Api.CreateTypeInstitution(
        state.newTypeInstitution, // data del form a insertar en db
        /* Obtenemos la data y un validador */
        (data, validate) => {
          if (validate) { //Verificamos que no venga vacia

            state.$typeInstitutions[data.id] = data /* creamos la nueva
            propiedad en el objeto y le asignamos la data */
            state.typeInstitutions.push(data) /* Insertamos el nuevo dato
            en el array que contiene todos los datos existente */

            addTbTypeInstitution(data) /* Agregamos a la tabla la data
            y refrescamos */

            /* Limpiamos los campos */
            cleanObject(state.newTypeInstitution)

            payload.successRegisterForm() /* Mostramos un
            mensaje de success */
          }
          else payload.errorRegisterForm() /* Mostramos un
          mensaje de error */
        },
        (type) => {
          /* Si hay un error type sera 'error', si no sera 'finally'.
          Si no se espesfica nada por defecto siempre es finally */

          if(type=="error") payload.errorRegisterForm() /* Mostramos un
          mensaje de error */
          state.lifecicly.loading.register = false /* Desactivamos
          el loading */
        }
      )
    },
    /*****----------------------------------------------------*****/

    /* Actualizamos un dato en la db y en a la dataTable */
    UPDATE_TYPE_INSTITUTION(state, payload) {

      /* Preguntamos si no hay cambios echos, esta variable cambia
      cuando se compara la copia con el objeto del form y no son iguales */
      if (state.lifecicly.watcher.change) {

        state.lifecicly.loading.update = true /* Activamos el loading */
        let id = state.typeInstitution.id /* Guardamos el id actual
        en una variable para no tener que llamar objeto tan largo */

        /*LLamado a la Api */
        Api.UpdateTypeInstitution(
          { params: { id }, data:state.typeInstitution}, /* Data para
          actualizar en db y id para identificar el que se actualizara */
          /* Obtenemos la data y un validador */
          (data, validate) => {
            if (validate) { //Verificamos que no venga vacia

              state.lifecicly.watcher.change = false /* Una ves ya esten
              listos los cambios y se envien la db, se vuelve a hacer,
              una copia de la nueva modificación*/

              // Obtenemos el index por medio de id del elemento
              let index = findIndex(state.typeInstitutions, {id})

              //Remplazamos la informacion por la que se actualizo
              state.typeInstitutions.splice(index, 1, data)

              state.typeInstitution = data /* Actualizamos los datos
              del campo del store que lo amerite */
              state.$typeInstitutions[id] = data /* $ -> buscamos y
              remplazamos el objeto */

              /* Copia para comparar cuando se quiera volver a actualizar,
              la finalidad es saber si hay cambios y permitir actualizar
              o restablece los cambios echos si no se quiere actualizar */
              state.copyInstitution = cloneDeep(data)

              updateTbTypeInstitution(data) /* Actualizamos la dataTable
              insertando la informacion actualizada */

              refreshTbInstitution() /* Refrescamos la tabla que usa este
              modulo typeInstitution. Esto es por si hay una column con
              un datos modificado lo detecte */

              payload.successUpdateForm() /* Mostramos un
              mensaje de success */
            }
            else payload.errorUpdateForm() /* Mostramos un
            mensaje de error */
          },
          (/*type, validate*/) => {
            /* Si hay un error type sera 'error', si no sera 'finally'.
            Si no se espesfica nada por defecto siempre es finally */

            state.lifecicly.loading.update = false /*
            Desactivamos el loading */
          }
        )
      } else payload.differentUpdateForm() /* Mostramos un
      mensaje de no haber cambios en el form */
    },
    /*****----------------------------------------------------*****/

    /* Eliminamos un dato en la db y en a la dataTable */
    DELETE_TYPE_INSTITUTION(state, payload){

      /*LLamado a la Api */
      Api.DeleteTypeInstitution(
        {id: payload.id }, /* Pasamos el id para eliminar
        solo ese dato */
        /* Podemos obtener la data y un validador */
        (/*data, validate*/) => {
          delete state.$typeInstitutions[payload.id] /* Eliminamos
          la propiedad del objeto */
          remove(state.typeInstitutions, {id: payload.id }) /* Removemos
          el dato del array de datos */
          refreshTbInstitution() /* Refrescamos la dataTable de un
          modulo que usa datos en una o varias columnas de este
          modulo actual */
        }
      )
    },
    /*****----------------------------------------------------*****/

    /* Eliminamos varios datos de la db y de la dataTable */
    DELETE_MANY_TYPE_INSTITUTIONS(state){
      let end = 0 // iniciamos un contador en 0

      /* Recorremos el array que contiene los ids que se
      seleccionaron para ser eliminados en la dataTable */
      state.selected.forEach(async (id) => {
        end++ // aumentamos el contador

        /*LLamado a la Api */
        Api.DeleteTypeInstitution(
          { id }, /* Pasamos el id para eliminar solo ese dato */
          /* Podemos obtener la data y un validador */
          (/*data, validate*/) => {
            delete state.$typeInstitutions[id] /* Eliminamos la
            propiedad del objeto */
            remove(state.typeInstitutions, { id }) /* Removemos
            el dato del array de datos */
          }
        )
      })

      /* Verificamos que el contador sea mayor o igual a la cantida
      de ids que hay en 'selected' para asi ejecutar algo cuando el
      ciclo del for de arriba se termine */
      if(end >= state.selected.length){
        refreshTbInstitution() /* Refrescamos la dataTable de un
        modulo que usa datos en una o varias columnas de este
        modulo actual */
      }
      state.selected = [] /* Vaciamos una ves ya esten eliminados
      los ids que estaban aqui */
    },
  },
  actions: {
    /* Llamamos a todos los datos */
    ShowAllTypeInstitutions({ commit, dispatch }, payload) {
      /* Payload -> type == "initTable" o null */
      commit('SHOW_ALL_TYPE_INSTITUTIONS', { commit, dispatch, ...payload })
    },
    /* Llamamos a un solo dato */
    ShowTypeInstitution({ commit }, payload) {
      /* Payload -> 'number': un id del dato que se quiere obtener */
      commit('SHOW_TYPE_INSTITUTION', payload)
    },
    /* Registramos a un solo dato */
    RegisterTypeInstitution({ commit }, payload) {
      /* payload -> successRegisterForm: ƒ, errorRegisterForm: ƒ */
      commit('REGISTER_TYPE_INSTITUTION', { commit, ...payload })
    },
    /* Actualizamos a un solo dato */
    UpdateTypeInstitution({ commit }, payload) {
      /* payload -> successUpdateForm: ƒ, errorUpdateForm: ƒ, differentUpdateForm: ƒ */
      commit('UPDATE_TYPE_INSTITUTION', {commit, ...payload})
    },
    /* Eliminamos varios datos */
    DeleteTypeInstitutions({ commit }) {
      /* Para eliminar no se necesita id por que en el array 'selected'
      estan los ids que se seleccionaron para ser eliminados esos datos */
      commit('DELETE_MANY_TYPE_INSTITUTIONS')
    },
    /* Eliminamos un dato */
    DeleteTypeInstitution({ commit, rootState }, payload) {
      const $state = rootState
      commit('DELETE_TYPE_INSTITUTION', {$state, ...payload})
    },
  }
}
