<template>
<svg x="0px" y="0px" :width="size" :height="size" viewBox="0 0 172 172" :style="`fill:${color};`">
  <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none"
    text-anchor="none" style="mix-blend-mode: normal">
    <path d="M0,172v-172h172v172z" fill="none"></path>
    <g :fill="color">
      <path
        d="M86,21.5c-35.55872,0 -64.5,28.9413 -64.5,64.5c0,35.55869 28.94128,64.5 64.5,64.5c35.55872,0 64.5,-28.94131 64.5,-64.5c0,-35.5587 -28.94128,-64.5 -64.5,-64.5zM86,32.25c29.749,0 53.75,24.00103 53.75,53.75c0,29.74897 -24.001,53.75 -53.75,53.75c-29.749,0 -53.75,-24.00103 -53.75,-53.75c0,-29.74897 24.001,-53.75 53.75,-53.75zM68.30029,60.69971l-7.60058,7.60058l17.69971,17.69971l-17.69971,17.69971l7.60058,7.60058l17.69971,-17.69971l17.69971,17.69971l7.60058,-7.60058l-17.69971,-17.69971l17.69971,-17.69971l-7.60058,-7.60058l-17.69971,17.69971z">
      </path>
    </g>
  </g>
</svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#000000',
    },
    size: {
      type: String,
      default: '10',
    }
  }
}
</script>
