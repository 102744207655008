<template>
<svg x="0px" y="0px" :width="size" :height="size" viewBox="0 0 172 172" :style="`fill:${color};`" class="d-inline">
  <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none"
    text-anchor="none" style="mix-blend-mode: normal">
    <path d="M0,172v-172h172v172z" fill="none"></path>
    <g :fill="color">
      <path
        d="M51.43522,155.42282c-0.54031,0.73235 -1.39627,1.16481 -2.3067,1.16481h-37.05546c-9.89858,0 -15.57611,-11.28805 -9.6811,-19.23321l35.30985,-47.58858c1.66965,-2.25367 1.66965,-5.27767 0,-7.53134l-35.30985,-47.58858c-5.89823,-7.94946 -0.21283,-19.23321 9.6811,-19.23321h36.37434c0.90326,0 1.75385,0.42565 2.29487,1.14905l49.13641,65.66235c1.6718,2.25295 1.6718,5.27982 -0.00466,7.53922zM169.61126,78.81639l-43.43237,-58.53445c-2.26442,-3.04908 -5.88318,-4.86921 -9.6811,-4.86921h-53.73009c-2.36044,0 -3.70942,2.69365 -2.29487,4.58365l44.00313,58.80246c3.19455,4.30526 3.19455,10.07809 0.00717,14.37403l-43.41338,58.84689c-1.39591,1.89251 -0.04515,4.56789 2.3067,4.56789h53.12135c3.80544,0 7.42134,-1.82264 9.6811,-4.86921l43.43237,-58.53445c3.18523,-4.29594 3.18523,-10.07164 0,-14.36758z">
      </path>
    </g>
  </g>
</svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#000000',
    },
    size: {
      type: String,
      default: '24',
    }
  }
}
</script>
