<template>
<div class="pb-5">
  <div class="row">
    <div class="col-xl-12">
      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label">Logo institucional</label>
        <div class="col-lg-9 col-xl-9">
          <div class="circle-image-primary" :style="logoPlaceholder"></div>
          <div class="image-input image-input-outline" id="kt_contact_edit_avatar">
            <div class="image-input-wrapper circle-image"></div>
            <label class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="change" data-toggle="tooltip" data-original-title="Change avatar">
              <i class="fa fa-pen icon-sm text-muted"></i>
              <input type="file" name="logo" accept=".png, .jpg, .jpeg" @input="UpdateImageIntitution" id="fileLogoUpdate"/>
              <input type="hidden" name="profile_avatar_remove"/>
            </label>
            <span class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="cancel" data-toggle="tooltip" title="Cancel avatar" id="btnDeleteLogo" @click="deleteImageInstitution('logo')">
              <i class="ki ki-bold-close icon-xs text-muted"></i>
            </span>
          </div>
        </div>
      </div>
      <UploadHerouImage :value="hero_image"
        @input="UpdateImageIntitution"
        @delete="deleteImageInstitution"/>

      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label">Nombre del Instituto</label>
        <div class="col-lg-9 col-xl-9">
          <input class="form-control form-control-lg form-control-solid" name="name" placeholder="Instituto" :value="institution?.name" @input="UpdateFieldIntitution"/>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label">Alias Del Instituto</label>
        <div class="col-lg-9 col-xl-9">
          <input class="form-control form-control-lg form-control-solid" name="alias" placeholder="@Alias" :value="institution?.alias" @input="UpdateFieldIntitution"/>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label">Tipo de instituto</label>
        <div class="col-lg-9 col-xl-9">
          <SelectTypeInstitution name="type_institution_id" :value="institution?.type_institution_id" @input="UpdateFieldIntitution"/>
          <div class="fv-plugins-message-container"></div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label">Seleccionar idioma principal</label>
        <div class="col-lg-9 col-xl-9">
          <select name="language"
            class="form-control form-control-lg form-control-solid"
            :value="institution?.language" @input="UpdateFieldIntitution">
            <option hidden selected value="">Seleciona el Idioma...</option>
            <option value="in">INGLÉS</option>
            <option value="es">ESPAÑOL</option>
            <option value="it">ITALIANO</option>
            <option value="fra">FRANCÉS</option>
            <option value="man">MANDARÍN</option>
            <option value="ale">ALEMÁN</option>
          </select>
          <div class="fv-plugins-message-container"></div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label">Tipo de Cuenta</label>
        <div class="col-lg-9 col-xl-9">
          <SelectTypeAccount :name="'type_account_id'" :value="institution?.type_account_id" @input="UpdateFieldIntitution"/>
          <div class="fv-plugins-message-container"></div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import SelectTypeInstitution from '@components/admin/typeInstitution/Select.vue'
import UploadHerouImage from '@components/admin/institution/UploadHerouImage.vue'
import SelectTypeAccount from '@components/admin/typeAccount/Select.vue'

import { endpoint } from '@services/api.js'
import {mapState, mapActions} from 'vuex'
import { trim } from 'lodash'

let url_logo = `${endpoint}/api/v1/institution/logo`
let url_hero = `${endpoint}/api/v1/institution/hero`

export default {
  components:{
    SelectTypeInstitution,
    SelectTypeAccount,
    UploadHerouImage,
  },
  computed: {
    ...mapState({
      institution: ({institution}) => institution.institution,
      copyInstitution: ({institution}) => institution.copyInstitution
    }),
    hero_image(){
      let image = ''
      if (this.copyInstitution?.hero_image) {
        image = this.copyInstitution?.hero_image
      }
      return `${url_hero}/${image}`
    },
    logoPlaceholder() {
      let logo = this.copyInstitution?.logo || 'placeholder.png'
      let url = `${url_logo}/${logo}`
      return `background-image: url(${url})`
    },
  },
  methods: {
    ...mapActions('global', ['EditFieldState', 'DelFieldState', 'WatcherChangeInState']),
    detectChangeInState(){
      this.WatcherChangeInState({
        state: 'institution',
        type:{
          copy:'copyInstitution',
          mutated:'institution',
        }
      })
    },

    UpdateImageIntitution(e) {
      this.EditFieldState({
        state: 'institution',
        type: 'institution',
        field: e.target.name,
        value: e.target.files[0],
      })
      this.detectChangeInState()
    },

    deleteImageInstitution(name) {
      this.EditFieldState({
        state: 'institution',
        type: 'institution',
        field: name,
        value: this.copyInstitution?.logo,
      })
    },

    UpdateFieldIntitution(e) {
      this.EditFieldState({
        state: 'institution',
        type: 'institution',
        field: e.target.name,
        value: trim(e.target.value),
      })
      this.detectChangeInState()
    }
  },
  /*deactivated() {
    console.log('institucion', this.institution)
  }*/
};
</script>

<style>
  .circle-image{
    border-radius:50% !important;
    background-position: center !important;
  }
  .circle-image-primary{
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    width:120px;
    height:120px;
    border-radius:50% !important;
    background-position: center !important;
  }
</style>
