<template>
<div class="form-group">
  <label>Tiempo</label>
  <div class="time-container" style="background: #f3f6f9;">

    <div class="mt-2 mb-4">
      <template v-if="modalityTimes?.length <= 0">
        No hay tiempo con la modalidad: {{ studyProgram.modality }}
        <hr>
      </template>
      <a type="button" class="btn btn-primary w-100" @click="addTime">
        <i class="fa fa-plus"></i> Agregar tiempo
      </a>
      <div class="fv-help-block w-100 d-none" v-show="modalityTimes?.length <= 0" :data-modality="modalityTimes?.length" id="modalityTimesAdd">
        Necesitas agregar un tiempo
      </div>
    </div>

    <template v-for="(time, index) in modalityTimes" :key="time.index">

      <div class="time-container bg-white" :style="index>0? 'margin-top: 10px;':''">

        <div style="position: absolute; right: 35px; z-index: 100;">
          <a type="button" class="btn btn-danger btn-icon btn-xs" @click="removeTime(time.index)">
            <i class="fa fa-trash"></i>
          </a>
        </div>
        <div class="checkbox-inline" v-if="modality == 'rangos'">
          <label class="checkbox checkbox-success">
            <strong style="padding-right:5px">Duración minima</strong>
            <input type="checkbox" :checked="time.time_min == 'true' ? true : false" @input="changeTimeMin($event, time.index)" />
            <span></span>
          </label>
        </div>
        <div class="row">
          <div class="col-4">
            <label class="col-form-label">Duración</label>
            <div>
              <select :name="`times[${time.index}].type`" class="form-control form-control-lg form-control-solid" :value="time.type" @input="addTimesField">
                <option hidden selected value="">Seleciona el tipo de tiempo...</option>
                <option value="semanas">Semanas</option>
                <option value="horas">Horas</option>
                <option value="mes">Mes</option>
                <option value="lecciones">Lecciones</option>
              </select>
              <div class="fv-plugins-message-container"></div>
            </div>
          </div>

          <template v-if="modality == 'rangos'">
            <div class="col-4" v-if="time.time_min == 'false'">
              <label class="col-form-label">Desde y Hasta</label>
              <div class="row">
                <div class="input-group col-6" style="padding-right: 0;">
                  <div class="input-group-prepend">
                    <span class="input-group-text">Desde</span>
                  </div>
                  <input class="form-control form-control-lg" type="number" placeholder="0.00" :value="time.from" style="border-radius:0px; adding:10px 6px;" :name="`times[${time.index}].from`" @input="addTimesField" />
                </div>
                <div class="input-group col-6" style="padding-left: 0;">
                  <div class="input-group-prepend">
                    <span class="input-group-text" style="border-radius: 0px;">Hasta</span>
                  </div>
                  <input class="form-control form-control-lg" type="number" placeholder="0.00" :value="time.to" :name="`times[${time.index}].to`" style="adding:10px 6px;" @input="addTimesField" />
                </div>
              </div>
            </div>
            <div class="col-4" v-else>
              <label class="col-form-label">Cantidad</label>
              <div class="input-group" style="padding-right: 0;">
                <div class="input-group-prepend">
                  <span class="input-group-text">minimo</span>
                </div>
                <input class="form-control form-control-lg" type="number" placeholder="0.00" :value="time.min" style="border-radius: 0px;" :name="`times[${time.index}].min`" @input="addTimesField" />
              </div>
            </div>
          </template>
          <template v-else>
            <div class="col-4">
              <label class="col-form-label">Cantidad</label>
              <div class="input-group" style="padding-right: 0;">
                <div class="input-group-prepend">
                  <span class="input-group-text">numero</span>
                </div>
                <input class="form-control form-control-lg" type="number" placeholder="0.00" :value="time.number" style="border-radius: 0px;" :name="`times[${time.index}].number`" @input="addTimesField" />
              </div>
            </div>
          </template>

          <div class="col-4">
            <label class="col-form-label">Precio</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">{{selectCurrency(studyProgram?.currency?.id)}}</span>
              </div>
              <input class="form-control form-control-lg" type="number" placeholder="0.00" :value="time?.price" :name="`times[${time.index}].price`" @input="addTimesField" />
            </div>
          </div>
        </div>
      </div>

    </template>
  </div>

</div>
</template>

<script>
import { addTimes } from '@components/admin/studyProgram/register/formRegister.js'
import { mapState, mapActions, mapGetters } from 'vuex'
import { trim } from 'lodash'
const arrayTime = {
  time_min: "false",
  type: "",
  number: "",
  price: "",
  from: "",
  min: "",
  to: "",
}
export default {
  data() {
    return {
      timeMin: false,
    }
  },
  computed: {
    ...mapState({
      studyProgram: ({ studyProgram }) => studyProgram.newStudyProgram,
    }),
    ...mapGetters('currency', ['selectCurrency']),
    modality() {
      return this.studyProgram?.modality
    },
    modalityTimes() {
      return this.studyProgram?.times?.filter((time) => time.modality == this.modality)
    }
  },
  methods: {
    ...mapActions('global', [
      'EditFieldState',
      'AddArrayState',
      'RemoveArrayState',
    ]),
    json_decode(value) {
      return JSON.parse(value)
    },
    addTimesField(e) {
      this.EditFieldState({
        state: 'studyProgram',
        type: 'newStudyProgram',
        field: e.target.name,
        value: trim(e.target.value),
      })
    },
    changeTimeMin(e, index) {
      this.EditFieldState({
        state: 'studyProgram',
        type: 'newStudyProgram',
        field: `times[${index}].time_min`,
        value: trim(e.target.checked),
      })
    },
    addTime() {
      addTimes(this.studyProgram.times.length)
      this.AddArrayState({
        state: 'studyProgram',
        type: 'newStudyProgram',
        field: 'times',
        value: {
          index: this.studyProgram.times.length,
          study_program_id: '',
          modality: this.modality,
          ...arrayTime,
        },
      })
    },
    removeTime(index) {
      this.RemoveArrayState({
        state: 'studyProgram',
        type: 'newStudyProgram',
        field: 'times',
        index: index
      })
    }
  },
}
</script>
