<template>
  <div class="w-full px-4" style="margin:0px">
    <div class="uk-switcher max-w-2xl" id="elements-nav" style="touch-action: pan-y pinch-zoom;">

      <Details/>

      <Locations/>

      <Programs/>

      <Gallery/>

    </div>
  </div>
</template>

<script>
import Details from './tabs/Details.vue'
import Locations from './tabs/Locations.vue'
import Programs from './tabs/Programs.vue'
import Gallery from './tabs/Gallery.vue'

export default {
  components: {
    Locations,
    Programs,
    Details,
    Gallery,
  },
}
</script>
