import _ from 'lodash'

/* Funcion para obtener un booleano de lo que se
obtiene del localstorages */
export const getItemBoolean = (value) => {
  return value=="true" ? true : false
}

/* Funcion para recorrer un objeto y vaciar sus
propiedades dejandolo sin valores */
export const cleanObject = (value) => {

	for (let key in value) {

    /* Si el campo es un objeto volvemos a llamar
    a la misma funcion - aqui se implementa la recursividad */
		if (typeof value[key] == 'object') {
			cleanObject(value[key])
		}
    /* Si es boolean por defecto lo vuelve a false */
		if (typeof value[key] == 'boolean') {
			value[key] = false
		}
    /* Si es string por defecto lo vuelve null o vacio='' */
		if (typeof value[key] == 'string') {
			value[key] = ''
		}
    /* Si es number por defecto lo vuelve a 0 */
		if (typeof value[key] == 'number') {
			value[key] = 0
		}
	}
}

/* Funcion para transformar arrays en objetos.
Util para grandes cantidades de datos, y eficiente
para acceder a la informacion por la llave principal
que se le asigne por defecto. */
export const keyBy = (arr, key) => {
	return arr.reduce((acc, el) => {
		acc[el[key]] = el //asignamos el nomber al objeto y su valor
		return acc // retornamos el objeto ya listo
	}, {}) // lo añadimos en el {}
}

/* Funcion para agregar si no existe y borrar si ya existe */
export const addOrRemove = (arr, val) => {
  if (!_.includes(arr, val)) { //Preguntamos si no existe
    arr.push(val); //Se agrega
  } else { // De lo contrario de borra
    _.remove(arr, item => item === val);
  }
}

/* Funcion para agregar si no existe y borrar si ya existe solo una vez */
export const addOrRemoveOnce = (arr, val, type="") => {
	if (type == 'del') { //Preguntamos si es del tipo 'del'
		_.remove(arr, item => item === val); // Buscamos y borramos
	} else {
		arr.push(val); // Agregamos
	}
}

/* Funcion para obtener del localStorage los datos*/
export const getMenyPersisten = (data) => {

  /* Recorremos las propiedades de data para
  obtener los datos de cada propiedad segun
  su nombre */
  for (const field in data) {
    data[field] = localStorage.getItem(field) /*
    La insertamos en el navegador */
  }
  return data /* Retornamos todo cuando
  termine de iterar */
}

/* Funcion para insertar en localStorage datos */
export const setMenyPersisten = (data) => {

  /* Recorremos las propiedades de data para
  guardar cada propiedad segun su nombre */
  for (const field in data) {
    localStorage.setItem(field, data[field]) /*
    Almacenamos en localStorage */
  }
}

/* Funcion para eliminar de localStorage datos */
export const deleteMenyPersisten = (data) => {

  /* Recorremos las propiedades de data para
  eliminar cada propiedad segun su nombre */
  for (const field in data) {
    localStorage.setItem(field, '') /*
    Vaciamos de localStorage el valor */
  }
}
