/* Servicios de consumo de api */
import Api from '@services/modules/time.js'

export default {
  namespaced: true, //hace que sea global el modulo en vuex
  state: () => ({
    deleteTimes:[], /* lo usamos para almacenar los ids
    que se quieres eliminar de times */
  }),
  mutations: {

    /* Eliminamos un dato en la db y en a la dataTable */
    DELETE_TIMES_ARRAY(state) {
      state.deleteTimes = []
    },

    /* Eliminamos un dato en la db y en a la dataTable */
    // DELETE_TIMES(state) {
    //   console.log(state.deleteTimes)
    //   /* Recorremos el array que contiene los ids que
    //   seran eliminados */
    //   state.deleteTimes.forEach((id) => {
    //
    //     /* LLamado a la Api */
    //     Api.DeleteTime({ id })
    //   })
    // },
    /*****----------------------------------------------------*****/

    /* Registramos un dato en la db y lo agregamos a la dataTable */
    SET_DELETE_TIMES(state, payload) {
      state.deleteTimes.push(payload) /* Insertamos el nuevo dato
      en el array que contiene todos los datos existente */
    },
    /*****----------------------------------------------------*****/
  },
  actions: {
    /* Eliminamos varios datos */
    // DeleteTime({ commit }) {
    //   commit('DELETE_TIMES')
    // },
    /* Registramos a un solo dato */
    setDeleteTimes({ commit }, payload) {
      /* payload -> objeto con el nuevo time */
      commit('SET_DELETE_TIMES', payload)
    },
  }
}
