/* Middlewares*/

/* Utiliades y ayudantes */
import { computed, ref, watchEffect } from 'vue'
import { getItemBoolean } from '@tools/util.js'
import { isEmpty } from 'lodash'
import router from '@router'
import store from '@store'

/* Api: Url base y opciones */
import Api from '@services/modules/authenticated.js'

let startRouter = '' /* Variable para definir la ruta por
la que se paso */

/* Funcion para llamar una endpoint con el fin de
verificar si la session esta activa y permitir o
negar el paso a ciertas rutas */
const isSessionActive = () => {

  let session = localStorage.getItem('session') /* Obtenemos
  la session que esta o estuvo activo/a */
  const token = localStorage.getItem('access_token') /* Obtenemos
  el token de acceso que esta o estuvo activo/a */

  /* Si el token no esta vacio se ejecuta */
  if (!isEmpty(token)) {

    /* Obtenemos y guardamos la session y la hacemos reactiva */
    let sessionInactive = ref(getItemBoolean(session))

    /* Llamado a la Api */
    Api.session(
      token, // Data para autenticar - y saber si estamos activos
      /* Obtenemos la data y un validador */
      (data, /*validate */) => {

        /* Siempre que haya una diferencia en la 'session' se ejecuta */
        if (getItemBoolean(session) != data.error ) {

          localStorage.setItem('session', data.error) /* Cuando hay
          error es un 'true' y se deslogea */
          sessionInactive.value = data.error /* volvemos la session
          inactiva en false, es decir, la session no esta inactiva,
          si no mas bien activa. */
          window.location.reload() /* recargamos para que se salga */
        }
      }
    )
    return sessionInactive /* Retornamos si hay cambios */
  }
  return ref(true) /* 'true' significa que debe logiarse para entrar */
}

/* Funcion para autenticar cuando se esta dentro de la session */
export const authenticared = (to, from) => {

  /* Preguntamos si es diferente de 'guest', esto significa que si no
  venimos del middlewares anterior entonces se ejecuta este middlewares */
  if (startRouter != 'guest'){

    const isSession = isSessionActive() /* Obtenemos la session */

    /* Detectamos cuando hayan cambios */
    watchEffect(() => {

      /* En caso de haber un cambio y la variable 'isSession' y
      ser un true, entonces no esta logiado y mandamos a logiar */
      if (isSession.value) {

        /* Redireccionamos */
        let currentRouter = router.resolve({ name: 'login' })
        window.location.assign(currentRouter.href)
      }
    })
  }
  startRouter = 'authenticared' /* Una ves ya se uso este middlewares
  no hay que volver a llamar a la api en el siguiente middleware */
}

/* Funcion para verificar que no esta dentro de una session */
export const guest = (to, from) => {

  /* Preguntamos si es diferente de 'authenticared', esto significa que si no
  venimos del middlewares anterior entonces se ejecuta este middlewares */
  if (startRouter != 'authenticared') {
    const isSession = isSessionActive()

    /* Detectamos cuando hayan cambios */
    watchEffect(() => {

      /* En caso de haber un cambio y la variable 'isSession' y
      ser un true, entonces ya esta logiado y lo sacamos de la ruta
      que no queremos que sea accedida una ves logiado, tal como login */
      if (!isSession.value) {
        let currentRouter = router.resolve({ name: 'index-dashboard' })
        window.location.assign(currentRouter.href)
      }
    })
  }
  startRouter = 'guest' /* Una ves ya se uso este middlewares
  no hay que volver a llamar a la api en el siguiente middleware */
}
