<template>
<div class="fixed block lg:hidden z-40" style="top:55vh; left:-18px;" id="btn-active-menu-profile">
    <button class="bg-white py-2 px-2 shadow-xl rounded-full inline-flex items-center">
      <IconArrowRightTwo color="#28aae1"/>
    </button>
</div>

<div class="fixed lg:static max-w-xs w-4/12 lg:block z-50 lg:z-0 bg-white lg:bg-transparent pt-5 lg:p-0 lg:overflow-hidden overflow-scroll" style="height:93%; top:59px; left:-320px; min-width: 200px; margin:0px;" id="menu-filter-profile">

  <nav class="cd-secondary-nav border-r md:m-0 lg:-mx-4 nav-small pl-4 lg:pl-8">
    <ul uk-switcher="connect: #elements-nav ;animation: uk-animation-fade ; toggle: > * " style="display:block; padding:0px;">
      <li aria-expanded="false" class="uk-active" style="float:none;">
        <a href="#" class="lg:px-2" @click="activeTab">Destails</a>
      </li>
      <li aria-expanded="false" id="locations" style="float:none;">
        <a href="#" class="lg:px-2" @click="activeTab">Locations</a>
      </li>
      <li aria-expanded="false" id="programs" style="float:none;">
        <a href="#" class="lg:px-2" @click="activeTab">Programs</a>
      </li>
      <li aria-expanded="true" id="gallery" style="float:none;">
        <a href="#" class="lg:px-2" @click="activeTab">Gallery</a>
      </li>
    </ul>
  </nav>

  <div class="absolute lg:static rounded pl-2 md:pl-3 bottom-0 mr-4" style="background: #eee;">
    <ul class="space-y-6 py-6 pr-3">
      <li>
        <div class="flex">
          <i class="fa fa-map-marker mr-3 mt-1 text-one"></i>
          <span>{{$headquarters[id]?.address}}</span>
        </div>
      </li>
      <li>
        <div class="flex">
          <i class="fa fa-phone mr-3 text-one"></i>
          <span>{{$headquarters[id]?.phone}}</span>
        </div>
      </li>
      <li>
        <div class="flex">
          <span class="bg-gray">
            <i class="fa fa-globe mr-3 text-one"></i>
            <a :href="$headquarters[id]?.web||'#'" target="_blank">Web Site</a>
          </span>
        </div>
      </li>
    </ul>
  </div>
</div>

<div class="fixed top-0 w-full h-full z-40 bg-black bg-opacity-40 hidden lg:hidden" id="bg-all-profile"></div>

</template>

<script>
import IconArrowRightTwo from '@components/icons/ArrowRightTwo.vue'

import {mapState} from 'vuex'

export default {
  data(){
    return {
      id:'',
    }
  },
  components:{
    IconArrowRightTwo,
  },
  computed:{
    ...mapState({
      $headquarters: ({ headquarter }) => headquarter.$headquarters,
    }),
  },
  methods:{
    activeTab(){
      $('#bg-all-profile').removeClass('block')
      $('#bg-all-profile').addClass('hidden')
      $('#menu-filter-profile').css({left: '-320px', transition: 'left 0.4s'})
    }
  },
  mounted(){
    this.id = this.$route.params.id

    $(()=>{
      $('#bg-all-profile').click(function(){
        $(this).removeClass('block')
        $(this).addClass('hidden')
        $('#menu-filter-profile').css({left: '-320px', transition: 'left 0.4s'})
      })

      $('#btn-active-menu-profile').click(function(){
        $('#bg-all-profile').removeClass('hidden')
        $('#bg-all-profile').addClass('block')
        $('#menu-filter-profile').css({left: '0px', transition: 'left 0.4s'})
      })
    })
  },
  watch:{
    $route(to, from){
      this.id = to.params?.id
    }
  }
}
</script>

<style scoped>
.cd-secondary-nav.nav-small {
  height: auto !important;
}

.cd-secondary-nav {
  overflow: visible;
  -webkit-transform: translateY(0) !important;
  transform: translateY(0) !important;
}

.cd-secondary-nav li {
  display: inline;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.cd-secondary-nav ul,
.cd-secondary-nav li,
.cd-secondary-nav a {
  height: 100%;
}

.cd-secondary-nav li.active a,
.cd-secondary-nav li.uk-active a {
  border-right: 3px solid #28aae1 !important;
  border-bottom: 0px !important;
}

.cd-secondary-nav li.active a,
.cd-secondary-nav li.uk-active a,
.cd-secondary-nav li:hover a {
    color: #28aae1 !important;
}

.cd-secondary-nav.nav-small li a {
  line-height: 30px !important;
}
</style>
