<template>
  <select :name="name" @input="$emit('input', $event)" :value="value"
    class="form-control form-control-lg form-control-solid">
    <option hidden selected value="">Seleciona el estudio...</option>
    <option :value="study?.id" v-for="(study, index) in studies" :key="index">{{study?.title}}</option>
  </select>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {
  name:'SelectTypeAccount',
  props:{
    value:{
      type:String,
      default:'',
    },
    name:{
      type:String,
      default:'',
    }
  },
  computed:{
    ...mapState({
      studies: ({ study }) => study.studies,
    }),
  },
  methods: {
    ...mapActions('study', ['ShowAllStudies']),

  },
  mounted(){
    this.ShowAllStudies()
  },
};
</script>
