<template>
<svg x="0px" y="0px" :width="size" :height="size" viewBox="0 0 172 172" :style="`fill:${color};`">
  <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none"
    text-anchor="none" style="mix-blend-mode: normal">
    <path d="M0,172v-172h172v172z" fill="none"></path>
    <g :fill="color">
      <rect x="52.5" y="50.5" transform="scale(2.6875,2.6875)" width="2" height="2"></rect>
      <path d="M111.53125,137.0625v4.03125h21.5v-5.375h-20.15625c-0.74192,0.0005 -1.34325,0.60183 -1.34375,1.34375z"></path>
      <path
        d="M161.25,103.46875h-64.5c-5.19233,0.00628 -9.39997,4.21392 -9.40625,9.40625v48.375c0.00628,5.19233 4.21392,9.39997 9.40625,9.40625h64.5c5.19233,-0.00628 9.39997,-4.21392 9.40625,-9.40625v-48.375c-0.00628,-5.19233 -4.21392,-9.39997 -9.40625,-9.40625zM154.53125,153.1875c0,2.2264 -1.80485,4.03125 -4.03125,4.03125c-2.2264,0 -4.03125,-1.80485 -4.03125,-4.03125v-4.03125h-34.9375v4.03125c0,2.2264 -1.80485,4.03125 -4.03125,4.03125c-2.2264,0 -4.03125,-1.80485 -4.03125,-4.03125v-16.125c0.00628,-5.19233 4.21392,-9.39997 9.40625,-9.40625h33.59375v-6.71875c0,-2.2264 1.80485,-4.03125 4.03125,-4.03125c2.2264,0 4.03125,1.80485 4.03125,4.03125z">
      </path>
      <path
        d="M5.375,170.65625h72.5625c2.2264,0 4.03125,-1.80485 4.03125,-4.03125c0,-2.2264 -1.80485,-4.03125 -4.03125,-4.03125h-6.71875v-24.1875h6.71875c2.2264,0 4.03125,-1.80485 4.03125,-4.03125c0,-2.2264 -1.80485,-4.03125 -4.03125,-4.03125h-48.375c-1.0692,-0.00016 -2.09466,0.4245 -2.85071,1.18054c-0.75604,0.75604 -1.18071,1.7815 -1.18054,2.85071v28.21875h-16.125v-149.15625c0.00243,-2.22539 1.80586,-4.02882 4.03125,-4.03125h80.625c2.22539,0.00243 4.02882,1.80586 4.03125,4.03125v80.625c0,2.2264 1.80485,4.03125 4.03125,4.03125c2.2264,0 4.03125,-1.80485 4.03125,-4.03125v-63.15625h30.90625c2.22539,0.00243 4.02882,1.80586 4.03125,4.03125v59.125c0,2.2264 1.80485,4.03125 4.03125,4.03125c2.2264,0 4.03125,-1.80485 4.03125,-4.03125v-59.125c-0.00727,-6.67618 -5.41757,-12.08648 -12.09375,-12.09375h-30.90625v-9.40625c-0.00727,-6.67618 -5.41757,-12.08648 -12.09375,-12.09375h-80.625c-6.67618,0.00727 -12.08648,5.41757 -12.09375,12.09375v153.1875c-0.00016,1.0692 0.4245,2.09466 1.18054,2.85071c0.75604,0.75604 1.7815,1.18071 2.85071,1.18054zM63.15625,162.59375h-10.75v-24.1875h10.75zM33.59375,138.40625h10.75v24.1875h-10.75z">
      </path>
      <path d="M49.71875,29.5625c0,-2.2264 -1.80485,-4.03125 -4.03125,-4.03125c-2.2264,0 -4.03125,1.80485 -4.03125,4.03125v13.4375c0,2.2264 1.80485,4.03125 4.03125,4.03125c2.2264,0 4.03125,-1.80485 4.03125,-4.03125z"></path>
      <path d="M81.96875,29.5625c0,-2.2264 -1.80485,-4.03125 -4.03125,-4.03125c-2.2264,0 -4.03125,1.80485 -4.03125,4.03125v13.4375c0,2.2264 1.80485,4.03125 4.03125,4.03125c2.2264,0 4.03125,-1.80485 4.03125,-4.03125z"></path>
      <path d="M33.59375,29.5625c0,-2.2264 -1.80485,-4.03125 -4.03125,-4.03125c-2.2264,0 -4.03125,1.80485 -4.03125,4.03125v13.4375c0,2.2264 1.80485,4.03125 4.03125,4.03125c2.2264,0 4.03125,-1.80485 4.03125,-4.03125z"></path>
      <path d="M65.84375,29.5625c0,-2.2264 -1.80485,-4.03125 -4.03125,-4.03125c-2.2264,0 -4.03125,1.80485 -4.03125,4.03125v13.4375c0,2.2264 1.80485,4.03125 4.03125,4.03125c2.2264,0 4.03125,-1.80485 4.03125,-4.03125z"></path>
      <path d="M49.71875,61.8125c0,-2.2264 -1.80485,-4.03125 -4.03125,-4.03125c-2.2264,0 -4.03125,1.80485 -4.03125,4.03125v13.4375c0,2.2264 1.80485,4.03125 4.03125,4.03125c2.2264,0 4.03125,-1.80485 4.03125,-4.03125z"></path>
      <path d="M81.96875,61.8125c0,-2.2264 -1.80485,-4.03125 -4.03125,-4.03125c-2.2264,0 -4.03125,1.80485 -4.03125,4.03125v13.4375c0,2.2264 1.80485,4.03125 4.03125,4.03125c2.2264,0 4.03125,-1.80485 4.03125,-4.03125z"></path>
      <path d="M33.59375,61.8125c0,-2.2264 -1.80485,-4.03125 -4.03125,-4.03125c-2.2264,0 -4.03125,1.80485 -4.03125,4.03125v13.4375c0,2.2264 1.80485,4.03125 4.03125,4.03125c2.2264,0 4.03125,-1.80485 4.03125,-4.03125z"></path>
      <path d="M65.84375,61.8125c0,-2.2264 -1.80485,-4.03125 -4.03125,-4.03125c-2.2264,0 -4.03125,1.80485 -4.03125,4.03125v13.4375c0,2.2264 1.80485,4.03125 4.03125,4.03125c2.2264,0 4.03125,-1.80485 4.03125,-4.03125z"></path>
      <path
        d="M45.6875,90.03125c-1.0692,-0.00016 -2.09466,0.4245 -2.85071,1.18054c-0.75604,0.75604 -1.18071,1.7815 -1.18054,2.85071v13.4375c0,2.2264 1.80485,4.03125 4.03125,4.03125c2.2264,0 4.03125,-1.80485 4.03125,-4.03125v-13.4375c0.00016,-1.0692 -0.4245,-2.09466 -1.18054,-2.85071c-0.75604,-0.75604 -1.7815,-1.18071 -2.85071,-1.18054z">
      </path>
      <path
        d="M77.9375,90.03125c-1.0692,-0.00016 -2.09466,0.4245 -2.85071,1.18054c-0.75604,0.75604 -1.18071,1.7815 -1.18054,2.85071v13.4375c0,2.2264 1.80485,4.03125 4.03125,4.03125c2.2264,0 4.03125,-1.80485 4.03125,-4.03125v-13.4375c0.00016,-1.0692 -0.4245,-2.09466 -1.18054,-2.85071c-0.75604,-0.75604 -1.7815,-1.18071 -2.85071,-1.18054z">
      </path>
      <path
        d="M29.5625,90.03125c-1.0692,-0.00016 -2.09466,0.4245 -2.85071,1.18054c-0.75604,0.75604 -1.18071,1.7815 -1.18054,2.85071v13.4375c0,2.2264 1.80485,4.03125 4.03125,4.03125c2.2264,0 4.03125,-1.80485 4.03125,-4.03125v-13.4375c0.00016,-1.0692 -0.4245,-2.09466 -1.18054,-2.85071c-0.75604,-0.75604 -1.7815,-1.18071 -2.85071,-1.18054z">
      </path>
      <path
        d="M61.8125,90.03125c-1.0692,-0.00016 -2.09466,0.4245 -2.85071,1.18054c-0.75604,0.75604 -1.18071,1.7815 -1.18054,2.85071v13.4375c0,2.2264 1.80485,4.03125 4.03125,4.03125c2.2264,0 4.03125,-1.80485 4.03125,-4.03125v-13.4375c0.00016,-1.0692 -0.4245,-2.09466 -1.18054,-2.85071c-0.75604,-0.75604 -1.7815,-1.18071 -2.85071,-1.18054z">
      </path>
      <path d="M135.71875,45.6875c0,-2.2264 -1.80485,-4.03125 -4.03125,-4.03125c-2.2264,0 -4.03125,1.80485 -4.03125,4.03125v10.75c0,2.2264 1.80485,4.03125 4.03125,4.03125c2.2264,0 4.03125,-1.80485 4.03125,-4.03125z"></path>
      <path d="M120.9375,45.6875c0,-2.2264 -1.80485,-4.03125 -4.03125,-4.03125c-2.2264,0 -4.03125,1.80485 -4.03125,4.03125v10.75c0,2.2264 1.80485,4.03125 4.03125,4.03125c2.2264,0 4.03125,-1.80485 4.03125,-4.03125z"></path>
      <path
        d="M131.6875,71.21875c-1.0692,-0.00016 -2.09466,0.4245 -2.85071,1.18054c-0.75604,0.75604 -1.18071,1.7815 -1.18054,2.85071v10.75c0,2.2264 1.80485,4.03125 4.03125,4.03125c2.2264,0 4.03125,-1.80485 4.03125,-4.03125v-10.75c0.00016,-1.0692 -0.4245,-2.09466 -1.18054,-2.85071c-0.75604,-0.75604 -1.7815,-1.18071 -2.85071,-1.18054z">
      </path>
      <path
        d="M116.90625,71.21875c-1.0692,-0.00016 -2.09466,0.4245 -2.85071,1.18054c-0.75604,0.75604 -1.18071,1.7815 -1.18054,2.85071v10.75c0,2.2264 1.80485,4.03125 4.03125,4.03125c2.2264,0 4.03125,-1.80485 4.03125,-4.03125v-10.75c0.00016,-1.0692 -0.4245,-2.09466 -1.18054,-2.85071c-0.75604,-0.75604 -1.7815,-1.18071 -2.85071,-1.18054z">
      </path>
    </g>
  </g>
</svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#000000',
    },
    size: {
      type: String,
      default: '24',
    }
  }
}
</script>
