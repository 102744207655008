/* Middlewares*/

/* Utiliades y ayudantes */
import countries from '@services/data/location/onlyCountries.js'
import { lowerCase, snakeCase } from 'lodash'

export const existingCountry = (to, from, next) => {
  let name = to.params.name

  if(countries[name]) {
    to.params.id = countries[name]
    to.params.name = lowerCase(name)
    next()
  }
  else next({ name: 'destinations' })
}
