import {existingCountry} from '@middlewares/verifyCountry.js'
import Guest from '@layouts/Guest.vue'

export default [
  /* Rutas de auteticación o desautenticación */
  {
    path: '/destinations',
    component: Guest,
    /* Rutas a partir del path principal */
    children: [
      {
        path: '',
        name: 'destinations',
        component: async () => await require('@views/Destinations.vue'),
      },
      {
        path: 'search/:name',
        name: 'destination-search',
        beforeEnter: [existingCountry],
        component: async () => await require('@views/DestinationSearch.vue'),
      },
    ],
  },
]
