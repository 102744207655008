<template>
<div class="pb-5">
  <div class="row">
    <div class="col-xl-12">
      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label">Nombre del tipo de cuenta</label>
        <div class="col-lg-9 col-xl-9">
          <input class="form-control form-control-lg form-control-solid" name="name" type="text" placeholder="Nuevo tipo de cuenta" :value="typeAccount.name" @input="UpdateFieldTypeAccount"/>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label">Precio Del tipo de cuenta</label>
        <div class="col-lg-9 col-xl-9">

          <div class="input-group">
						<div class="input-group-prepend">
							<span class="input-group-text">$</span>
						</div>
            <input class="form-control form-control-lg" name="price" type="number" placeholder="0.00" :value="typeAccount.price" @input="UpdateFieldTypeAccount" aria-label="US - dollar"/>
					</div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {resetForm} from '@components/admin/typeAccount/register/formRegister.js'
import {mapState, mapActions} from 'vuex'
import {trim} from 'lodash'

export default {
  computed: {
    ...mapState({
      typeAccount: ({typeAccount}) => typeAccount.newTypeAccount
    }),
  },
  methods: {
    ...mapActions('global', ['EditFieldState']),

    UpdateFieldTypeAccount(e) {
      this.EditFieldState({
        state: 'typeAccount',
        type:'newTypeAccount',
        field: e.target.name,
        value: trim(e.target.value),
      })
    }
  },
  deactivated(){
    resetForm()
  }
};
</script>
