<template>
<div class="py-5 custom-color">
  <div class="flex items-center justify-content-between flex-wrap sm:flex-nowrap">
    <div class="flex content-center flex-wrap">

      <ol class="list-reset flex text-grey-dark text-xs">
        <li class="flex items-center">
          <IconHome size="15px" color="#073755" class="mr-1" style="margin-top: -1px" />
          <a href="#" class="font-bold">Home</a>
        </li>
        <li><span class="mx-2">/</span></li>
        <li class="flex content-center">
          <IconDestinations size="14px" color="#073755" class="mr-1" />
          <a href="#" class="font-bold">Destinations</a>
        </li>
        <li><span class="mx-2">/</span></li>
        <li class="flex">
          <IconFlag size="14px" color="#073755" class="mr-1" />
          Canada
        </li>
      </ol>

    </div>
    <!-- <div class="flex items-center">
      Showing (1 a 9) de 9 results
    </div> -->
  </div>
</div>
</template>

<script>
import IconDestinations from '@components/icons/Destinations.vue'
import IconFlag from '@components/icons/Flag.vue'
import IconHome from '@components/icons/Home.vue'

export default {
  components: {
    IconDestinations,
    IconHome,
    IconFlag,
  }
}
</script>

<style scoped>
.custom-color .active>a {
  color: #073755 !important;
}

.custom-color .breadcrumb:focus {
  color: #073755 !important;
}
</style>
