<template>
<div class="gallery">
  <div class="grid grid-cols-3 gap-2">
    <div v-for="(image, index) in $headquarters[id]?.gallery">
      <div :style="imgStyle(image)"></div>
    </div>
  </div>
</div>
</template>

<script>
/* import IconGraduation from '@components/icons/Graduation.vue' */

import {kebabCase} from 'lodash'
import {mapState} from 'vuex'

const url_base = 'https://you-worldwide.com/api/v1'

export default {
  data(){
    return {
      id:'',
    }
  },
  computed:{
    ...mapState({
      $headquarters: ({ headquarter }) => headquarter.$headquarters,
    }),
  },
  methods: {
    imgStyle(img) {
      let url = '', nameFile = ''
      if (img) {
        let arr = ["about-1-jpeg","about-2-jpeg","about-3-jpeg","about-4-jpeg"]
        let logoName = img || 'placeholder.png'

        if (arr.includes(img)) nameFile = 'test'
        else nameFile = kebabCase(this.$headquarters[this.id]?.name) || 'placeholder'

        url = `${url_base}/headquarter/gallery/${nameFile}/${logoName}`
      }

      return {
        backgroundImage: `url(${url})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        height: '250px',
        width: '100%',
      }
    }
  },
  mounted(){
    this.id = this.$route.params?.id
  },
  watch:{
    $route(to, from){
      this.id = to.params?.id
    }
  }
}
</script>

<style scoped>
</style>
