<template>
<HeaderBackground />
<div class="flex flex-wrap">
  <div class="w-full sm:w-1/2 p-4 mt-6">

    <div class="p-5 mx-auto tube-card">

      <div class="text-center mt-4 mb-6">
        <h1 class="font-semibold text-2xl text-center uk-heading-line"><span style="color: #05a4e8; font-weight: bold;">About us</span></h1>
      </div>
      <article class="space-y-2 uk-article">
        <p class="lead"><span style="color: #05a4e8; font-weight: bold;">Y</span>ou Worldwide is a leading provider website of international schools worldwide, offering all kind of international education and programs.</p>
        <p> <span style="color: #05a4e8; font-weight: bold; "> You-worldwide.com</span> gathers the best schools and international programs all over the world in just one website making it easier for you to find the right one and always at the best
          price due to our huge discounts when you booked through us</p>
      </article>

      <h4 class="font-medium pt-3 text-xl" style="color: #05a4e8; font-weight: bold;">Mision</h4>
      <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
        cupidatat
        non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
      <h4 class="font-medium pt-3 text-xl" style="color: #05a4e8; font-weight: bold;"> Vision </h4>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
        cupidatat
        non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

    </div>

  </div>
  <div class="w-full sm:w-1/2 mt-6 mb-5">
    <!-- <div :style="style"></div> -->
    <div class="grid grid-cols-3 gap-2">
      <div class="col-span-2">
        <div :style="urlImage('about-1.jpeg')"></div>
      </div>
      <div>
        <div :style="urlImage('about-2.jpeg')"></div>
      </div>
      <div>
        <div :style="urlImage('about-3.jpeg')"></div>
      </div>
      <div class="col-span-2">
        <div :style="urlImage('about-4.jpeg')"></div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import HeaderBackground from '@components/guest/HeaderBackground.vue'
export default {
  components: {
    HeaderBackground,
  },
  methods: {
    urlImage(name) {
      return {
        backgroundImage: `url(${require('@assets/images/'+name)})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: '45vh',
        width: '100%',
      }
    }
  }
}
</script>
