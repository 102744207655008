<template>
  <div :class="activeOverlay"
    @click="DrawerToggleOverlay">
  </div>
</template>


<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'overlay-dashboard',
  computed: {
    ...mapState({
      overlay: ({ global }) => global.overlay,
      drawerSelect: ({ global }) => global.drawer.select,
    }),
    activeOverlay(){
      if (this.overlay) {
        if (this.drawerSelect=='main') return 'header-menu-wrapper-overlay'
        else if (this.drawerSelect == 'modalChat') return ''
        else return 'offcanvas-overlay'
      }
    }
  },
  methods: {
    ...mapActions('global', [
      'DrawerToggleOverlay'
    ]),
  },
}
</script>
