<template>
<div class="programs">

    <div class="block border-1 rounded">
      <!-- <div class="w-full bg-two p-3 text-center">
        <h3 class="text-white text-lg">Private classes</h3>
      </div> -->
      <template v-for="(study, index) in $headquarters[id]?.study_programs">
        <ul uk-accordion="" class="uk-accordion p-5">
          <li class="">
            <a class="uk-accordion-title border-1 border-two rounded-md pt-5 p-3 text-two" href="#">{{$studies[study.study_id].title}}</a>
            <div class="uk-accordion-content" aria-hidden="true" hidden="">
              <table class="table-fixed w-full">
                <thead>
                  <tr class="border bg-two text-white">
                    <th class="w-1/2 border p-2">Descripción</th>
                    <th class="w-1/2 border p-2">Opciones</th>
                    <th class="w-1/4 border p-2">Costo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border p-2">Costo de inscripción</td>
                    <td class="border p-2">Obligatorio</td>
                    <td class="border p-2">{{study.registration_price}} {{$currencies[study.currency_id].code}}</td>
                  </tr>
                  <tr>
                    <td class="border p-2">Traslado al Aeropuerto (ida)</td>
                    <td class="border p-2">Opcional</td>
                    <td class="border p-2">{{study.shipping_price}} {{$currencies[study.currency_id].code}}</td>
                  </tr>
                </tbody>
              </table>
              <table class="table-fixed w-full">
                <thead>
                  <tr class="border bg-two text-white">
                    <th class="w-1/2 border p-2">Duración</th>
                    <th class="w-1/4 border p-2">Precio</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(time, index) in study.times" :key="index">
                    <td class="border p-2">
                      <div v-if="time.modality == 'paquetes'">
                        {{time.from}} - {{time.to}} {{time.type}}
                      </div>
                      <div v-else>
                        {{time.number}} {{time.type}}
                      </div>
                    </td>
                    <td class="border p-2">{{time.price}} {{$currencies[study.currency_id].code}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </li>
          <!-- <li>
            <a class="uk-accordion-title" href="#">Course di italiano via skype</a>
            <div class="uk-accordion-content" aria-hidden="true" hidden="">
              <table class="table-fixed w-full">
                <thead>
                  <tr class="border bg-two text-white">
                    <th class="w-1/2 border p-2">Descripción</th>
                    <th class="w-1/2 border p-2">Opciones</th>
                    <th class="w-1/4 border p-2">Costo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border p-2">Costo de inscripción</td>
                    <td class="border p-2">Obligatorio</td>
                    <td class="border p-2">80 EUR</td>
                  </tr>
                  <tr>
                    <td class="border p-2">Traslado al Aeropuerto (ida)</td>
                    <td class="border p-2">Opcional</td>
                    <td class="border p-2">80,00 EUR</td>
                  </tr>
                </tbody>
              </table>
              <table class="table-fixed w-full">
                <thead>
                  <tr class="border bg-two text-white">
                    <th class="w-1/2 border p-2">Duración</th>
                    <th class="w-1/4 border p-2">Precio</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border p-2">1-240 lecciones</td>
                    <td class="border p-2">39 EUR</td>
                  </tr>
                  <tr>
                    <td class="border p-2">1-34 lecciones</td>
                    <td class="border p-2">16 EUR</td>
                  </tr>
                  <tr>
                    <td class="border p-2">1-23 lecciones</td>
                    <td class="border p-2">12 EUR</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </li> -->
        </ul>
      </template>
    </div>

  <!-- <div class="block border-1 rounded">
    <div class="w-full bg-two p-3 text-center">
      <h3 class="text-white text-lg">Private classes</h3>
    </div>
    <ul uk-accordion="" class="uk-accordion p-5">
      <li class="">
        <a class="uk-accordion-title" href="#">Individual course</a>
        <div class="uk-accordion-content" aria-hidden="true" hidden="">
          <table class="table-fixed w-full">
            <thead>
              <tr class="border bg-two text-white">
                <th class="w-1/2 border p-2">Descripción</th>
                <th class="w-1/2 border p-2">Opciones</th>
                <th class="w-1/4 border p-2">Costo</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border p-2">Costo de inscripción</td>
                <td class="border p-2">Obligatorio</td>
                <td class="border p-2">80 EUR</td>
              </tr>
              <tr>
                <td class="border p-2">Traslado al Aeropuerto (ida)</td>
                <td class="border p-2">Opcional</td>
                <td class="border p-2">80,00 EUR</td>
              </tr>
            </tbody>
          </table>
          <table class="table-fixed w-full">
            <thead>
              <tr class="border bg-two text-white">
                <th class="w-1/2 border p-2">Duración</th>
                <th class="w-1/4 border p-2">Precio</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border p-2">1-240 lecciones</td>
                <td class="border p-2">39 EUR</td>
              </tr>
              <tr>
                <td class="border p-2">1-34 lecciones</td>
                <td class="border p-2">16 EUR</td>
              </tr>
            </tbody>
          </table>
        </div>
      </li>
      <li>
        <a class="uk-accordion-title" href="#">Course di italiano via skype</a>
        <div class="uk-accordion-content" aria-hidden="true" hidden="">
          <table class="table-fixed w-full">
            <thead>
              <tr class="border bg-two text-white">
                <th class="w-1/2 border p-2">Descripción</th>
                <th class="w-1/2 border p-2">Opciones</th>
                <th class="w-1/4 border p-2">Costo</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border p-2">Costo de inscripción</td>
                <td class="border p-2">Obligatorio</td>
                <td class="border p-2">80 EUR</td>
              </tr>
              <tr>
                <td class="border p-2">Traslado al Aeropuerto (ida)</td>
                <td class="border p-2">Opcional</td>
                <td class="border p-2">80,00 EUR</td>
              </tr>
            </tbody>
          </table>
          <table class="table-fixed w-full">
            <thead>
              <tr class="border bg-two text-white">
                <th class="w-1/2 border p-2">Duración</th>
                <th class="w-1/4 border p-2">Precio</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border p-2">1-240 lecciones</td>
                <td class="border p-2">39 EUR</td>
              </tr>
              <tr>
                <td class="border p-2">1-34 lecciones</td>
                <td class="border p-2">16 EUR</td>
              </tr>
              <tr>
                <td class="border p-2">1-23 lecciones</td>
                <td class="border p-2">12 EUR</td>
              </tr>
            </tbody>
          </table>
        </div>
      </li>
    </ul>
  </div>
  <div class="block border-1 rounded mt-5">
    <div class="w-full bg-two p-3 text-center">
      <h3 class="text-white text-lg">Exam Preparation</h3>
    </div>
    <ul uk-accordion="" class="uk-accordion p-5">
      <li class="">
        <a class="uk-accordion-title" href="#">Individual course</a>
        <div class="uk-accordion-content" aria-hidden="true" hidden="">
          <table class="table-fixed w-full">
            <thead>
              <tr class="border bg-two text-white">
                <th class="w-1/2 border p-2">Descripción</th>
                <th class="w-1/2 border p-2">Opciones</th>
                <th class="w-1/4 border p-2">Costo</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border p-2">Costo de inscripción</td>
                <td class="border p-2">Obligatorio</td>
                <td class="border p-2">80 EUR</td>
              </tr>
              <tr>
                <td class="border p-2">Traslado al Aeropuerto (ida)</td>
                <td class="border p-2">Opcional</td>
                <td class="border p-2">80,00 EUR</td>
              </tr>
            </tbody>
          </table>
          <table class="table-fixed w-full">
            <thead>
              <tr class="border bg-two text-white">
                <th class="w-1/2 border p-2">Duración</th>
                <th class="w-1/4 border p-2">Precio</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border p-2">1-240 lecciones</td>
                <td class="border p-2">39 EUR</td>
              </tr>
              <tr>
                <td class="border p-2">1-34 lecciones</td>
                <td class="border p-2">16 EUR</td>
              </tr>
            </tbody>
          </table>
        </div>
      </li>
      <li>
        <a class="uk-accordion-title" href="#">Course di italiano via skype</a>
        <div class="uk-accordion-content" aria-hidden="true" hidden="">
          <table class="table-fixed w-full">
            <thead>
              <tr class="border bg-two text-white">
                <th class="w-1/2 border p-2">Descripción</th>
                <th class="w-1/2 border p-2">Opciones</th>
                <th class="w-1/4 border p-2">Costo</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border p-2">Costo de inscripción</td>
                <td class="border p-2">Obligatorio</td>
                <td class="border p-2">80 EUR</td>
              </tr>
              <tr>
                <td class="border p-2">Traslado al Aeropuerto (ida)</td>
                <td class="border p-2">Opcional</td>
                <td class="border p-2">80,00 EUR</td>
              </tr>
            </tbody>
          </table>
          <table class="table-fixed w-full">
            <thead>
              <tr class="border bg-two text-white">
                <th class="w-1/2 border p-2">Duración</th>
                <th class="w-1/4 border p-2">Precio</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border p-2">1-240 lecciones</td>
                <td class="border p-2">39 EUR</td>
              </tr>
              <tr>
                <td class="border p-2">1-34 lecciones</td>
                <td class="border p-2">16 EUR</td>
              </tr>
              <tr>
                <td class="border p-2">1-23 lecciones</td>
                <td class="border p-2">12 EUR</td>
              </tr>
            </tbody>
          </table>
        </div>
      </li>
    </ul>
  </div> -->
</div>
</template>

<script>
/* import IconGraduation from '@components/icons/Graduation.vue'
import IconLocation from '@components/icons/Location.vue'
import IconGallery from '@components/icons/Gallery.vue' */

import {mapState} from 'vuex'

export default {
  data(){
    return {
      id:'',
    }
  },
  computed:{
    ...mapState({
      $headquarters: ({ headquarter }) => headquarter.$headquarters,
      $studies: ({ study }) => study.$studies,
      $currencies: ({ currency }) => currency.$currencies,
    }),
  },
  mounted(){
    this.id = this.$route.params?.id
  },
  watch:{
    $route(to, from){
      this.id = to.params?.id
    }
  }
}
</script>
