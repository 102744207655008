<template>
<HeaderBackground />
<div class="mx-auto max-w-7xl p-1 pb-8">
  <Carousel />
</div>
</template>

<script>
import HeaderBackground from '@components/guest/index/HeaderBackground.vue'
import Carousel from '@components/guest/index/Carousel.vue'
export default {
  components: {
    Carousel,
    HeaderBackground,
  }
}
</script>
