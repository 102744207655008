import {isEmpty, upperFirst} from 'lodash'

import {
  baseTb,
  renderField,
  searchModify,
  countElements,
  exportsFunctions,
  multiSelectAndDelete,
  updateAndDeleteButtons
} from '@options/tables/index.js'

const renderCover = (cover) => {
let img = isEmpty(cover) ? `${url}/placeholder.png` : `${url}/${cover}`
return (`
<div class="d-flex align-items-center">
<div class="symbol symbol-60 flex-shrink-0">
<img src="${img}" alt="${img}"
style="border:1px solid #3445e5; width:60px; height:60px;">
</div>
</div>
`)
}

const renderLogo = (logo) => {
let img = isEmpty(logo) ? `${urlInst}/placeholder.png` : `${urlInst}/${logo}`
return (`
<div class="d-flex align-items-center">
<div class="symbol symbol-60 flex-shrink-0">
<img src="${img}" alt="${img}"
style="border-radius:50%; border:1px solid #3445e5; width:60px; height:60px;">
</div>
</div>
`)
}

/* Variables dentro de este hambito */

let urlInst = `https://you-worldwide.com/api/v1/institution/logo`
let url = `https://you-worldwide.com/api/v1/headquarter/cover`

let name = 'headquarter', tb = null, status = false
/* tbClass = '${name}_table' */

const initTable = (tbClass, actions, data) => {

  status = true
  tb = baseTb({
    name,
    tbClass: tbClass,
    data: data.headquarters,
    options: {
      columns: [
        { title: 'Portada', data: 'cover' }, /*2*/
        { title: 'Id', data: 'id' },
        { title: 'Nombre', data: 'name' },
        { title: 'Pais', data: 'country' },
        { title: 'Estado', data: 'state' },
        { title: 'institución', data: 'institution' },
        { title: 'logo', data: 'institution' },
      ],
      columnDefs: [
        {
          targets: 2,
          width:'100px',
          render: (data) => renderCover(data)
        },
        {
          targets: 7,
          render: (data) => renderField(data, 'name')
        },
        {
          targets: 8,
          render: (data) => renderLogo(data.logo)
        },
      ],
    }
  })
  countElements(tb)
  searchModify(tb, name)
  multiSelectAndDelete({ tb, tbClass, actions, state:name })
  updateAndDeleteButtons({ tb, tbClass, actions, state:name })

}

export const getTb = () => tb
export const getStatus = () => status

const getExport = exportsFunctions({
  getStatus,
  initTable,
  getTb,
  name,
})

getExport[`getTb${upperFirst(name)}`] = getTb

export default getExport
