<template>
  <div class="d-flex flex-row flex-column-fluid page">
    <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
      <div class="d-flex flex-column flex-column-fluid" id="kt_content">
        <SubHeader />
        <div class="d-flex flex-column-fluid">
          <div class="container">
            <div class="card card-custom gutter-b">
              <div class="card-body p-0">
                <FormUpdate />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* Funcionalidad de actualizar y validar la data */
import formUpdate from "@components/admin/headquarter/update/formUpdate.js"

/* Componentes */
import FormUpdate from '@components/admin/headquarter/update/FormUpdate.vue'
import SubHeader from '@components/admin/headquarter/update/SubHeader.vue'

/* funciones del vuex */
import {mapState, mapActions} from 'vuex'

export default {
  name:'Update-Headquarter',
  components:{
    SubHeader,
    FormUpdate,
  },
  methods:{
    /* Llamamos a N cantidad de acciones del store */
    ...mapActions('headquarter', ['ShowHeadquarter', 'UpdateHeadquarter']),
  },
  mounted(){
    /* Iniciamos la data del componente actual si no se a montado
    el componente, pasamos el parametro: this.$route.params.id, con
    la finalidad de obtener la 'sede' actual, para luego mostrar los
    datos en los inputs que se actuazaran. */
    this.ShowHeadquarter(this.$route.params.id)

    /* Instanciamos la validacion de actualizar.
    Pasamos la accion UpdateHeadquarter para internamente
    una ves validado todo, active la funcion y se actualize */
    formUpdate(this.UpdateHeadquarter)
  },
}
</script>
