<template>
<div class="pb-5">
  <div class="row">
    <div class="col-xl-12">

      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label">Institución</label>
        <div class="col-lg-9 col-xl-9">
          <input name="institution_id" class="form-control form-control-lg form-control-solid" placeholder="Nombre de la sede" :value="institutionSelect" disabled/>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label">Nombre</label>
        <div class="col-lg-9 col-xl-9">
          <input class="form-control form-control-lg form-control-solid" name="name" placeholder="Nombre de la sede" :value="headquarter?.name" @input="UpdateFieldHeadquarter"/>
        </div>
      </div>

      <Location type-store="headquarter" :mode-update="true"/>

      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label">Dirección</label>
        <div class="col-lg-9 col-xl-9">
          <input class="form-control form-control-lg form-control-solid" name="address" placeholder="Dirección de la sede" :value="headquarter?.address" @input="UpdateFieldHeadquarter"/>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label">Telefono</label>
        <div class="col-lg-9 col-xl-9">
          <input class="form-control form-control-lg form-control-solid" name="phone" placeholder="Telefono de la sede" :value="headquarter?.phone" @input="UpdateFieldHeadquarter"/>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label">Codigo postal</label>
        <div class="col-lg-2 col-xl-2">
          <input class="form-control form-control-lg form-control-solid" maxlength="15" name="postcode" placeholder="Codigo zip/postal" :value="headquarter?.postcode" @input="UpdateFieldHeadquarter"/>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label">Descripción - reseña historica</label>
        <div class="col-lg-9 col-xl-9">
          <textarea id="kt_quil_1" style="height: 125px" class="form-control form-control-lg form-control-solid" name="description" placeholder="Descripción o reseña historica" :value="headquarter?.description" @input="UpdateFieldHeadquarter"></textarea>
        </div>
      </div>

      <UploadCover :value="image_cover"
        @input="UpdateImageHeadquarter"
        @delete="deleteImageHeadquarter"/>

    </div>
  </div>
</div>
</template>

<script>
import UploadCover from '@components/admin/headquarter/UploadCover.vue'
import Location from '@components/admin/headquarter/Location.vue'

import {resetForm} from '@components/admin/headquarter/update/formUpdate.js'
import { endpoint } from '@services/api.js'

import {mapState, mapActions} from 'vuex'
import {trim} from 'lodash'

let url_cover = `${endpoint}/api/v1/headquarter/cover`

export default {
  components:{
    UploadCover,
    Location,
  },
  computed: {
    ...mapState({
      headquarter: ({headquarter}) => headquarter.headquarter,
      copyHeadquarter: ({headquarter}) => headquarter.copyHeadquarter,
      $institutions: ({institution}) => institution.$institutions
    }),
    institutionSelect(){
      let id = this.headquarter.institution_id
      let name = this.$institutions[id]?.name
      let alias = this.$institutions[id]?.alias
      return `${name} - ${alias}`
    },
    image_cover(){
      let image = 'placeholder.png'
      if (this.headquarter?.cover) {
        image = this.copyHeadquarter?.cover
      }
      return `${url_cover}/${image}`
    }
  },
  methods: {
    ...mapActions('global', ['EditFieldState', 'WatcherChangeInState']),

    detectChangeInState(){
      this.WatcherChangeInState({
        state: 'headquarter',
        type:{
          copy:'copyHeadquarter',
          mutated:'headquarter',
        }
      })
    },

    deleteImageHeadquarter(name) {
      this.EditFieldState({
        state: 'headquarter',
        type: 'headquarter',
        field: name,
        value: this.copyHeadquarter?.cover,
      })
    },

    UpdateImageHeadquarter(e) {
      this.EditFieldState({
        state: 'headquarter',
        type: 'headquarter',
        field: e.target.name,
        value: e.target.files[0],
      })
      this.detectChangeInState()
    },

    UpdateFieldHeadquarter(e) {
      this.EditFieldState({
        state: 'headquarter',
        type:'headquarter',
        field: e.target.name,
        value: trim(e.target.value),
      })
      this.detectChangeInState()
    },

  },
  /*deactivated() {
    console.log('sede', this.headquarter)
  }*/
};
</script>
