let validation = []
let validate = {
  fields: {
    title: {
      validators: {
        notEmpty: {
          message: 'Ingresa el titulo del estudio'
        }
      }
    },
  },
  plugins: {
    trigger: new FormValidation.plugins.Trigger(),
    // Bootstrap Framework Integration
    bootstrap: new FormValidation.plugins.Bootstrap({
      //eleInvalidClass: '',
      eleValidClass: '',
    })
  }
}
let formEl, _avatar
let customClass = {
    confirmButton: "btn font-weight-bold btn-primary",
    cancelButton: "btn font-weight-bold btn-default"
}
let KTContactsUpdate = function() {

  let _initValidation = function(CreateStudy) {

    validation.push(FormValidation.formValidation(formEl, validate))

    $('#create-form-study').on('click', function() {
      validation[0].validate().then(function(status) {
        if (status == 'Valid') {
          Swal.fire({
            text: "¡Todo es bueno! solo confirma",
            icon: "success",
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonText: "Registrar!",
            cancelButtonText: "No, cancelar",
            customClass
          }).then(() => {
            const errorUpdateForm = () => {
              Swal.fire({ //Notificacion de tipo de institucion no registrada
                text: "No se pudo registrar el estudio, intente en unos minutos",
                icon: "error",
                buttonsStyling: false,
                customClass
              })
            }
            const successUpdateForm = () => {
              Swal.fire({ //Notificacion de tipo de cuante correctamente
                text: "Estudio actualizado!",
                icon: "success",
                buttonsStyling: false,
                customClass
              })
            }
            if (status == 'Valid') {
              CreateStudy({
                successUpdateForm,
                errorUpdateForm,
              })
            }
          })
        }
      })
    })
  }

  return {
    init: function(CreateStudy) {
      _initValidation(CreateStudy)
    }
  }
}()

export default (CreateStudy) => {
  $(function() {
    formEl = KTUtil.getById('create_form_study')
    KTContactsUpdate.init(CreateStudy)
  })
}

export const resetForm = () => validation[0].resetForm(true)
