<template>
  <div class="d-flex flex-row flex-column-fluid page">
    <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
      <div class="d-flex flex-column flex-column-fluid" id="kt_content">
        <SubHeader />
        <div class="d-flex flex-column-fluid">
          <div class="container">
            <DataTable/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* Componentes */
import SubHeader from '@components/admin/typeAccount/index/SubHeader.vue'
import DataTable from '@components/admin/typeAccount/index/DataTable.vue'

export default {
  name: 'index-types-institution',
  components: {
    SubHeader,
    DataTable,
  },
}
</script>
