<template>
<div class="card card-custom">
  <div class="card-header flex-wrap py-5">
    <div class="card-title">
      <h3 class="card-label">Column Rendering
        <div class="text-muted pt-2 font-size-sm">custom colu rendering</div>
      </h3>
    </div>
    <div class="card-toolbar">
      <router-link :to="{ name:'register-headquarter'}" class="btn btn-primary font-weight-bolder" tag="a">
        <i class="la la-plus"></i>Agregar nueva sede
      </router-link>

      <button type="button" class="btn btn-danger font-weight-bolder ml-2" v-show="selected.length > 0" data-toggle="modal" data-target="#staticBackdrop" >
        <i class="la la-trash"></i>Eliminar
      </button>

      <div class="modal fade" id="staticBackdrop" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Confirmación</h5>
            </div>
            <div class="modal-body">
              ¿ Desea eliminar los tipos de cuentas seleccionados ?.
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-light-primary font-weight-bold" data-dismiss="modal">No</button>
              <button type="button" class="btn btn-primary font-weight-bold" data-dismiss="modal" id="delete-selected" @click="DeleteHeadquarters">Si</button>
            </div>
          </div>
        </div>
      </div>

		</div>
  </div>
  <div class="card-body">
      <Loading :active="loading">
        <table class="table table-separate table-head-custom table-checkable" id="headquarter_table">
        </table>
      </Loading>
  </div>
</div>
</template>

<script>
import Loading from '@components/Loading.vue'
import {mapState, mapActions} from 'vuex'

export default {
  name: 'datatable-index',
  components:{
    Loading,
  },
  computed:{
    ...mapState({
      selected: ({ headquarter }) => headquarter.selected,
      loading: ({ headquarter }) => headquarter.lifecicly.loading.showAll,
    }),
  },
  methods: {
    ...mapActions('headquarter', ['ShowAllHeadquarters', 'DeleteHeadquarters']),
  },
  mounted(){
    this.ShowAllHeadquarters({ type: "initTable" })
  },
}
</script>
