let validation = []
let validate = {
  fields: {
    // name: {
    //   validators: {
    //     notEmpty: {
    //       message: 'Ingresa el nombre de la sede'
    //     }
    //   }
    // },
    phone: {
      validators: {
        notEmpty: {
          message: 'Debe proporcionar un numero de telefono'
        }
      }
    },
    address: {
      validators: {
        notEmpty: {
          message: 'Porfavor ingresa una dirreccion'
        }
      }
    },
    country: {
      validators: {
        notEmpty: {
          message: 'Debe haber un pais'
        }
      }
    },
    // state: {
    //   validators: {
    //     notEmpty: {
    //       message: 'Debe haber un estado'
    //     }
    //   }
    // },
    // city: {
    //   validators: {
    //     notEmpty: {
    //       message: 'Debe haber una ciudad'
    //     }
    //   }
    // },
    postcode: {
      validators: {
        notEmpty: {
          message: 'Debe haber una ciudad'
        }
      }
    },
    // description: {
    //   validators: {
    //     notEmpty: {
    //       message: 'Porfavor pon una descripcion o reseña historica'
    //     }
    //   }
    // },
    institution_id: {
      validators: {
        notEmpty: {
          message: 'Este campo es obligatorio'
        }
      }
    },
  },
  plugins: {
    trigger: new FormValidation.plugins.Trigger(),
    // Bootstrap Framework Integration
    bootstrap: new FormValidation.plugins.Bootstrap({
      //eleInvalidClass: '',
      eleValidClass: '',
    })
  }
}
let formEl, _avatar

const errorUpdateForm = () => {
  Swal.fire({ //Notificacion de tipo de cuenta no registrada
    text: "No se pudo registrar la sede, intente en unos minutos",
    icon: "error",
    buttonsStyling: false,
    customClass: {
      confirmButton: "btn font-weight-bold btn-primary",
      cancelButton: "btn font-weight-bold btn-default"
    }
  })
}

const successUpdateForm = () => {
  Swal.fire({ //Notificacion de tipo de cuante correctamente
    text: "Sede Registrada!",
    icon: "success",
    buttonsStyling: false,
    customClass: {
      confirmButton: "btn font-weight-bold btn-primary",
      cancelButton: "btn font-weight-bold btn-default"
    }
  })
  $('#clean-input').trigger('click')
}

let KTContactsUpdate = function() {

  let _initValidation = function(CreateHeadquarter) {

    validation.push(FormValidation.formValidation(formEl, validate))

    $('#create-button-headquarter').on('click', function() {
      validation[0].validate().then(function(status) {
        if (status == 'Valid') {
          Swal.fire({
            text: "¡Todo es bueno! solo confirma",
            icon: "success",
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonText: "Registrar!",
            cancelButtonText: "No, cancelar",
            customClass: {
              confirmButton: "btn font-weight-bold btn-primary",
              cancelButton: "btn font-weight-bold btn-default"
            }
          }).then(() => {
            if (status == 'Valid') {
              CreateHeadquarter({
                successUpdateForm,
                errorUpdateForm,
              })
            }
          })
        }
      })
    })
  }

  return {
    init: function(CreateHeadquarter) {
      _initValidation(CreateHeadquarter)
    }
  }
}()

export default (CreateHeadquarter) => {
  $(function() {
    formEl = KTUtil.getById('create-form-headquarter')
    KTContactsUpdate.init(CreateHeadquarter)
  })
}

export const resetForm = () => validation[0].resetForm(true)
