<template>
  <Loading :active="loading" size="xl" :full="true"/>

  <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
    <div class="col-xl-12 col-xxl-7">
      <form class="form fv-plugins-bootstrap fv-plugins-framework" id="validate_register_study_program">

        <SearchInstitution @select="savedSelect">
          <Inputs/>

          <div class="d-flex justify-content-between border-top pt-10">
            <div>
              <button type="button" class="btn btn-success font-weight-bolder text-uppercase px-9 py-4" id="register-form-study-program">
                Aceptar
              </button>
            </div>
          </div>
        </SearchInstitution>

      </form>
    </div>
  </div>
</template>

<script>
import SearchInstitution from '@components/admin/institution/search/Search.vue'
import Inputs from './formRegister/Inputs.vue'
import Loading from '@components/Loading.vue'
import {mapState, mapActions} from 'vuex'

export default {
  name:'FormRegisterStudyProgram',
  components:{
    SearchInstitution,
    Loading,
    Inputs,
  },
  computed:{
    ...mapState({
      loading: ({institution}) => institution.lifecicly.loading.register
    })
  },
  methods:{
    ...mapActions('global', ['EditFieldState']),
    savedSelect(id){
      this.EditFieldState({
        state: 'studyProgram',
        type: 'newStudyProgram',
        field: 'institution_id',
        value: `${id}`,
      })
    }
  }
}
</script>
