import Guest from '@layouts/Guest.vue'

export default [
  /* Rutas de auteticación o desautenticación */
  {
    path: '/institution',
    component: Guest,
    /* Rutas a partir del path principal */
    children: [
      {
        path: 'profile/:id',
        name: 'profile-institution',
        component: async () => await require('@views/ProfileInstitution.vue'),
      },
    ],
  },
]
