<template>
  <div class="fixed block xl:hidden z-40" style="top:60vh; right:-18px;" id="btn-active-menu-social">
      <button class="bg-white py-2 px-2 shadow-xl rounded-full inline-flex items-center">
        <IconArrowRightTwo color="#28aae1" class="transform rotate-180"/>
      </button>
  </div>

  <div class="fixed xl:static max-w-xs w-54 xl:w-5/12 xl:block space-y-1 z-50 xl:z-0 bg-white xl:bg-transparent pt-0 xl:overflow-hidden overflow-scroll" style="height:93%; top:59px; right:-300px; min-width: 200px; margin:0px;" id="menu-filter-social">

    <div v-if="facebookBaner">
      <iframe :src="facebookBaner" width="300" height="400" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    </div>
  </div>

  <div class="fixed top-0 w-full h-full z-40 bg-black bg-opacity-40 hidden xl:hidden" id="bg-all-social"></div>
</template>

<script>
import IconArrowRightTwo from '@components/icons/ArrowRightTwo.vue'

import {mapState} from 'vuex'

const url = (name) => {
 return 'https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2F'+name+'&tabs=timeline&width=300&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId'
}
export default {
  data(){
    return {
      id:'',
    }
  },
  components:{
    IconArrowRightTwo,
  },
  computed:{
    ...mapState({
      $headquarters: ({ headquarter }) => headquarter.$headquarters,
    }),
    facebookBaner(){
      if (this.$headquarters[this.id]) {
        let social_m = this.$headquarters[this.id]?.social_media
        let fbName = social_m.facebook.replace('https://www.facebook.com/', '')
        return url(fbName)
      }
    },
  },
  mounted(){
    this.id = this.$route.params.id

    $(()=>{
      $('#bg-all-social').click(function(){
        $(this).removeClass('block')
        $(this).addClass('hidden')
        $('#menu-filter-social').css({right: '-300px', transition: 'right 0.4s'})
      })

      $('#btn-active-menu-social').click(function(){
        $('#bg-all-social').removeClass('hidden')
        $('#bg-all-social').addClass('block')
        $('#menu-filter-social').css({right: '0px', transition: 'right 0.4s'})
      })
    })
  },
  watch:{
    $route(to, from){
      this.id = to.params?.id
    }
  }
}
</script>
