<template>
<div class="pb-5">
  <div class="row">
    <div class="col-xl-12">
      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label">Nombre del tipo de cuenta</label>
        <div class="col-lg-9 col-xl-9">
          <input class="form-control form-control-lg form-control-solid" name="name" type="text" placeholder="Instituto" :value="typeAccount?.name" @input="UpdateFieldTypeAccount"/>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label">Precio Del tipo de cuenta</label>
        <div class="col-lg-9 col-xl-9">

          <div class="input-group">
            <span class="input-group-text">$</span>
            <input class="form-control form-control-lg form-control-solid" name="price" type="number" placeholder="0.00" :value="typeAccount?.price" @input="UpdateFieldTypeAccount" aria-label="US - dollar"/>
					</div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import {trim} from 'lodash'

export default {
  computed: {
    ...mapState({
      typeAccount: ({typeAccount}) => typeAccount.typeAccount
    }),
  },
  methods: {
    ...mapActions('global', ['EditFieldState', 'WatcherChangeInState']),

    UpdateFieldTypeAccount(e) {
      this.EditFieldState({
        state: 'typeAccount',
        type: 'typeAccount',
        field: e.target.name,
        value: trim(e.target.value),
      })
      this.WatcherChangeInState({
        state: 'typeAccount',
        type:{
          copy:'copyTypeAccount',
          mutated:'typeAccount',
        }
      })
    }
  },
};
</script>
