<template>
  <Loading :active="loading" size="xl" :full="true"/>

  <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
    <div class="col-xl-12 col-xxl-9">
      <form class="form" id="validate_update_study_program">

        <Inputs/>

        <div class="d-flex justify-content-between border-top pt-10">
          <button type="submit" class="btn btn-success font-weight-bolder text-uppercase px-9 py-4" id="update-form-study-program">
            Aceptar
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

import Inputs from './form/Inputs.vue'
import Loading from '@components/Loading.vue'

export default {
  name:'FormIputs',
  components:{
    Inputs,
    Loading
  },
  computed:{
    ...mapState({
      loading: ({typeAccount}) => typeAccount.lifecicly.loading.update
    })
  }
}
</script>
