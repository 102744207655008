<template>
<!-- <svg x="0px" y="0px" :width="size" :height="size" viewBox="0 0 172 172" :style="`fill:${color};`" class="d-inline">
  <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none"
    text-anchor="none" style="mix-blend-mode: normal">
    <path d="M0,172v-172h172v172z" fill="none"></path>
    <g :fill="color">
      <path
        d="M26.875,28.66667c-6.923,0 -12.54167,5.61867 -12.54167,12.54167l71.66667,44.79167l71.66667,-44.79167c0,-6.923 -5.61867,-12.54167 -12.54167,-12.54167zM14.33333,55.55566v73.44434c0,7.91917 6.41417,14.33333 14.33333,14.33333h114.66667c7.91917,0 14.33333,-6.41417 14.33333,-14.33333v-73.44434l-71.66667,44.77767z">
      </path>
    </g>
  </g>
</svg> -->

<svg x="0px" y="0px" :width="size" :height="size" viewBox="0 0 24 24" :style="`fill:${color};`" class="d-inline">
  <path
    d="M6.2 9C6.1 10 6 11 6 12s.1 2 .2 3H2.5C2.2 14 2 13 2 12s.2-2 .5-3H6.2zM15.8 9c.1.9.2 2 .2 3s-.1 2.1-.2 3H8.2C8.1 14.1 8 13 8 12s.1-2.1.2-3H15.8zM22 12c0 1-.2 2-.5 3h-3.7c.1-1 .2-2 .2-3s-.1-2-.2-3h3.7C21.8 10 22 11 22 12zM8.1 2.8C7.4 3.9 6.9 5.3 6.5 7H3.3C4.4 5.1 6.1 3.7 8.1 2.8zM15.4 7H8.6c.7-2.9 2-4.8 3.2-5 .1 0 .1 0 .2 0s.1 0 .2 0C13.4 2.2 14.7 4.1 15.4 7zM20.7 7h-3.2c-.4-1.7-.9-3.1-1.6-4.2C17.9 3.7 19.6 5.1 20.7 7zM6.5 17c.4 1.7.9 3.1 1.6 4.2-2-.9-3.6-2.3-4.7-4.2H6.5zM8.6 17h6.8c-.7 2.9-2 4.8-3.2 5-.1 0-.1 0-.2 0s-.1 0-.2 0C10.6 21.8 9.3 19.9 8.6 17zM20.7 17c-1.1 1.9-2.7 3.3-4.7 4.2.7-1.1 1.2-2.5 1.6-4.2H20.7z">
  </path>
</svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#000000',
    },
    size: {
      type: String,
      default: '24',
    }
  }
}
</script>
