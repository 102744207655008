<template>
  <div :class="['d-content-dashboard', scrollContent]">
    <slot></slot>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
        drawerToggle: ({ global }) => global.drawerToggle,
    }),

    scrollContent(){
      return this.drawerToggle ? 'active-drawer-content' : null
    },
  },
}
</script>

<style lang="css" scoped>
.d-content-dashboard{
  transition: padding 1s;
  padding-top: 65px;
  padding-left: 15px;
}
.active-drawer-content {
  transition: padding 1s;
  padding-left: 300px;
}
@media only screen and (max-width: 768px) {
  .active-drawer-content {
    transition: padding 1s;
    padding-left: 15px;
  }
}
</style>
