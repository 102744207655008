<template>
<div class="overflow-hidden" tabindex="-1" style="filter: brightness(0.5);">
    <div :style="style" class="object-cover uk-cover"></div>
</div>
</template>

<script>
import location from '@components/guest/Location.vue'
export default {
  components: {
    location
  },
  computed:{
    style(){
      return {
        backgroundImage: `url(${require('@assets/images/about.jpg')})`,
        width:'100%',
        height: '139px',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }
    }
  }
}
</script>
