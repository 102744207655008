<template>
  <header uk-sticky>
    <div class="header_inner">
      <div class="left-side">

        <!-- icon menu for mobile -->
        <div class="triger md:invisible" uk-toggle="target: .header_menu; cls: is-visible; animation: uk-animation-fade" id="menu-click-nav"></div>

        <!-- menu bar for mobile -->
        <nav class="header_menu pt-10 md:p-0 md:-mt-1">
          <ul>
            <li class="md:mr-20 pl-5 pb-5 md:p-0 ">
              <img src="@assets/images/logo-mini.png" class="-mt-2">
            </li>
            <li>
              <router-link :to="{ name:'index' }" class="font-weight-bold" @click="topDestination">
                Home
              </router-link>
            </li>
            <li>
              <router-link :to="{ name:'about' }" class="font-weight-bold" @click="topDestination">
                About Us
              </router-link>
            </li>
            <li>
              <router-link :to="{ name:'destinations' }" class="font-weight-bold" @click="topDestination">
                Destinations
              </router-link>
            </li>
            <li>
              <router-link :to="{ name:'contact' }" class="font-weight-bold" @click="topDestination">
                Contact
              </router-link>
            </li>
          </ul>
        </nav>

        <!-- overly for small devices -->
        <div class="overly" uk-toggle="target: .header_menu ; cls: is-visible; animation: uk-animation-fade"></div>

      </div>
      <div class="right-side">

        <!-- cart -->
        <a href="#" class="header_widgets">
          <IconTranslate/>
        </a>
        <div uk-drop="mode: click" class="header_dropdown uk-drop uk-drop-bottom-right" style="left: 858.5px; top: 5.5px;">
          <ul class="dropdown_scrollbar" data-simplebar="init">
            <div class="simplebar-wrapper" style="margin: 0px;">
              <div class="simplebar-height-auto-observer-wrapper">
                <div class="simplebar-height-auto-observer"></div>
              </div>
              <div class="simplebar-mask">
                <div class="simplebar-offset" style="right: -15px; bottom: 0px;">
                  <div class="simplebar-content" style="padding: 0px; height: auto; overflow: hidden scroll;">
                    <li>
                      <a href="#">
                        <div class="drop_content">
                          <IconFlagSpain/>
                          <strong> Spanish </strong>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <div class="drop_avatar">
                        </div>
                        <div class="drop_content">
                          <IconFlagEnglish/>
                          <strong> English </strong>
                        </div>
                      </a>
                    </li>
                  </div>
                </div>
              </div>
              <div class="simplebar-placeholder" style="width: 208px; height: 482px;"></div>
            </div>
            <div class="simplebar-track simplebar-horizontal" style="visibility: hidden;">
              <div class="simplebar-scrollbar" style="transform: translate3d(0px, 0px, 0px); visibility: hidden;"></div>
            </div>
            <div class="simplebar-track simplebar-vertical" style="visibility: visible;">
              <div class="simplebar-scrollbar" style="transform: translate3d(0px, 0px, 0px); visibility: visible; height: 284px;"></div>
            </div>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import IconTranslate from '@components/icons/Translate.vue'
import IconFlagSpain from '@components/icons/FlagSpain.vue'
import IconFlagEnglish from '@components/icons/FlagEnglish.vue'
export default {
  components:{
    IconTranslate,
    IconFlagSpain,
    IconFlagEnglish
  },
  methods:{
    topDestination() {
      $( "#btn-top" ).trigger( "click" );
      $( "#menu-click-nav" ).trigger( "click" );
    },
  }
}
</script>

<style lang="css" scoped>
.router-link-exact-active{
    color: #05a4e8;
}
@media (max-width: 560px){
  .header_menu.is-visible {
    width: 150px;
  }
}

@media (max-width: 768px){
  .header_menu.is-visible {
    width: 270px !important;
  }
}
</style>
