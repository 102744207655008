// import countries from '@services/data/location/countriesWithStatesAndCities.js'
import { kebabCase } from 'lodash'

let resolveImage = (name) => {
    return require(`@assets/images/destinations/${name}.jpg`)
}

let url = (name) => {
  return ({
    name: 'destination-search',
    params: {
    name: kebabCase(name),
    },
  })
}

export default [{
    id: 232,
    title: 'Usa',
    image: resolveImage('usa'),
    url: url('united-states'),
  },
  {
    id: 38,
    title: 'Canada',
    image: resolveImage('canada'),
    url: url('canada'),
  },
  {
    id: 231,
    title: 'United Kingdom',
    image: resolveImage('united-kingdom'),
    url: url('united-kingdom'),
  },
  {
    id: 44,
    title: 'China',
    image: resolveImage('china'),
    url: url('china'),
  },
  {
    id: 74,
    title: 'France',
    image: resolveImage('france'),
    url: url('france'),
  },
  {
    id: 105,
    title: 'Italy',
    image: resolveImage('italy'),
    url: url('italy'),
  },
  {
    id: 80,
    title: 'Germany',
    image: resolveImage('germany'),
    url: url('germany'),
  },
  {
    id: 103,
    title: 'Ireland',
    image: resolveImage('ireland'),
    url: url('ireland'),
  },
  {
    id: 134,
    title: 'Malta',
    image: resolveImage('malta'),
    url: url('malta'),
  },
  {
    id: 203,
    title: 'South Africa',
    image: resolveImage('south-africa'),
    url: url('south-africa'),
  },
  {
    id: 13,
    title: 'Australia',
    image: resolveImage('australia'),
    url: url('australia'),
  },
  {
    id: 157,
    title: 'New Zealand',
    image: resolveImage('new-zealand'),
    url: url('new-zealand'),
  },
]
