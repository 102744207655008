/* Api: Url base y opciones */
import Api from '@services/api'

export default {

  /* Obtenemos un dato de la db */
  OneInstitution: async (params, next, end) => {

    /* Consumo de la endpoint */
    const resp = await Api.get(`/institution/${params.id}`) /*
    Obtenemos los datos de manera asincrona */

    /* Manejo de resultados */
    try { // Si todo va bien
      if(!!next) { /* si la funcion 'next' existe se ejecuta lo siguiente */
        let isEmptyData = Object.keys(resp.data).length === 0 /*
        Comprobamos que trae un objeto que no esta vacio */

        next(resp.data, !isEmptyData) /* Pasamos la data,
        y un valor que indique que no esta vacia la data */
      }
    }
    catch(e) { // En caso de errores
      console.error(`Error: Services institution - OneInstitution:`, e) /*
      En caso de error mostrar en que modulo y servicio */

      /* si la funcion 'end' existe se ejecuta */
      if(!!end) end("error")
    }
    finally{ // Siempre que finalize hara lo siguiente
      /* si la funcion 'end' existe se ejecuta */
      if(!!end) end("finally")
    }
  },
  /*****--------------------------------------------******/

  /* Obtenemos todos los datos de la db */
  AllInstitutions: async (next, end) => {

    /* Consumo de la endpoint */
    const resp = await Api.get('/institution/all') /*
    Obtenemos los datos de manera asincrona */

    /* Manejo de resultados */
    try { // Si todo va bien
      if(!!next) { /* si la funcion 'next' existe se ejecuta lo siguiente */
        let isEmptyData = resp.data.length < 0 /* Verificamos que el array
        o datos no este vacio */

        next(resp.data, !isEmptyData) /* Pasamos la data,
        y un valor que indique que no esta vacia la data */
      }
    }
    catch(e) { // En caso de errores
      console.error(`Error: Services institution - AllInstitutions:`, e) /*
      En caso de error mostrar en que modulo y servicio */

      /* si la funcion 'end' existe se ejecuta */
      if(!!end) end("error")
    }
    finally{  // Siempre que finalize hara lo siguiente
      /* si la funcion 'end' existe se ejecuta */
      if(!!end) end("finally")
    }
  },
  /*****--------------------------------------------******/

  /* Registramos un nuevo dato en la db */
  CreateInstitution: async (data, next, end) => {

    /* Consumo de la endpoint */
    const resp = await Api.post('/institution/create', data) /*
    Obtenemos los datos de manera asincrona */

    /* Manejo de resultados */
    try { // Si todo va bien
      if(!!next) { /* si la funcion 'next' existe se ejecuta lo siguiente */
        let isEmptyData = Object.keys(resp.data).length === 0 /*
        Comprobamos que trae un objeto que no esta vacio */

        next(resp.data, !isEmptyData) /* Pasamos la data,
        y un valor que indique que no esta vacia la data */
      }
    }
    catch(e) { // En caso de errores
      console.error(`Error: Services institution - CreateInstitution:`, e) /*
      En caso de error mostrar en que modulo y servicio */

      /* si la funcion 'end' existe se ejecuta */
      if(!!end) end("error")
    }
    finally{ // Siempre que finalize hara lo siguiente
      /* si la funcion 'end' existe se ejecuta */
      if(!!end) end("finally")
    }
  },
  /*****--------------------------------------------******/

  /* Actualizamos un dato de la db */
  UpdateInstitution: async ({params, data}, next, end) => {

    /* Consumo de la endpoint */
    const resp = await Api.post(`/institution/update/${params.id}`, data) /*
    Obtenemos los datos de manera asincrona */

    /* Manejo de resultados */
    try { // Si todo va bien
      if(!!next) { /* si la funcion 'next' existe se ejecuta lo siguiente */
        let isEmptyData = Object.keys(resp.data).length === 0 /*
        Comprobamos que trae un objeto que no esta vacio */

        next(resp.data, !isEmptyData) /* Pasamos la data,
        y un valor que indique que no esta vacia la data */
      }
    }
    catch(e) { // En caso de errores
      console.error(`Error: Services institution - UpdateInstitution:`, e) /*
      En caso de error mostrar en que modulo y servicio */

      /* si la funcion 'end' existe se ejecuta */
      if(!!end) end("error")
    }
    finally{ // Siempre que finalize hara lo siguiente
      /* si la funcion 'end' existe se ejecuta */
      if(!!end) end("finally")
    }
  },
  /*****--------------------------------------------******/

  /* Eliminamos un dato de la db */
  DeleteInstitution: async (params, next, end) => {

    /* Consumo de la endpoint */
    const resp = await Api.delete(`/institution/delete/${params.id}`) /*
    Obtenemos los datos de manera asincrona */

    /* Manejo de resultados */
    try { // Si todo va bien
      if(!!next) { /* si la funcion 'next' existe se ejecuta lo siguiente */
        let isEmptyData = resp.data.length < 0 /* Verificamos que el array
        o datos no este vacio */

        next(resp.data, !isEmptyData) /* Pasamos la data,
        y un valor que indique que no esta vacia la data */
      }
    }
    catch(e) { // En caso de errores
      console.error(`Error: Services institution - DeleteInstitution:`, e) /*
      En caso de error mostrar en que modulo y servicio */

      /* si la funcion 'end' existe se ejecuta */
      if(!!end) end("error")
    }
    finally{ // Siempre que finalize hara lo siguiente
      /* si la funcion 'end' existe se ejecuta */
      if(!!end) end("finally")
    }
  },
}
