<template>
<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" :width="size" :height="size" viewBox="0 0 172 172" :style="`fill:${color};`">
  <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none"
    text-anchor="none" style="mix-blend-mode: normal">
    <path d="M0,172v-172h172v172z" fill="none"></path>
    <g>
      <path
        d="M136.16667,17.91667l-86,-0.00717c-7.90842,0 -17.25733,3.827 -17.85933,21.50717h-0.05733c0,0 0,71.036 0,100.33333l0.0645,-0.1935c0.65217,13.03975 9.12675,14.52683 12.86058,14.52683h83.82492c0.67367,0 1.79167,0 3.58333,0c7.16667,0 7.16667,-7.16667 7.16667,-7.16667v-21.49283v-103.92383c0,-1.98158 -1.60175,-3.58333 -3.58333,-3.58333z"
        fill="#7e57c2"></path>
      <path d="M129,129h-83.82492c-4.773,0 -5.75842,4.00975 -5.75842,8.95833c0,4.95217 0.98542,8.9655 5.75842,8.9655l83.82492,-0.00717z" fill="#ffe0b2"></path>
      <path d="M45.17508,129c-4.18175,0 -5.45383,3.0745 -5.70825,7.16667h89.53317v-7.16667z" fill="#e0b990"></path>
      <path d="M50.16667,39.4095h75.25v17.91667h-75.25z" fill="#ffecb3"></path>
      <path d="M136.16667,129h-7.16667v25.08333c0.67367,0 1.79167,0 3.58333,0c7.16667,0 7.16667,-7.16667 7.16667,-7.16667v-21.49283c0,1.97442 -1.60175,3.57617 -3.58333,3.57617z" fill="#311b92"></path>
    </g>
  </g>
</svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#000000',
    },
    size: {
      type: String,
      default: '24',
    }
  }
}
</script>
