/* Utiliades y ayudantes */
import { keyBy } from '@tools/util'
import { orderBy } from 'lodash'

/* Servicios de consumo de api */
import Api from '@services/modules/currency'

/* Funcion para iniciar la dataTable */
const initTable = (state, payload) => {
  const {commit, dispatch} = payload //Extraemos

  /* Preguntamos si el tipo es 'initTable' para
  posteriomente montar la tabla */
  if (payload.type == "initTable") {
    tableCurrency(
      {commit, dispatch}, /* funciones para
      llamar a mutations y actions*/
      { currencies: state.currencies } /* Datos
      para mostrar en tabla */
    )
  }
}

export default {
  namespaced: true, //hace que sea global el modulo en vuex
  state: () => ({
    lifecicly:{ //Ciclo de vida del componente
      mounted:{ //Cuando se monta
        /* Hacen referencia a las actions */
        showAll:false,
        show:false,
      },
    },
    watchCurrency:null, // cambio echo actual
    currencies: [], //todos
    $currencies: {}, /* $ -> significa que esto sera
    un objeto, para acceder rapidamente a las propiedades
    sin afectar el rendimiendo con un find, map, o forEach */
  }),
  getters:{
    /* Seleccion actual de un id */
    selectCurrency: state => currentId => {
      /* Si no hay un cambio en la variable 'watchCurrency' entonces
       es igual al id actual, de lo contrario si no es nulo entonces
       es un id seleccionado en 'watchCurrency' */
      let id = state.watchCurrency == null ? currentId : state.watchCurrency
      return state.$currencies[id]?.code /* Retornamos la moneda con el id */
    }
  },
  mutations: {
    /* Llamamos varios datos para almacenar y visualizar dichos datos */
    SHOW_ALL_CURRENCIES(state, payload){
      if (!state.lifecicly.mounted.showAll) {

        state.lifecicly.mounted.showAll = true /* establecemos
        en 'true' para que se sepa que ya la data esta, y no
        hay que volver a ejecutar un llamado a la Api */

        /*LLamado a la Api */
        Api.AllCurrencies(
          /* Obtenemos la data y un validador */
          (data, /*validate*/) => {
            state.currencies = orderBy(data, ['title'],['asc']) /*
            Insertamos la data y la ordenamos por el titulo */
            state.$currencies = keyBy(data, 'id') /* Insertamos
            la data transformandola en objeto y con el id como llave
            por defecto -> $objeto['numero_de_id'].propiedad */

            initTable(state, payload) /* Instanciamos la dataTable
            e insertamos los datos */
          }
        )
      } else initTable(state, payload) /* Instanciamos la dataTable
      e insertamos los datos aun si esta vacia la data */
    },
    /*****----------------------------------------------------*****/
    
  },
  actions: {
    /* Llamamos a todos los datos */
    ShowAllCurrencies({ commit, dispatch }, payload) {
      /* Payload -> type == "initTable" o null */
      commit('SHOW_ALL_CURRENCIES', { commit, dispatch, ...payload })
    },
  },
}
