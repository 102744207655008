<template>
    <Loading :active="loading" :full="true">
      <div id="wrapper" class="horizontal relative" style="min-height: 100vh;">
        <Header />
        <router-view v-slot="{ Component }">
          <keep-alive>
            <div class="pb-20">
              <component :is="Component"/>
            </div>
          </keep-alive>
        </router-view>
        <Footer />
      </div>
      <button id="btn-top" class="top-destination btn-top rounded-full bg-one p-2 invisible">
        <IconArrowTop color="white"/>
      </button>
      <button id="top-destination" class="btn-top invisible">Dest</button>
    </Loading>
</template>

<script>
import { useHead } from '@vueuse/head'
import { ref } from 'vue'

import Header from '@components/guest/Header.vue'
import Footer from '@components/guest/Footer.vue'
import IconArrowTop from '@components/icons/ArrowTop.vue'
import Loading from '@components/Loading.vue'

export default {
  components: {
    Loading,
    IconArrowTop,
    Header,
    Footer,
  },
  setup(){
    const loading = ref(true)

    useHead({
      link: [
        { href: '/css/icons.min.css', rel: 'stylesheet' },
        { href: '/css/style.min.css', rel: 'stylesheet' },
        { href: '/css/uikit.min.css', rel: 'stylesheet' },
        { href: '/css/tailwind.min.css', rel: 'stylesheet' },
      ],
      script:[
        { src:'js/uikit.min.js' },
        { src:'js/tippy.all.min.js' },
        { src:'js/simplebar.min.js' },
        { src:'js/custom.min.js' },
      ]
    })

    setTimeout(() => {
      loading.value = false
    }, 1500)

    return {
      loading,
    }
  }
}
</script>

<style scoped>
  .btn-top{
    position: fixed;
    bottom: 10px;
    right: 15px;
  }
</style>
