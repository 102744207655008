<template>
<div class="container mt-10">
  <div class="container d-flex align-items-center flex-wrap flex-sm-nowrap">
    <!--begin::Info-->
    <div class="d-flex align-items-center flex-wrap mr-1">
      <!--begin::Page Heading-->
      <div class="d-flex align-items-baseline flex-wrap mr-5">
        <!--begin::Page Title-->
        <h5 class="text-dark font-weight-bold my-1 mr-5">Home</h5>
        <!--end::Page Title-->
        <!--begin::Breadcrumb-->
        <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">
          <li class="breadcrumb-item text-muted">
            <span href="#" class="text-muted">Admin</span>
          </li>
          <li class="breadcrumb-item text-muted">
            <span href="#" class="text-muted">Dashboard</span>
          </li>
          <li class="breadcrumb-item text-muted">
            <span href="#" class="text-muted">Index</span>
          </li>
        </ul>
        <!--end::Breadcrumb-->
      </div>
      <!--end::Page Heading-->
    </div>
  </div>
  <div class="row mt-5">

    <div class="col-xl-3">
      <div class="card card-custom bgi-no-repeat card-stretch gutter-b" :style="imageStyle">
        <router-link :to="{ name:'index-institution' }" tag="div" class="card-body">
          <span>
            <IconInstitution size="34px" />
          </span>
          <span class="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block">
            {{count.institution}}
          </span>
          <span class="font-weight-bold text-muted text-xl">
            View Institutions
          </span>
        </router-link>
        <router-link :to="{ name:'register-institution' }" tag="div" class="card-body d-flex flex-column flex-grow-1 border border-two">

          <span class="card-title font-weight-bolder text-dark-75 font-size-h5 mb-2 text-hover-danger">Register Institution </span>
          <span class="font-weight-bold text-muted font-size-lg">Create new Institution</span>
        </router-link>
      </div>
    </div>

    <div class="col-xl-3">
      <div class="card card-custom bgi-no-repeat card-stretch gutter-b" :style="imageStyle">
        <router-link :to="{ name:'index-headquarter' }" tag="div" class="card-body">
          <span>
            <IconHeadquarter size="34px" />
          </span>
          <span class="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block">
            {{count.headquarter}}
          </span>
          <span class="font-weight-bold text-muted text-xl">
            View Headquarters
          </span>
        </router-link>
        <router-link :to="{ name:'register-headquarter' }" tag="div" class="card-body d-flex flex-column flex-grow-1 border border-two">

          <span class="card-title font-weight-bolder text-dark-75 font-size-h5 mb-2 text-hover-danger">Register Headquarter </span>
          <span class="font-weight-bold text-muted font-size-lg">Create new Headquarter</span>
        </router-link>
      </div>
    </div>

    <div class="col-xl-3">
      <div class="card card-custom bgi-no-repeat card-stretch gutter-b" :style="imageStyle">
        <router-link :to="{ name:'index-study-program' }" tag="div" class="card-body">
          <span>
            <IconStudyProgram size="34px" />
          </span>
          <span class="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block">
            {{count.studyProgram}}
          </span>
          <span class="font-weight-bold text-muted text-xl">
            View Study Programs
          </span>
        </router-link>
        <router-link :to="{ name:'register-study-program' }" tag="div" class="card-body d-flex flex-column flex-grow-1 border border-two">

          <span class="card-title font-weight-bolder text-dark-75 font-size-h5 mb-2 text-hover-danger">Create Study Programs </span>
          <span class="font-weight-bold text-muted font-size-lg">Add new Study Programs</span>
        </router-link>
      </div>
    </div>

  </div>
</div>
</template>

<script>
import Api from '@services/modules/count.js'
import IconInstitution from '@components/icons/colors/Institution.vue'
import IconHeadquarter from '@components/icons/colors/Headquarter.vue'
import IconStudyProgram from '@components/icons/colors/StudyProgram.vue'


export default {
  name: 'Home',
  components: {
    IconInstitution,
    IconHeadquarter,
    IconStudyProgram,
  },
  data() {
    return {
      count: {},
    }
  },
  computed: {
    imageStyle() {
      return {
        backgroundPosition: 'right top',
        backgroundSize: '30% auto',
        backgroundImage: `url(${require('@assets/images/abstract-1.svg')})`,
        boxShadow: '0.2px 0.2px 2px',
      }
    }
  },
  methods: {
    async callCount() {
      await Api.Count(data => this.count = data)
    },
  },
  mounted() {
    this.callCount()
  }
}
</script>
