<template>
    <div :class="sizesBackground" v-show="active">
      <div class="animate-spiner" :class="sizeLoading"></div>
    </div>
    <slot></slot>
</template>

<script>
export default {
  name: 'Loading',
  props:{
    /* Opcion para habilitar o deshabilitado el loading */
    active:{
      type:Boolean,
      default:true,
    },

    /* Opción de tamaño del loading */
    size:{
      type:String,
      default: 'md',
    },

    /* Mostrar el loading tapando todo de fondo
    con una capa transparente oscura y el loading
    en el medio */
    full:{
      type:Boolean,
      default:false,
    }
  },
  computed:{

    /* Propiedad computada para modificar el
    tamaño del loading */
    sizeLoading(){
       switch (this.size) {
        case 'sm': return 'spinner-sm';

        case 'md': return 'spinner-md';

        case 'xl': return 'spinner-xl';

        case 'lg': return 'spinner-lg';
      }
    },

    /* Propiedad computada para mostrar
    en pantalla completa el loading */
    sizesBackground(){
      switch (this.full) {
        case true: return 'spinner-full';

        case false: return 'spinner-expand';
      }
    }
  }
}
</script>

<style>
  @keyframes spiner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .spinner-content{
    width: 100%;
    height: 100;
    display: flex;
    justify-content:center;
  }
  .spinner-full {
    background-color: white;
    justify-content: center;
    align-items: center;
    display: flex;
    position:fixed;
    z-index: 9000;
    height:100%;
    width: 100%;
    top:0;
    left:0;
  }

  .spinner-sm{
    height: 20px;
    width: 20px;
  }
  .spinner-md{
    height: 30px;
    width: 30px;
  }
  .spinner-lg{
    height: 40px;
    width: 40px;
  }
  .spinner-xl{
    height: 50px;
    width: 50px;
  }
  .spinner-2xl{
    height: 50px;
    width: 50px;
  }
  .animate-spiner	{
    animation: spiner 1s linear infinite;
    border: 3px solid #28ADE4;
    border-top-color:transparent;
    justify-items: center;
    border-radius: 50%;
  }
</style>
