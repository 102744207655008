<template>
  <li class="menu-item menu-item-submenu" :class="{'menu-item-active': subMenu}" data-menu-toggle="hover" aria-haspopup="true" >
    <a href="#" class="menu-link menu-toggle">
      <span class="svg-icon menu-icon">
        <i class="la la-th-list"></i>
      </span>
      <span class="menu-text">Tipos de cuenta</span>
      <i class="menu-arrow"></i>
    </a>
    <div class="menu-submenu menu-submenu-classic menu-submenu-right">
      <ul class="menu-subnav">
        <template v-for="(menu, index) in menu.typeAccount" :key="index">
          <router-link custom :to="{name:menu.to}" v-slot="{ navigate, href, isExactActive }">
            <li class="menu-item"
            :class="{'menu-item-active': subMenu==false ? subMenu=isExactActive: isExactActive }" aria-haspopup="true">
              <a :href="href" @click="navigate"  class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{menu.title}}</span>
              </a>
            </li>
          </router-link>
        </template>
      </ul>
    </div>
  </li>
</template>

<script>
export default {
  data(){
    return {
      subMenu:false,
      menu:{
        typeAccount:[
          {
            title: 'Ver los tipos de cuentas',
            to: 'index-type-account',
          },
          {
            title: 'Crear nuevo tipo de cuenta',
            to: 'add-type-account',
          },
        ],
      },
    }
  },
  watch: {
     $route() {
       this.subMenu = false
     }
  },
}
</script>
