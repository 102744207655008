import {upperFirst} from 'lodash'

import {
  baseTb,
  searchModify,
  countElements,
  exportsFunctions,
  multiSelectAndDelete,
  updateAndDeleteButtons
} from '@options/tables/index.js'

let renderField = (data, field) => {
  return (`
    <div class="d-flex align-items-center">
      ${ data[field] }
    </div>
  `)
}

let name = 'typeInstitution', tb = null, status = false

/* tbClass = '${name}_table' */

let initTable = (tbClass, actions, data) => {

  status = true
  tb =baseTb({
      name,
      tbClass: tbClass,
      data: data.typeInstitutions,
      options: {
        columns: [
          {
            title: 'Nombre', data: 'name'
          },
        ],
      }
    })
  countElements(tb)
  searchModify(tb, name)
  multiSelectAndDelete({tb, tbClass, actions, state: name })
  updateAndDeleteButtons({tb, tbClass, actions, state: name })
}

const getTb = () => tb
const getStatus = () => status

let getExport = exportsFunctions({
  initTable,
  getStatus,
  getTb,
  name,
})

getExport[`getTb${upperFirst(name)}`] = getTb

export default getExport
