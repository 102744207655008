<template>
  <HeaderBackground/>
  <BestDestinations/>
</template>

<script>
import BestDestinations from '@components/guest/destinations/BestDestinations.vue'
import HeaderBackground from '@components/guest/HeaderBackground.vue'

export default {
  components:{
    HeaderBackground,
    BestDestinations,
  }
}
</script>
