<template>
<svg x="0px" y="0px" :width="size" :height="size" viewBox="0 0 172 172" :style="`fill:${color};`">
  <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none"
    text-anchor="none" style="mix-blend-mode: normal">
    <path d="M0,172v-172h172v172z" fill="none"></path>
    <g :fill="color">
      <path
        d="M100.33333,0c-19.78717,0 -35.83333,16.04617 -35.83333,35.83333c0,8.09117 2.78447,15.45951 7.30664,21.45801l-0.13997,0.04199l28.66667,50.16667l28.66667,-50.16667l-0.13997,-0.04199c4.52217,-5.9985 7.30664,-13.36684 7.30664,-21.45801c0,-19.78717 -16.04617,-35.83333 -35.83333,-35.83333zM100.33333,21.5c7.91917,0 14.33333,6.41417 14.33333,14.33333c0,7.91917 -6.41417,14.33333 -14.33333,14.33333c-7.91917,0 -14.33333,-6.41417 -14.33333,-14.33333c0,-7.91917 6.41417,-14.33333 14.33333,-14.33333zM21.5,86c-11.87412,0 -21.5,9.62588 -21.5,21.5c0,11.87412 9.62588,21.5 21.5,21.5c5.56239,-0.00688 10.90564,-2.16926 14.90723,-6.03288l35.41341,17.7067c-0.74777,8.02537 1.91758,15.99477 7.34299,21.95554c5.42541,5.96076 13.10957,9.36211 21.1697,9.37064c7.99066,-0.01008 15.61449,-3.35487 21.03297,-9.22776c5.41848,-5.8729 8.1398,-13.74085 7.50772,-21.70648l19.94629,-7.97852c2.5207,1.98823 5.63591,3.07265 8.84635,3.07943c7.91608,0 14.33333,-6.41725 14.33333,-14.33333c0,-7.91608 -6.41725,-14.33333 -14.33333,-14.33333c-7.11845,0.0107 -13.15043,5.24398 -14.16536,12.28972l-19.30241,7.71257c-5.30317,-8.00578 -14.26264,-12.82445 -23.86556,-12.83561c-9.43499,0.01151 -18.26019,4.66489 -23.59961,12.44368l-33.91569,-16.96484c0.75796,-6.11308 -1.14253,-12.25765 -5.21947,-16.87533c-4.07694,-4.61767 -9.93868,-7.26486 -16.09857,-7.27018z">
      </path>
    </g>
  </g>
</svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#000000',
    },
    size: {
      type: String,
      default: '24',
    }
  }
}
</script>
