<template>
  <div class="subheader py-2 py-lg-4 subheader-transparent" id="kt_subheader">
    <div class="container d-flex align-items-center flex-wrap flex-sm-nowrap">
      <div class="d-flex align-items-center flex-wrap mr-2">

        <router-link :to="{name:'index-institution'}" class="font-weight-bold" tag="a">
          Regresar
        </router-link>
        <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">New Contact</h5>
        <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>
        <div class="d-flex align-items-center" id="kt_subheader_search">
          <span class="text-dark-50 font-weight-bold" id="kt_subheader_total">Enter contact details and submit</span>
        </div>
      </div>
    </div>
  </div>
</template>
