import { computed, watchEffect } from 'vue'
import axios from 'axios';
import store from '@store'
import { getMenyPersisten } from '@tools/util'

/* URL base del consumo del backend */
const ENDPOINT = 'https://you-worldwide.com'


/* Obtenemos el token que hay en la session */
 const token = getMenyPersisten({ access_token:'' }).access_token

/* Creamos una endpoint para acceder a los metodos
con la url lista y opciones adicionales */

export default axios.create({
  baseURL: `${ENDPOINT}/api/v1`,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  },
})

/* Exportamos la 'endpoint' para usar
en cualquier otra parte de la app */
export const endpoint = ENDPOINT
