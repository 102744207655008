<template>
  <svg x="0px" y="0px" :width="size" :height="size" viewBox="0 0 172 172" :style="`fill:${color};`" class="d-inline">
    <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal">
      <path d="M0,172v-172h172v172z" fill="none"></path>
      <g id="original-icon" :fill="color">
        <path d="M93.16667,21.5l-43,64.5l43,64.5h28.66667l-43,-64.5l43,-64.5z"></path>
      </g>
    </g>
  </svg>
</template>
<script>
  export default {
    props:{
      color:{
        type:String,
        default: '#000000',
      },
      size:{
        type:String,
        default: '24',
      }
    }
  }
</script>
