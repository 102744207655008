<template>

<div class="form-group row">
  <label class="col-xl-3 col-lg-3 col-form-label">Pais</label>
  <div class="col-lg-9 col-xl-9">
    <select name="country" class="form-control form-control-lg form-control-solid country" @input="inputCountry" :value="headquarter[typeStore]?.country">
      <option hidden selected value="" class="hidd">Seleciona un pais...</option>
      <option v-for="(obj, key, index) in countries" :key="index" :value="obj.name" :data-dat="key">
        {{obj.name}}
      </option>
    </select>
    <div class="fv-plugins-message-container"></div>
  </div>
</div>

<div class="form-group row" v-show="!modeUpdate ? states : true">
  <label class="col-xl-3 col-lg-3 col-form-label">Estado</label>
  <div class="col-lg-9 col-xl-9">
    <select name="state" class="form-control form-control-lg form-control-solid" :value="headquarter[typeStore]?.state" @input="inputState">
      <!-- <option hidden selected value="">Seleciona un estado...</option> -->
      <option v-for="(obj, key, index) in states" :key="index" :value="obj.name" :data-dat="obj.cities">
        {{obj.name}}
      </option>
      <option v-if="notSelectState" hidden selected
      :value="headquarter[typeStore]?.state">Seleciona un estado...</option>
    </select>
    <div class="fv-plugins-message-container"></div>
  </div>
</div>

<div class="form-group row" v-show="!modeUpdate ? cities : true">
  <label class="col-xl-3 col-lg-3 col-form-label">Ciudad</label>
  <div class="col-lg-9 col-xl-9">
    <select name="city" class="form-control form-control-lg form-control-solid" :value="headquarter[typeStore]?.city" @input="inputCity">
      <!-- <option hidden selected value="">Seleciona una ciudad...</option> -->
      <option v-for="(city, index) in cities" :key="index" :value="city">
        {{city}}
      </option>
      <option v-if="notCities" hidden selected
      :value="headquarter[typeStore]?.city">No hay ciudades disponibles</option>
      <option v-if="notSelectCity" hidden selected
      :value="headquarter[typeStore]?.city">Seleciona una ciudad...</option>
    </select>
    <div class="fv-plugins-message-container"></div>
  </div>
</div>
</template>

<script>
/* Objeto de paises, estados y ciudades */
//import locations from '@services/data/location/countriesWithStatesAndCities.js'

/* Utilidades y ayudantes */
import { mapState, mapActions } from 'vuex'
import { keyBy } from '@tools/util.js'
import { watchEffect } from 'vue'
import { trim } from 'lodash'

export default {
  data() {
    return {
      countries:{},
      cities:false, // 'false' para que no muestre nada
      states:false, // 'false' para que no muestre nada
      notCities:false,
      notSelectCity:false,
      notSelectState:false,
    }
  },
  props:{
    /* Hacemos refrencia a los primeros elemento del store,
    por eso es 'typeStore' tipo de store, ya que toma de headquarter
    el campo que necesite por ejemplo headquarter.newHeadquarter */
    typeStore: {
      type:String,
      default:'headquarter',
    },

    /* Opcion para detectar un cambio */
    modeUpdate:{
      type:Boolean,
      default:false,
    }
  },
  computed: {
    /* Llamamos a N cantidad de modulos del store */
    ...mapState({
      headquarter: ({headquarter}) => headquarter
    }),
  },
  methods: {
    /* Llamamos a N cantidad de acciones del store */
    ...mapActions('global', ['EditFieldState', 'WatcherChangeInState']),

    /* Funcion para detectar si hay un id y mostrar el
    pais con el estado y la cuidad */
    moauntedSelects(){

      let country = this.headquarter[this.typeStore]?.country /*
      Obtenemos el id del pais si existe */

      let state = this.headquarter[this.typeStore]?.state /*
      Obtenemos el estado si existe */
      let city = this.headquarter[this.typeStore]?.city /*
      Obtenemos la ciudad si existe */

      /* Iteramos sobre los paises */
      for (const key in this.countries){
        let $country = this.countries[key] /* Obtenemos el pais
        con el id */

        /* Si el pais es igual a lo que esta seleccionado */
        if ($country.name == country){

          /* Iteramos sobre el estado del pais seleccionado */
          for (const keySt in $country.states){
            this.states = $country.states /* Guardamos el estado */
            let $state = $country.states[keySt] /* Obtenemos el estado
            con las ciudades */

            /* Preguntamos si el estado es igual al seleccionado */
            if (state == $state.name){
              /* Si hay ciudades continua */

              if ($state.cities.length) {

                if ($state.cities.includes(city)){
                  this.notSelectCity = false
                } else this.notSelectCity = true

                this.cities = $state.cities /* Guardamos las ciudades */
                this.notCities = false
              } else this.notCities = true

            }
          }
        }

      }
    },
    /* Activar comparacion al actualizar */
    activeComparedUpdate(){
      /* Si contiene algo se ejecuta */
      if(this.modeUpdate){

        /* Enviamos la modificacion al state para
        mostrar que hay un cambio y verificar */
        this.WatcherChangeInState({
          state: 'headquarter',
          type:{
            copy:'copyHeadquarter',
            mutated: 'headquarter',
          }
        })
      }
    },

    /* Funcion para obtener un pais al volver
    a seleccionar de nuevo */
    inputCountry(e){
      /* Obtenemos el 'id' del pais seleccionado */
      const id = e.target.selectedOptions[0].dataset.dat

      this.notSelectState = true
      /* Preguntamos si no esta vacio el dato que
      contiene los estado para luego mostrarlos
      una ves seleccionado el pais */
      if(locations[id]?.states.length > 0){


        /* Guardamos los estados del pais selccionado */
        this.states = locations[id]?.states

        /* guardamos el pais seleccionado en el state */
        this.EditFieldState({
          state: 'headquarter',
          type: this.typeStore,
          field: e.target.name,
          value: trim(e.target.value),
        })

        /* Llamamos para denotar que hay cambios echos */
        this.activeComparedUpdate()
      }
      else this.states = false /* De lo contrario 'false'
        para no mostrar nada en el selector */
    },

    /* Funcion para obtener un estado al volver
    a seleccionar de nuevo */
    inputState(e){
      this.notSelectState = false
      /* Obtenemos las ciudades de ese estado */
      const cities = e.target.selectedOptions[0].dataset.dat.split(',')
      let state = this.headquarter[this.typeStore].state
      /* Si el array de ciudades no esta
      vacio entonces ejecuta */

      if(e.target.value == state) {
        this.notSelectCity = false
      } else {
        this.notCities = false
        this.notSelectCity = true
      }

      if (cities[0] != '') {
        this.notCities = false
        this.cities = cities /* Guardamos las ciudades */
      }
      else {
        this.cities = false /* De lo contrario 'false'
        para no mostrar nada en el selector */
        this.notSelectCity = false
        this.notCities = true
      }

      /* guardamos el estado seleccionado en el state */
      this.EditFieldState({
        state: 'headquarter',
        type: this.typeStore,
        field: e.target.name,
        value: trim(e.target.value),
      })

      /* Llamamos para denotar que hay cambios echos */
      this.activeComparedUpdate()
    },

    /* Funcion para obtener una ciudad al volver
    a seleccionar de nuevo */
    inputCity(e) {

      /* guardamos el estado seleccionado en el state */
      this.EditFieldState({
        state: 'headquarter',
        type: this.typeStore,
        field: e.target.name,
        value: trim(e.target.value),
      })

      /* Llamamos para denotar que hay cambios echos */
      this.activeComparedUpdate()
    }
  },
  async mounted(){
    this.countries = await axios.get('/locations/countriesStatesCities.json')

    /* Si es 'true' iniciando, se ejecuta */
    if (this.modeUpdate){
      watchEffect(()=>{
        if(this.headquarter[this.typeStore]?.country){
          this.moauntedSelects()
        }
      })
    }
  },
  watch:{
    $route(){
      /* Si hay cambios en la ruta vuelve a ejecutarse de 0 */
      if (this.modeUpdate){
        /* Esperamos  800 milisegundos para ejecutar */
        setTimeout(()=> this.moauntedSelects(), 800)
      }
    }
  }
}
</script>
