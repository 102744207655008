let validation = []
let validate = {
	fields: {
		name: {
			validators: {
				notEmpty: {
					message: 'Porfavor ingresa el nombre del intituto'
				}
			}
		},
		price: {
			validators: {
				notEmpty: {
					message: 'Debe proporcionar un precio'
				}
			}
		},
	},
	plugins: {
		trigger: new FormValidation.plugins.Trigger(),
		// Bootstrap Framework Integration
		bootstrap: new FormValidation.plugins.Bootstrap({
			//eleInvalidClass: '',
			eleValidClass: '',
		})
	}
}
let formEl

let KTContactsUpdate = function() {

  let _initValidation = function(UpdateTypeAccount) {

		validation.push(FormValidation.formValidation(formEl, validate))

    $('#update-form-type-account').on('click', function () {
	    validation[0].validate().then(function(status) {
				const differentUpdateForm = () => {
					Swal.fire({ //Notificacion de institución registrada correctamente
            text: "No hay datos modificados",
            icon: "warning",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn font-weight-bold btn-primary",
              cancelButton: "btn font-weight-bold btn-default"
            }
          })
				}
				const errorUpdateForm = ()=>{
					Swal.fire({ //Notificacion de institución no registrada
						text: "No se pudo Actualizar el tipo de cuenta, intente en unos minutos",
						icon: "error",
						buttonsStyling: false,
						customClass: {
							confirmButton: "btn font-weight-bold btn-primary",
							cancelButton: "btn font-weight-bold btn-default"
						}
					})
				}
				const successUpdateForm = ()=>{
					$("#btnDeleteLogo").trigger("click")

					Swal.fire({ //Notificacion de institución registrada correctamente
						text: "Tipo de cuenta actualizada!",
						icon: "success",
						buttonsStyling: false,
						customClass: {
							confirmButton: "btn font-weight-bold btn-primary",
							cancelButton: "btn font-weight-bold btn-default"
						}
					})
				}
	      if (status == 'Valid') {
					UpdateTypeAccount({
						successUpdateForm,
						errorUpdateForm,
						differentUpdateForm
					})
	      }
	    })
    })
  }

  return {
    init: function(UpdateTypeAccount) {
      _initValidation(UpdateTypeAccount)
    }
  }
}()

export default (UpdateTypeAccount) => {
  $(function() {
    formEl = KTUtil.getById('validate_update_type_account')
    KTContactsUpdate.init(UpdateTypeAccount)
  })
}

export const resetForm = () => validation[0].resetForm(true)
