let KTTypeahead = function() {
  let demo2 = (input, data, functions) => {

    let institutions = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      local: data,
    })

    let institutionsTwo = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('alias'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      local: data,
    })

    input.typeahead(
      {
        hint: true,
        highlight: true,
        minLength: 1,
        limit:10,
      },
      {
        limit: 10,
        name: 'institution',
        source: institutions,
        display: Handlebars.compile('{{name}} – {{alias}}'),
        templates: {
          header: '<div><h5 class="league-name">Nombre de institucion:</h5></div>',
          suggestion: Handlebars.compile('<div><strong>{{name}}</strong> – {{alias}}</div>')
        }
      },
      {
        limit: 10,
        name: 'institution',
        source: institutionsTwo,
        display: Handlebars.compile('{{name}} – {{alias}}'),
        templates: {
          header: '<div><h5 class="league-name">Alias de institucion:</h5></div>',
          suggestion: Handlebars.compile('<div><strong>{{name}}</strong> – {{alias}}</div>')
        }
      },
    )

    const activeSelection = (e, data) => {
      functions.inputValue(e)
      setTimeout(() => {
        functions.selectInstituionId(
          data.id, `${data.name} – ${data.alias}`
        )
      }, 300)
    }

    input.bind('typeahead:select', function(e, data) {
      activeSelection(e, data)
    })

    input.bind('typeahead:autocomplete', function(e, data) {
      activeSelection(e, data)
    })

    $('#clean-input').click(()=> {
      input.typeahead('val','');
      let e = { target: { value: '' } }
      functions.inputValue(e)
    })
  }

  return {
    init: function(input, data, functions) {
      demo2(input, data, functions);
    }
  }
}()

export default (data, functions) => {
  let input = $('#kt_typeahead_search')
  $(function() {
    KTTypeahead.init(input, data, functions);
  })
}
