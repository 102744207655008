<template>
  <HeaderBackground />
  <FormContac />
</template>

<script>
import HeaderBackground from '@components/guest/HeaderBackground.vue'
import FormContac from '@components/guest/contact/Form.vue'
export default {
  components: {
    HeaderBackground,
    FormContac,
  },
}
</script>
