<template>
<svg x="0px" y="0px" :width="size" :height="size" viewBox="0 0 172 172" :style="`fill:${color};`" class="d-inline">
  <g transform="">
    <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal">
      <path d="M0,172v-172h172v172z" fill="none"></path>
      <path d="" fill="none"></path>
      <g :fill="color">
        <path d="M71.66667,42.49609l-10.75,10.75l32.7539,32.75391l-32.7539,32.7539l10.75,10.75l43.5039,-43.5039z"></path>
      </g>
    </g>
  </g>
</svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#000000',
    },
    size: {
      type: String,
      default: '24',
    }
  }
}
</script>
