let validation = [], formEl, _avatar
let validate = {
  fields: {
    name: {
      validators: {
        notEmpty: {
          message: 'Ingresa el nombre del tipo de institucion'
        }
      }
    },
  },
  plugins: {
    trigger: new FormValidation.plugins.Trigger(),
    // Bootstrap Framework Integration
    bootstrap: new FormValidation.plugins.Bootstrap({
      //eleInvalidClass: '',
      eleValidClass: '',
    })
  }
}

const errorRegisterForm = () => {
  Swal.fire({ //Notificacion de tipo de institucion no registrada
    text: "No se pudo registrar el tipo de institucion, intente en unos minutos",
    icon: "error",
    buttonsStyling: false,
    customClass: {
      confirmButton: "btn font-weight-bold btn-primary",
      cancelButton: "btn font-weight-bold btn-default"
    }
  })
}
const successRegisterForm = () => {
  Swal.fire({ //Notificacion de tipo de cuante correctamente
    text: "Tipo de institucion actualizada!",
    icon: "success",
    buttonsStyling: false,
    customClass: {
      confirmButton: "btn font-weight-bold btn-primary",
      cancelButton: "btn font-weight-bold btn-default"
    }
  })
}

let KTContactsRegister = function() {

  let _initValidation = function(CreateTypeInstitution) {

    validation.push(FormValidation.formValidation(formEl, validate))

    $('#create-form-type-institution').on('click', function() {
      validation[0].validate().then(function(status) {
        if (status == 'Valid') {
          Swal.fire({
            text: "¡Todo es bueno! solo confirma",
            icon: "success",
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonText: "Registrar!",
            cancelButtonText: "No, cancelar",
            customClass: {
              confirmButton: "btn font-weight-bold btn-primary",
              cancelButton: "btn font-weight-bold btn-default"
            }
          }).then(() => {
            if (status == 'Valid') {
              CreateTypeInstitution({
                successRegisterForm,
                errorRegisterForm,
              })
            }
          })
        }
      })
    })
  }

  return {
    init: function(CreateTypeInstitution) {
      _initValidation(CreateTypeInstitution)
    }
  }
}()

export default (CreateTypeInstitution) => {
  $(function() {
    formEl = KTUtil.getById('create_form_type_institution')
    KTContactsRegister.init(CreateTypeInstitution)
  })
}

export const resetForm = () => validation[0].resetForm(true)
