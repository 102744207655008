import { authenticared } from '@middlewares/authenticated.js'
import Dashboard from '@layouts/Dashboard.vue'

export default [
  /* Ruta de modulo programas de estido */
  {
    path: '/admin/dashboard/modules/study/program',
    component: Dashboard,
     /* Ruta de modulo tipos de cuentas*/
     children: [
       {
         path: '', /* Ruta por defecto '/' pero no se coloca el slash */
         name: 'index-study-program',
         component: async () => await require("@views/admin/studyProgram/Index.vue"),
         beforeEnter: [authenticared], /* middlewares */
       },
       {
         path: 'register',
         name: 'register-study-program',
         component: async () => await require("@views/admin/studyProgram/Register.vue"),
         beforeEnter: [authenticared], /* middlewares */
       },
       {
         path: 'update/:id',
         name: 'update-study-program',
         component: async () => await require("@views/admin/studyProgram/Update.vue"),
         beforeEnter: [authenticared], /* middlewares */
       },
     ]
  }
]
