<template>
<div class="pb-5">
  <div class="row">
    <div class="col-xl-12">
      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label">Nombre del tipo de institucion</label>
        <div class="col-lg-9 col-xl-9">
          <input class="form-control form-control-lg form-control-solid" name="name" type="text" placeholder="Nuevo tipo de institucion" :value="typeInstitution.name" @input="UpdateFieldTypeInstitution"/>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
/* Funciones de formRegister */
import {resetForm} from '@components/admin/typeInstitution/register/formRegister.js'

/* Utilidades y ayudantes */
import {mapState, mapActions} from 'vuex'
import {trim} from 'lodash'

export default {
  computed: {
    /* Llamamos a N cantidad de modulos del store */
    ...mapState({
      typeInstitution: ({typeInstitution}) => typeInstitution.newTypeInstitution
    }),
  },
  methods: {
    /* Llamamos a N cantidad de acciones del store */
    ...mapActions('global', ['EditFieldState']),

    /* Funcion para actualizar el input cuando se hacen cambios */
    UpdateFieldTypeInstitution(e) {

      /* Guarda cambios en el store */
      this.EditFieldState({
        state: 'typeInstitution',
        type:'newTypeInstitution',
        field: e.target.name,
        value: trim(e.target.value),
      })
    }
  },
  deactivated(){
    /* Cada vez que haya un cambio de
    ruta resea el form */
    resetForm()
  }
}
</script>
