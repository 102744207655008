<template>
  <svg x="0px" y="0px" :width="size" :height="size" :style="`fill:${color};`" viewBox="0 0 172 172" class="d-inline">
    <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal">
      <path d="M0,172v-172h172v172z" fill="none"></path>
      <g>
        <path d="M2.15,27.95h167.7v116.1h-167.7z" fill="#f78f8f"></path>
        <path d="M167.7,30.1v111.8h-163.4v-111.8h163.4M172,25.8h-172v120.4h172v-120.4z" fill="#c74343"></path>
        <path d="M51.5957,38.7h116.1v8.6h-116.1zM51.5957,60.2h116.1v8.6h-116.1zM4.2957,103.2h163.4v8.6h-163.4zM4.3086,81.7h163.4v8.6h-163.4zM4.3086,124.7h163.4v8.6h-163.4z" fill="#ffffff"></path>
        <path d="M2.15,27.95h81.7v60.2h-81.7z" fill="#8bb7f0"></path>
        <path d="M81.7,30.1v55.9h-77.4v-55.9h77.4M86,25.8h-86v64.5h86v-64.5z" fill="#4e7ab5"></path>
        <path
          d="M10.75,30.1l1.9952,4.2484l4.4548,0.6794l-3.225,3.3067l0.7611,4.6655l-3.9861,-2.2016l-3.9861,2.2016l0.7611,-4.6655l-3.225,-3.3067l4.4548,-0.6794zM23.65,43l1.9952,4.2484l4.4548,0.6794l-3.225,3.3067l0.7611,4.6655l-3.9861,-2.2016l-3.9861,2.2016l0.7611,-4.6655l-3.225,-3.3067l4.4548,-0.6794zM36.55,30.1l1.9952,4.2484l4.4548,0.6794l-3.225,3.3067l0.7611,4.6655l-3.9861,-2.2016l-3.9861,2.2016l0.7611,-4.6655l-3.225,-3.3067l4.4548,-0.6794zM49.45,43l1.9952,4.2484l4.4548,0.6794l-3.225,3.3067l0.7611,4.6655l-3.9861,-2.2016l-3.9861,2.2016l0.7611,-4.6655l-3.225,-3.3067l4.4548,-0.6794zM62.35,30.1l1.9952,4.2484l4.4548,0.6794l-3.225,3.3067l0.7611,4.6655l-3.9861,-2.2016l-3.9861,2.2016l0.7611,-4.6655l-3.225,-3.3067l4.4548,-0.6794zM10.75,55.9l1.9952,4.2484l4.4548,0.6794l-3.225,3.3067l0.7611,4.6655l-3.9861,-2.2016l-3.9861,2.2016l0.7611,-4.6655l-3.225,-3.3067l4.4548,-0.6794zM36.55,55.9l1.9952,4.2484l4.4548,0.6794l-3.225,3.3067l0.7611,4.6655l-3.9861,-2.2016l-3.9861,2.2016l0.7611,-4.6655l-3.225,-3.3067l4.4548,-0.6794zM62.1952,55.9516l1.9909,4.2484l4.4591,0.6794l-3.225,3.3067l0.7611,4.6655l-3.9861,-2.2016l-3.9904,2.2016l0.7654,-4.6655l-3.225,-3.3067l4.4548,-0.6794zM23.65,68.8l1.9952,4.2484l4.4548,0.6794l-3.225,3.3067l0.7611,4.6655l-3.9861,-2.2016l-3.9861,2.2016l0.7611,-4.6655l-3.225,-3.3067l4.4548,-0.6794zM49.45,68.8l1.9952,4.2484l4.4548,0.6794l-3.225,3.3067l0.7611,4.6655l-3.9861,-2.2016l-3.9861,2.2016l0.7611,-4.6655l-3.225,-3.3067l4.4548,-0.6794zM75.25,68.8l1.9952,4.2484l4.4548,0.6794l-3.225,3.3067l0.7611,4.6655l-3.9861,-2.2016l-3.9861,2.2016l0.7611,-4.6655l-3.225,-3.3067l4.4548,-0.6794zM75.25,43l1.9952,4.2484l4.4548,0.6794l-3.225,3.3067l0.7611,4.6655l-3.9861,-2.2016l-3.9861,2.2016l0.7611,-4.6655l-3.225,-3.3067l4.4548,-0.6794z"
          fill="#ffffff"></path>
      </g>
    </g>
  </svg>
</template>
<script>
  export default {
    props:{
      color:{
        type:String,
        default: '#000000',
      },
      size:{
        type:String,
        default: '20',
      }
    }
  }
</script>
