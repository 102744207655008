/* Importaciones de los modulos */
import typeInstitution from '@routes/admin/modules/typeInstitution.js'
import studyProgram from '@routes/admin/modules/studyProgram.js'
import typeAccount from '@routes/admin/modules/typeAccount.js'
import institution from '@routes/admin/modules/institution.js'
import headquarter from '@routes/admin/modules/headquarter.js'
import dashboard from '@routes/admin/modules/dashboard.js'
import study from '@routes/admin/modules/study.js'
import login from '@routes/admin/login.js'

/* Importaciones de otras opciones */
import erros from '@routes/errors.js'
import index from '@routes/guest/index.js'
import destinations from '@routes/guest/destinations.js'
import institutions from '@routes/guest/institutions.js'


/* Adjuntamos todo para hacer un solo array d objetos,
con todas las rutas */
export default [
  /* ... esto se conoce como spread operator */
  ...erros,

  /* Admin */
  ...dashboard,
  ...typeInstitution,
  ...studyProgram,
  ...institution,
  ...typeAccount,
  ...headquarter,
  ...study,
  ...login,

  /* Invitados */
  ...destinations,
  ...institutions,
  ...index,
]
