<template lang="html">
  <svg x="0px" y="0px" :width="size" :height="size" viewBox="0 0 172 172" :style="`fill:${color};`" class="d-inline">
    <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal">
      <path d="M0,172v-172h172v172z" fill="none">
      </path>
      <g :fill="color">
        <path d="M159.3795,132.23217l-63.04517,-104.88417c-2.20017,-3.66217 -6.063,-5.848 -10.33433,-5.848c-4.27133,0 -8.13417,2.18583 -10.32717,5.84083l-63.05233,104.89133c-2.236,3.7195 -2.29333,8.37067 -0.15767,12.1475c2.1285,3.77683 6.149,6.12033 10.492,6.12033h126.0975c4.33583,0 8.3635,-2.3435 10.492,-6.12033c2.13567,-3.77683 2.07117,-8.428 -0.16483,-12.1475zM93.16667,129h-14.33333v-14.33333h14.33333zM93.16667,100.33333h-14.33333v-35.83333h14.33333z">
        </path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#000000',
    },
    size: {
      type: String,
      default: '24',
    }
  }
}
</script>
