<template>
  <select :name="name" @input="$emit('input', $event)" :value="value"
    class="form-control form-control-lg form-control-solid">
    <option hidden selected value="">Seleciona una cuenta...</option>
    <option :value="0" v-if="!headquartersById.length">Sin sede</option>
    <option :value="headquarter.id" v-for="(headquarter, index) in headquartersById" :key="index" v-else>
      {{headquarter.name}}
    </option>
  </select>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {
  name:'SelectTypeAccount',
  props:{
    value:{
      type:String,
      default:'',
    },
    name:{
      type:String,
      default:'',
    },
    id:{
      type:String||Number,
      default:'',
    }
  },
  computed:{
    ...mapState({
      headquarters: ({ headquarter }) => headquarter.headquarters,
    }),
    headquartersById(){
      if (this.id) {
        return this.headquarters.filter((headquarter) => headquarter.id == this.id)
      }
      else return this.headquarters
    },
  },
  methods: {
    ...mapActions('headquarter', ['ShowAllHeadquarters']),

  },
  mounted(){
    this.ShowAllHeadquarters()
  },
};
</script>
