<template>
<div class="form-group row">
  <label class="col-xl-3 col-lg-3 col-form-label">Gallery of images</label>
  <div class="col-lg-9 col-xl-9">
    <div class="dropzone dropzone-default dropzone-success dz-clickable">
      <div class="dropzone-msg dz-message needsclick" @click="clickImages">
        <h3 class="dropzone-msg-title">click to upload.</h3>
        <span class="dropzone-msg-desc">Only image are allowed for upload</span>
        <input type="file" accept=".jpg, .jpeg, .png" multiple @change="showPreview" id="uploadGallery" class="hidden">
      </div>
      <div class="dz-preview dz-processing dz-error dz-complete dz-image-preview" v-for="(image, index) in images" :key="index" v-if="images.length > 0">
        <div class="dz-image">
          <img data-dz-thumbnail="" alt="photo_2020-05-21_15-08-31.jpg" :src="image.url">
        </div>
        <div class="dz-details">
          <IconClose size="30px" color="red" class="btn-delete-image" @click="removeImage(index)"/>

          <div class="dz-size"><span data-dz-size=""><strong>{{ parseInt(image.size/1024) }}</strong> KB</span></div>
          <div class="dz-filename"><span data-dz-name="">{{image.name}}</span></div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import IconClose from '@components/icons/Close.vue'
import { mapActions } from 'vuex'

export default {
  components:{
    IconClose,
  },
  data() {
    return {
      images: [],
      imgStore: []
    };
  },
  methods: {
    ...mapActions('global', ['EditTypeState']),

    clickImages() {
      $('#uploadGallery').trigger('click');
    },

    showPreview(event) {
      if (event.target.files.length > 0) {

        let images = Array.from(event.target.files)

        images.map((file) => {
          let url = URL.createObjectURL(file);
          let arr = {}

          for (let an in file) {
            arr[an] = file[an]
          };

          this.images.push({ url, ...arr })
          this.imgStore.push(file)
        })

        this.EditTypeState({
          state: 'headquarter',
          type: 'gallery',
          value: this.imgStore,
        })
      }
    },
    removeImage(index){
      this.images.splice(index, 1)
      this.imgStore.splice(index, 1)

      this.EditTypeState({
        state: 'headquarter',
        type: 'gallery',
        value: this.imgStore,
      })
    }
  },
}
</script>

<style scoped>
.btn-delete-image{
  position: absolute;
  top: -22px;
  right: -22px;
  z-index: 200;
  cursor: pointer;
}
</style>
