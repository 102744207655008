<template>
<div class="pb-5">
  <div class="row">
    <div class="col-xl-12">
      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label">Titutlo del estudio</label>
        <div class="col-lg-9 col-xl-9">
          <input class="form-control form-control-lg form-control-solid" name="title" type="text" placeholder="Estudio" :value="study?.title" @input="UpdateFieldStudy"/>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label">Descripción</label>
        <div class="col-lg-9 col-xl-9">
          <textarea id="desc_study" style="height: 125px" class="form-control form-control-lg form-control-solid" name="description" placeholder="Descripción del estudio" :value="study?.description" @input="UpdateFieldStudy"></textarea>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { textEditor } from '@options/textEditor'
import {mapState, mapActions} from 'vuex'
import {trim} from 'lodash'

export default {
  computed: {
    ...mapState({
      study: ({study}) => study.study
    }),
  },
  methods: {
    ...mapActions('global', ['EditFieldState', 'WatcherChangeInState']),

    UpdateFieldStudy(e) {
      this.EditFieldState({
        state: 'study',
        type: 'study',
        field: e.target.name,
        value: trim(e.target.value),
      })
      this.WatcherChangeInState({
        state: 'study',
        type:{
          copy:'copyStudy',
          mutated:'study',
        }
      })
    }
  },
  mounted(){
    textEditor('desc_study')
  }
};
</script>
