<template>
<svg x="0px" y="0px" :width="size" :height="size" viewBox="0 0 172 172" :style="`fill:${color};`" class="d-inline">
  <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal">
    <path d="M0,172v-172h172v172z" fill="none"></path>
    <g id="original-icon" fill="#34495e" :fill="color">
      <path
        d="M13.76,3.44c-5.65719,0 -10.32,4.66281 -10.32,10.32v89.44c0,5.65719 4.66281,10.32 10.32,10.32h44.72v44.72c0,5.65719 4.66281,10.32 10.32,10.32h89.44c5.65719,0 10.32,-4.66281 10.32,-10.32v-89.44c0,-5.65719 -4.66281,-10.32 -10.32,-10.32h-44.72v-44.72c0,-5.65719 -4.66281,-10.32 -10.32,-10.32zM148.8875,4.4075l-17.2,17.2l-2.365,2.4725l2.365,2.4725l17.2,17.2l4.945,-4.945l-14.7275,-14.7275l14.7275,-14.7275zM13.76,10.32h89.44c1.90813,0 3.44,1.53188 3.44,3.44v46.655l-1.3975,1.505l4.8375,4.8375l1.505,-1.3975h46.655c1.90813,0 3.44,1.53188 3.44,3.44v89.44c0,1.90813 -1.53187,3.44 -3.44,3.44h-89.44c-1.90812,0 -3.44,-1.53187 -3.44,-3.44v-46.655l1.3975,-1.505l-4.8375,-4.8375l-1.505,1.3975h-46.655c-1.90812,0 -3.44,-1.53187 -3.44,-3.44v-89.44c0,-1.90812 1.53188,-3.44 3.44,-3.44zM55.04,27.52v6.88h-27.52v6.88h45.2575c-0.7525,8.21031 -6.93375,15.96375 -14.405,22.36c-3.38625,-2.00219 -6.665,-4.32687 -9.03,-6.88c-2.75469,-2.95625 -4.3,-5.95281 -4.3,-9.245h-6.88c0,5.4825 2.62031,10.19906 6.1275,13.975c2.43219,2.62031 5.2675,4.85094 8.2775,6.7725c-9.86312,7.01438 -19.565,11.18 -19.565,11.18l2.795,6.235c0,0 10.75,-4.48812 21.6075,-12.3625c0.60469,-0.44344 1.22281,-0.94062 1.8275,-1.3975c7.78031,3.92375 14.835,6.02 14.835,6.02l1.8275,-6.5575c0,0 -4.91812,-1.55875 -10.8575,-4.3c7.57875,-6.88 13.975,-15.52031 14.62,-25.8h9.7825v-6.88h-27.52v-6.88zM100.405,66.7575l-4.8375,4.73l4.945,4.945l4.73,-4.8375zM90.8375,76.325l-4.8375,4.8375l4.8375,4.8375l4.8375,-4.8375zM109.435,85.6775l-23.1125,58.8025h7.955l6.5575,-17.845h24.725l6.5575,17.845h8.6l-23.1125,-58.8025zM81.1625,86l-4.8375,4.73l4.945,4.945l4.73,-4.8375zM113.1975,92.5575l9.9975,27.52h-19.995zM71.595,95.5675l-4.8375,4.8375l4.8375,4.8375l4.8375,-4.8375zM19.6725,128.2475l-4.945,4.945l14.7275,14.7275l-14.7275,14.7275l4.945,4.945l17.2,-17.2l2.365,-2.4725l-2.365,-2.4725z">
      </path>
    </g>
  </g>
</svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#34495e',
    },
    size: {
      type: String,
      default: '24',
    }
  }
}
</script>
