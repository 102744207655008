<template>
<div class="uk-position-relative uk-visible-toggle overflow-hidden mb-8 lg:-mt-20 uk-slideshow" tabindex="-1" uk-slideshow="animation: scale ;min-height: 200; max-height: 605; autoplay: true" style="filter: brightness(0.5);">

  <ul class="uk-slideshow-items rounded" style="min-height: 500px;">
    <li tabindex="-1" class="" style="">
      <div class="uk-position-cover" uk-slideshow-parallax="scale: 1.2,1.2,1" style="transform: scale(1);">
        <div class="absolute w-full h-3/4 -bottom-12 from-transparent z-10">
        </div>
        <img src="@assets/images/1.jpg" class="object-cover uk-cover" alt="" uk-cover="" style="height: 500px; width: 2224px;">
      </div>
    </li>
    <li tabindex="-1" class="uk-active uk-transition-active">
      <div class="uk-position-cover" uk-slideshow-parallax="scale: 1.2,1.2,1" style="transform: scale(1.2);">
        <div class="absolute w-full h-3/4 -bottom-12 from-transparent z-10">
        </div>
        <img src="@assets/images/2.jpg" class="object-cover uk-cover" alt="" uk-cover="" style="height: 500px; width: 2224px;">
      </div>
    </li>
  </ul>
</div>

<div class="point">
  <img src="@assets/images/logo-trans-400B.png" class="image-logo">
  <h2 class="portada-text">
    Choose your destination <br>
    <span style="color: #fff;">Study abroad</span>
  </h2>
  <div class="location container mx-auto p-4">
    <div class="flex justify-content-center justify-items-center gap-0">
      <div class="p-0 m-0 w-4/5 sm:w-3/5 md:w-2/5 lg:w-1/2">
        <location @select="selectCountry" />
      </div>
      <div class="sm:col-2 md:col-1 p-0 m-0">
        <button type="reset" class="w-full bg-one text-white font-bold rounded-r-lg" style="padding:11.5px" @click="goDestinationSearch">
          Search
        </button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import location from '@components/guest/Location.vue'
import {kebabCase, isEmpty} from 'lodash'

export default {
  components: {
    location
  },
  data() {
    return {
      countryUrl: '',
    }
  },
  methods: {
    selectCountry({ target }) {
      this.countryUrl = JSON.parse(target.value)
    },
    goDestinationSearch() {
      if(!isEmpty(this.countryUrl)) {
        this.$router.push(this.countryUrl)
        $( "#btn-top" ).trigger( "click" );
      }
    },
  }
}
</script>

<style scoped>

.portada-text{
  margin: 0px auto;
  font-size: 2rem;
  color: #05a4e8;
  font-weight: bold;
}
.point{
  position: absolute;
  width: 100%;
  top: 17vh;
  display: block;
  text-align: center;
}
.image-logo{
  display: inline;
  width: 240px;
}

@media (max-width: 1024px) {
  .point{
    top: calc(16vw - 3%);
  }
}
@media (max-width: 640px) {
  .portada-text{
    font-size: calc(1em + 1vw);
  }
  .image-logo{
    width: calc(35vw - 2%);
  }
}
@media (max-width: 570px) {
  .point{
    top: calc(30vw - 3%);
  }
}
</style>
