<template>
<div class="pb-5">
  <div class="row">
    <div class="col-xl-12">

      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label">Sede</label>
        <div class="col-lg-9 col-xl-9">
          <SelectHeadquarter name="headquarter_id"  @input="UpdateFieldStudyProgram"
          :id="studyProgram?.institution_id"/>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label">Estudio</label>
        <div class="col-lg-9 col-xl-9">
          <SelectStudy name="study_id" :value="studyProgram?.study_id" @input="updateStudy"/>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label">Moneda</label>
        <div class="col-lg-9 col-xl-9">
          <select name="currency_id" class="form-control form-control-lg form-control-solid"   :value="studyProgram?.currency_id" @input="updateCurrency">
            <option hidden selected value="">Seleciona una moneda...</option>
            <option :value="currency?.id" v-for="(currency, index) in currencies">
              {{currency.title}} - {{currency.code}}
            </option>
          </select>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label">Precio de suscripción.</label>
        <div class="col-lg-9 col-xl-9">

          <div class="input-group">
						<div class="input-group-prepend">
							<span class="input-group-text">{{selectCurrency(studyProgram?.currency?.id)}}</span>
						</div>
            <input class="form-control form-control-lg" name="registration_price" placeholder="0.00" :value="studyProgram?.registration_price" @input="UpdateFieldStudyProgram"/>
					</div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label">Precio de envio.</label>
        <div class="col-lg-9 col-xl-9">

          <div class="input-group">
						<div class="input-group-prepend">
							<span class="input-group-text">{{selectCurrency(studyProgram?.currency?.id)}}</span>
						</div>
            <input class="form-control form-control-lg" name="shipping_price" placeholder="0.00" :value="studyProgram?.shipping_price" @input="UpdateFieldStudyProgram"/>
					</div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label">Seleccionar una modalidad</label>
        <div class="col-lg-9 col-xl-9">
          <select name="modality" class="form-control form-control-lg form-control-solid"
            :value="studyProgram?.modality" @input="UpdateFieldStudyProgram">
            <option hidden selected value="">Seleciona el Idioma...</option>
            <option value="paquetes">Paquetes</option>
            <option value="rangos">Rangos</option>
          </select>
          <div class="fv-plugins-message-container"></div>
        </div>
      </div>

      <AddTime/>

    </div>
  </div>
</div>
</template>

<script>
import {resetForm} from '@components/admin/studyProgram/register/formRegister.js'
import {mapState, mapActions, mapGetters} from 'vuex'
import {trim} from 'lodash'

import SelectHeadquarter from '@components/admin/headquarter/Select.vue'
import SelectStudy from '@components/admin/study/Select.vue'
import AddTime from '@components/admin/time/AddTime.vue'

export default {
  components:{
    SelectHeadquarter,
    SelectStudy,
    AddTime,
  },
  computed: {
    ...mapState({
      studyProgram: ({studyProgram}) => studyProgram.newStudyProgram,
      currencies: ({currency}) => currency?.currencies,
      studies: ({study}) => study?.studies,
    }),
    ...mapGetters('currency', ['selectCurrency']),
  },
  methods: {
    ...mapActions('global', ['EditFieldState', 'EditTypeState', 'WatcherChangeInState']),
    ...mapActions('currency', ['ShowAllCurrencies']),

    UpdateFieldStudyProgram(e) {
      this.EditFieldState({
        state: 'studyProgram',
        type: 'newStudyProgram',
        field: e.target.name,
        value: trim(e.target.value),
      })
    },
    updateCurrency(e){
      this.EditFieldState({
        state: 'studyProgram',
        type: 'newStudyProgram',
        field: e.target.name,
        value: trim(e.target.value),
      })

      this.EditTypeState({
        state: 'currency',
        type: 'watchCurrency',
        value: trim(e.target.value),
      })
    },
    updateStudy(e){
      /*Actualizar con el store de study*/
      this.EditFieldState({
        state: 'studyProgram',
        type: 'newStudyProgram',
        field: 'study_id',
        value: trim(e.target.value),
      })
    },
  },
  deactivated(){
    resetForm()
  },
  mounted(){
    this.ShowAllCurrencies()
  },
};
</script>
