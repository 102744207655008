import {upperFirst} from 'lodash'

import {
  baseTb,
  searchModify,
  countElements,
  exportsFunctions,
  multiSelectAndDelete,
  updateAndDeleteButtons
} from '@options/tables/index.js'

let name = 'typeAccount', tb = null, status = false

/* tbClass = '${name}_table' */

let renderField = (data, field) => {
  return (`
    <div class="d-flex align-items-center">
      ${ data[field] }
    </div>
  `)
}

let initTable = (tbClass, actions, data) => {

  status = true
  tb = baseTb({
      name,
      tbClass: tbClass,
      data: data.typeAccounts,
      options: {
        columns: [
          { title: 'Nombre', data: 'name' },
          { title: 'Precio', data: 'price' },
        ],
        columnDefs: [
          {
            targets: 3,
            title: 'Precio',
            data: 'price',
            render: function(data) {
              return (`
                <div class="d-flex align-items-center">
                  ${ data }$
                </div>
              `)
            },
          },
        ]
      }
    })
  countElements(tb)
  searchModify(tb, name)
  multiSelectAndDelete({tb, tbClass, actions, state: name })
  updateAndDeleteButtons({tb, tbClass, actions, state: name })
}

const getTb = () => tb
const getStatus = () => status

let getExport = exportsFunctions({
  initTable,
  getStatus,
  getTb,
  name,
})

getExport[`getTb${upperFirst(name)}`] = getTb

export default getExport
