<template>
<div class="header-bottom" id="sticker">
  <div class="container">
    <Drawer />
    <div class="d-none d-lg-flex align-items-center">
      <div class="quick-search quick-search-inline ml-4 w-250px" id="kt_quick_search_inline">
        <form method="get" class="quick-search-form">
          <div class="input-group rounded">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <span class="svg-icon svg-icon-lg">
                  <!--begin::Svg Icon | path:assets/media/svg/icons/General/Search.svg-->
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect x="0" y="0" width="24" height="24"></rect>
                      <path
                        d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                        fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                      <path
                        d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                        fill="#000000" fill-rule="nonzero"></path>
                    </g>
                  </svg>
                  <!--end::Svg Icon-->
                </span>
              </span>
            </div>
            <input type="text" class="form-control h-40px" placeholder="Search...">
            <div class="input-group-append">
              <span class="input-group-text">
                <i class="quick-search-close ki ki-close icon-sm" style="display: none;"></i>
              </span>
            </div>
          </div>
        </form>
        <div id="kt_quick_search_toggle" data-toggle="dropdown" data-offset="0px,0px" aria-expanded="true"></div>
        <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg dropdown-menu-anim-up">
          <div class="scroll">
            <div class="quick-search-result">
            

            </div>
            <div class="ps__rail-x" style="left: 0px; bottom: 0px;">
              <div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div>
            </div>
            <div class="ps__rail-y" style="top: 0px; right: 0px;">
              <div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 0px;"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import Drawer from '@components/admin/dashboard/Drawer.vue'

export default {
  name: '',
  components: {
    Drawer
  },
}
</script>
