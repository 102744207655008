import { authenticared } from '@middlewares/authenticated.js'
import Dashboard from '@layouts/Dashboard.vue'
import Index from '@views/admin/Index.vue'

export default [
  /* Rutas variadas */
  {
      path: '/admin/dashboard',
      component: Dashboard,
      /* Rutas a partir del path principal */
      children: [
        {
          path: '/admin', /* Redireccionamos en caso de ir
          a la ruta principal */
          redirect: '/admin/dashboard'
        },
        {
          path: '', /* Ruta por defecto '/' pero no se coloca el slash */
          name: 'index-dashboard',
          component: Index,
          beforeEnter: [authenticared], /* middlewares */
        },
      ],
    },
  {
    path: '/admin/dashboard/modules', /* Redireccionamos en
    caso de ir a esta ruta */
    redirect: '/admin/dashboard'
  },
  {
    path: '/admin/dashboard/modules/types', /* Redireccionamos en
    caso de ir a esta ruta */
    redirect: 'admin/dashboard'
  },
]
