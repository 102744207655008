let formEl, fv, validatorTime

let validate = {
  fields: {
    registration_price: {
      validators: {
        notEmpty: {
          message: 'Porfavor ingresa un precio de suscripción'
        }
      }
    },
    shipping_price: {
      validators: {
        notEmpty: {
          message: 'Debe proporcionar un precio de envio'
        }
      }
    },
    headquarter_id: {
      validators: {
        notEmpty: {
          message: 'Debe proporcionar una sede'
        }
      }
    },
    currency_id: {
      validators: {
        notEmpty: {
          message: 'Porfavor selecciona una moneda'
        }
      }
    },
    study_id: {
      validators: {
        notEmpty: {
          message: 'Debe proporcionar un estudio'
        }
      }
    },
  },
  plugins: {
    trigger: new FormValidation.plugins.Trigger(),
    bootstrap: new FormValidation.plugins.Bootstrap({
      //eleInvalidClass: '',
      eleValidClass: '',
    })
  },
}

let numberValidate = {
  validate: {
    required: true,
    number: true
  },
  message: {
    required: "Este campo es requerido",
    number: "Solo se permiten numeros"
  }
}
let validateTime = (index = 0) => ({
  type: {
    validate: {
      required: true
    },
    message: {
      required: "Porfavor selecciona un tiempo",
    }
  },
  price: numberValidate,
  number: numberValidate,
  from: {
    validate: {
      required: true,
      number: true,
      minTo: `times[${index}].to`
    },
    message: {
      required: "Este campo es requerido",
      number: "Solo se permiten numeros"
    }
  },
  to: {
    validate: {
      required: true,
      number: true,
      maxFrom: `times[${index}].from`
    },
    message: {
      required: "Este campo es requerido",
      number: "Solo se permiten numeros"
    }
  },
  min: numberValidate,
})

const errorRegisterForm = () => {
  Swal.fire({ //Notificacion de institución no registrada
    text: "No se pudo agregar el nuevo programa de estudio, intente en unos minutos",
    icon: "error",
    buttonsStyling: false,
    customClass: {
      confirmButton: "btn font-weight-bold btn-primary",
      cancelButton: "btn font-weight-bold btn-default"
    }
  })
}
const successRegisterForm = () => {
  Swal.fire({ //Notificacion de institución registrada correctamente
    text: "Programa de estudio agregado!",
    icon: "success",
    buttonsStyling: false,
    customClass: {
      confirmButton: "btn font-weight-bold btn-primary",
      cancelButton: "btn font-weight-bold btn-default"
    }
  })
}

let KTContactsRegister = function() {

  let _initValidation = function(RegisterStudyProgram) {
    fv = FormValidation.formValidation(formEl, validate)

    validatorTime = $(formEl).validate({
      debug: false,
      rules: {},
      messages: {},
      submitHandler: (form, e) => e.preventDefault(),
      errorElement: 'div',
      errorClass: 'fv-help-block w-100 h-100',
      highlight: function(element, errorClass) {
        $(element).removeClass(errorClass);
        $(element).addClass('is-invalid');
      },
      success: function(label, element) {
        $(element).removeClass('is-invalid');
      }
    })

    $('#register-form-study-program').on('click', function() {

      fv.validate().then(function(status) {

        let modality = $('#modalityTimesAdd')[0].dataset.modality
        if (status == 'Valid' && validatorTime.form()) {

          if (modality == 0) {
            $('#modalityTimesAdd').removeClass('d-none');
          } 
          else {
            $('#modalityTimesAdd').addClass('d-none');
            RegisterStudyProgram({
              successRegisterForm,
              errorRegisterForm,
            })
          }
        }
      })
    })
  }

  return {
    init: function(RegisterStudyProgram) {
      _initValidation(RegisterStudyProgram)
    }
  }
}()

export default (RegisterStudyProgram) => {
  $(function() {
    formEl = KTUtil.getById('validate_register_study_program')
    KTContactsRegister.init(RegisterStudyProgram)
  })
}

export const resetForm = () => fv.resetForm(true)

export const addTimes = function(rowIndex) {
  const fields = ['type', 'from', 'to', 'min', 'number', 'price']
  for (const field of fields) {
    validatorTime.settings.rules[`times[${rowIndex}].${field}`] = validateTime(rowIndex)[field].validate
    validatorTime.settings.messages[`times[${rowIndex}].${field}`] = validateTime(rowIndex)[field].message
  }
};
