/* Utiliades y ayudantes */
import { getMenyPersisten, setMenyPersisten, deleteMenyPersisten } from '@tools/util'

/* Datos a obtener, guardar y eliminar.
Creamos esta variable para no tener que
definir varias veces los nombres */
let auth = {
  expires_in:'',
  refresh_token:'',
  token_type:'',
  access_token:''
}

export default {
  namespaced: true, //hace que sea global el modulo en vuex
  state: () => ({
    user: null, /* Datos del usuario
    cuando se logea */
    auth: getMenyPersisten(auth), /* Datos
    de la session de login */
    status:false, /* Estado de la session */
  }),
  getters:{
    /* Obtenemos el estado de la session
    de forma reactiva cuando haya cambios */
    isAuth: (store) => store.status
  },
  mutations: {

    /* Guardar session de login */
    SAVE_SESSION(state, payload) {
      state.auth = payload /* almacenamos temporalmente */
      state.status = true /* Login activo */
      setMenyPersisten(payload) /* insertamos los
      datos de manera permanente */
    },
    /*****----------------------------------------------------*****/

    /* Eliminar session de login */
    DELETE_SESSION(state) {
      deleteMenyPersisten(state.auth) /* Removemos los
      datos que eran permanentes */
      state.status = false /* desactivamos el login */
      state.auth = null /* borramos los datos del 'auth' */
    },
    /*****----------------------------------------------------*****/
  },
  actions: {
    /* Guardar la session */
    SaveSession({ commit }, payload) {
      commit('SAVE_SESSION', payload) /* Guardamos */
    },
    /* Destruir la session */
    DeleteSession({ commit }) {
      commit('DELETE_SESSION') /* Eliminamos */
    },
  }
}
