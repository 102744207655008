<template>
<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" :width="size" :height="size" viewBox="0 0 172 172" :style="`fill:${color};`">
  <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none"
    text-anchor="none" style="mix-blend-mode: normal">
    <path d="M0,172v-172h172v172z" fill="none"></path>
    <g>
      <path d="M46.47583,57.44083h78.83333v75.25h-78.83333z" fill="#5d4037"></path>
      <path d="M35.83333,68.08333h10.75v57.33333h-10.75zM75.25,68.08333l-10.75,0.02867l0.05017,57.33333l10.69983,-0.01433zM107.5,68.08333l-10.75,-0.01433l0.05017,57.33333l10.69983,0.01433zM125.41667,68.08333h10.75v57.33333h-10.75z" fill="#d7ccc8">
      </path>
      <path
        d="M143.33333,60.85933c0,1.97083 -1.59458,3.63708 -3.58333,3.64067h-107.48567c-1.978,0.00358 -3.58333,-1.5265 -3.58692,-3.50092l-0.01075,-10.71058c-0.00358,-1.97442 1.59817,-3.57258 3.57617,-3.57258l107.49283,-0.13258c1.98158,-0.00358 3.57617,1.59458 3.58333,3.56542z"
        fill="#8d6e63"></path>
      <g fill="#bcaaa4">
        <path
          d="M150.5,150.5h-128.92117l-0.07883,-10.75h129zM50.14158,64.543l0.00358,3.58333c0.00358,1.98158 -1.59817,3.53675 -3.57975,3.54033h-10.75c-1.978,0 -3.56183,-1.53725 -3.56542,-3.51525v-3.58333zM78.82975,64.5l0.00358,3.56183c0.00358,1.98158 -1.59817,3.60125 -3.57975,3.60483h-10.75c-1.978,0 -3.58333,-1.59817 -3.58692,-3.57975v-3.58692zM111.05467,64.5l0.00717,3.55108c0,1.98158 -1.60175,3.61558 -3.57975,3.61558h-10.75c-1.978,0.00358 -3.56542,-1.60892 -3.56542,-3.5905v-3.57617zM139.75,64.5v3.51525c0,1.98158 -1.62325,3.65142 -3.60125,3.65142h-10.75c-1.978,0.00358 -3.56542,-1.64833 -3.56542,-3.62992v-3.53675zM50.16667,129.043v-3.58333c-0.00358,-1.98158 -1.56233,-3.62992 -3.54033,-3.62633h-10.75c-1.978,0 -3.62992,1.66983 -3.62633,3.65142v3.58333zM78.83333,129v-3.57617c-0.00358,-1.98158 -1.56233,-3.57975 -3.54033,-3.57617l-10.75,0.01433c-1.978,0 -3.58333,1.60533 -3.57975,3.58692l0.00717,3.55108zM111.08333,129v-3.61558c-0.00358,-1.98158 -1.60892,-3.55108 -3.5905,-3.55108h-10.75c-1.978,0.00358 -3.57975,1.59458 -3.57617,3.57617l0.00358,3.5905zM139.75,129l-0.00358,-3.65142c-0.00358,-1.978 -1.60892,-3.51525 -3.5905,-3.51525h-10.75c-1.978,0.00358 -3.57617,1.55875 -3.57258,3.53675v3.62992zM150.5,50.16667l-64.57883,-28.66667l-64.42117,28.74192v7.09142h129z">
        </path>
      </g>
      <g fill="#a1887f">
        <path d="M28.72758,129h114.59858v10.75h-114.59858zM39.37367,50.16667h93.16667l-46.612,-21.5z"></path>
      </g>
    </g>
  </g>
</svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#000000',
    },
    size: {
      type: String,
      default: '24',
    }
  }
}
</script>
