<template>
<li class="menu-item menu-item-submenu menu-item-rel menu-item-open-dropdown" data-menu-toggle="click" aria-haspopup="true" :class="{'menu-item-active': menuActive}">
  <a href="#" class="menu-link menu-toggle">
    <span class="navi-icon pr-2"><i class="la la-box text-white"></i></span>
    <span class="menu-text">Modulos</span>
    <span class="menu-desc"></span>
    <i class="menu-arrow"></i>
  </a>
  <div class="menu-submenu menu-submenu-classic menu-submenu-left" data-hor-direction="menu-submenu-left">
    <ul class="menu-subnav">
      <MenuInstitution />
      <MenuHeadquarter />
      <MenuStudyProgram />
      <MenuStudy />
      <MenuTypeInstitution />
      <MenuTypeAccount />

      <!-- <router-link custom :to="{name:'test-institution'}" v-slot="{ navigate, href, isExactActive }">
        <li class="menu-item"
        :class="{'menu-item-active': subMenu==false ? subMenu=isExactActive: isExactActive }" aria-haspopup="true">
          <a :href="href" @click="navigate" class="menu-link">
            <span class="svg-icon menu-icon">
              <i class="fa la-th-list icon-sm"></i>
            </span>
            <span class="menu-text">Test pages</span>
          </a>
        </li>
      </router-link> -->
    </ul>
  </div>
</li>

</template>

<script>
import MenuTypeInstitution from './modules/MenuTypeInstitution'
import MenuHeadquarter  from './modules/MenuHeadquarter'
import MenuInstitution from './modules/MenuInstitution'
import MenuTypeAccount from './modules/MenuTypeAccount'
import MenuStudyProgram from './modules/MenuStudyProgram'
import MenuStudy from './modules/MenuStudy'

export default {
  components:{
    MenuTypeInstitution,
    MenuStudyProgram,
    MenuHeadquarter,
    MenuInstitution,
    MenuTypeAccount,
    MenuStudy,
  },
  data(){
    return {
      subMenu:false,
    }
  },
  computed:{
    menuActive(){
      return this.$route.path.includes('modules')
    }
  }
}
</script>

<style>

</style>
