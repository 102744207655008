let formEl, fv, validatorTime

let validate = {
  fields: {
    registration_price: {
      validators: {
        notEmpty: {
          message: 'Porfavor ingresa un precio de suscripción'
        }
      }
    },
    shipping_price: {
      validators: {
        notEmpty: {
          message: 'Debe proporcionar un precio de envio'
        }
      }
    },
  },
  plugins: {
    trigger: new FormValidation.plugins.Trigger(),
    bootstrap: new FormValidation.plugins.Bootstrap({
      //eleInvalidClass: '',
      eleValidClass: '',
    })
  },
}
let numberValidate =  {
  validate: {
    required: true,
    number: true
  },
  message: {
    required: "Este campo es requerido",
    number: "Solo se permiten numeros"
  }
}

let validateTime = (index=0) => ({
  type: {
    validate: { required: true },
    message: { required: "Porfavor selecciona un tiempo", }
  },
  price: numberValidate,
  number: numberValidate,
  from: {
    validate: {
      required: true,
      number: true,
      minTo: `times[${index}].to`
    },
    message: {
      required: "Este campo es requerido",
      number: "Solo se permiten numeros"
    }
  },
  to: {
    validate: {
      required: true,
      number: true,
      maxFrom: `times[${index}].from`
    },
    message: {
      required: "Este campo es requerido",
      number: "Solo se permiten numeros"
    }
  },
  min: numberValidate,
})

const differentUpdateForm = () => {
  Swal.fire({ //Notificacion de institución registrada correctamente
    text: "No hay datos modificados",
    icon: "warning",
    buttonsStyling: false,
    customClass: {
      confirmButton: "btn font-weight-bold btn-primary",
      cancelButton: "btn font-weight-bold btn-default"
    }
  })
}
const errorUpdateForm = () => {
  Swal.fire({ //Notificacion de institución no registrada
    text: "No se pudo Actualizar el programa de estudio, intente en unos minutos",
    icon: "error",
    buttonsStyling: false,
    customClass: {
      confirmButton: "btn font-weight-bold btn-primary",
      cancelButton: "btn font-weight-bold btn-default"
    }
  })
}
const successUpdateForm = () => {
  Swal.fire({ //Notificacion de institución registrada correctamente
    text: "Programa de estudio actualizada!",
    icon: "success",
    buttonsStyling: false,
    customClass: {
      confirmButton: "btn font-weight-bold btn-primary",
      cancelButton: "btn font-weight-bold btn-default"
    }
  })
}

let KTContactsUpdate = function() {

  let _initValidation = function(UpdateStudyProgram) {
    fv = FormValidation.formValidation(formEl, validate)

    validatorTime = $(formEl).validate({
        debug: false,
        rules: {
          'times[0].number' : validateTime().number.validate,
          'times[0].price' : validateTime().price.validate,
          'times[0].type' : validateTime().type.validate,
          'times[0].from' : validateTime().from.validate,
          'times[0].min' : validateTime().min.validate,
          'times[0].to' : validateTime().to.validate,
        },
        messages: {
          'times[0].number' : validateTime().number.message,
          'times[0].price': validateTime().price.message,
          'times[0].type': validateTime().type.message,
          'times[0].from' : validateTime().from.message,
          'times[0].min' : validateTime().min.message,
          'times[0].to' : validateTime().to.message,
        },
        submitHandler: (form, e) => e.preventDefault(),
        errorElement : 'div',
        errorClass: 'fv-help-block w-100 h-100',
        highlight: function(element, errorClass) {
          $(element).removeClass(errorClass);
          $(element).addClass('is-invalid');
        },
        success: function(label, element) {
          $(element).removeClass('is-invalid');
        }
    })

    $('#update-form-study-program').on('click', function() {

      fv.validate().then(function(status) {

        let modality = $('#modalityTimes')[0].dataset.modality
        if (status == 'Valid' && validatorTime.form()) {

          if (modality == 0) {
            $('#modalityTimes').removeClass('d-none');
          }
          else {
            $('#modalityTimes').addClass('d-none');
            UpdateStudyProgram({
              differentUpdateForm,
              successUpdateForm,
              errorUpdateForm,
            })
          }
        }
      })
    })
  }

  return {
    init: function(UpdateStudyProgram) {
      _initValidation(UpdateStudyProgram)
    }
  }
}()

export default (UpdateStudyProgram) => {
  $(function() {
    formEl = KTUtil.getById('validate_update_study_program')
    KTContactsUpdate.init(UpdateStudyProgram)
  })
}

export const resetForm = () => validation[0].resetForm(true)

// export const removeTimes = function(rowIndex) {
//
//   delete validatorTime.settings.rules[`times[${rowIndex}].type`]
//   delete validatorTime.settings.messages[`times[${rowIndex}].type`]
//
// };

export const addTimes = function(rowIndex) {
  const fields = ['type', 'from', 'to', 'min', 'number', 'price']
  if (rowIndex != 0) {
    for (const field of fields) {
      validatorTime.settings.rules[`times[${rowIndex}].${field}`] = validateTime(rowIndex)[field].validate
      validatorTime.settings.messages[`times[${rowIndex}].${field}`] = validateTime(rowIndex)[field].message
    }
  }
};
