import Guest from '@layouts/Guest.vue'

export default [
  /* Rutas de auteticación o desautenticación */
  {
    path: '/',
    component: Guest,
    /* Rutas a partir del path principal */
    children: [
      {
        path: '/',
        name: 'index',
        component: async () => await require('@views/Index.vue'),
      },
      {
        path: 'about',
        name: 'about',
        component: async () => await require('@views/About.vue'),
      },
      {
        path: 'contact',
        name: 'contact',
        component: async () => await require('@views/Contact.vue'),
      },
    ],
  },
]
