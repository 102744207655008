<template>
<div class="pb-5">
  <div class="row">
    <div class="col-xl-12">

      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label">Nombre</label>
        <div class="col-lg-9 col-xl-9">
          <input class="form-control form-control-lg form-control-solid" name="name" placeholder="Nombre de la sede" :value="headquarter.name" @input="UpdateFieldHeadquarter" />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label">Telefono</label>
        <div class="col-lg-9 col-xl-9">
          <input class="form-control form-control-lg form-control-solid" name="phone" placeholder="Telefono de la sede" :value="headquarter.phone" @input="UpdateFieldHeadquarter" />
        </div>
      </div>

      <Location type-store="newHeadquarter" />

      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label">Dirección</label>
        <div class="col-lg-9 col-xl-9">
          <input class="form-control form-control-lg form-control-solid" name="address" placeholder="Dirección de la sede" :value="headquarter.address" @input="UpdateFieldHeadquarter" />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label">Codigo postal</label>
        <div class="col-lg-2 col-xl-2">
          <input class="form-control form-control-lg form-control-solid" maxlength="9" name="postcode" placeholder="Codigo zip/postal" :value="headquarter.postcode" @input="UpdateFieldHeadquarter" />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label">Descripción - reseña historica</label>
        <div class="col-lg-9 col-xl-9">
          <textarea id="desc_headquarter" style="height: 125px" class="form-control form-control-lg form-control-solid" name="description" placeholder="Descripción o reseña historica" :value="headquarter.description" @input="UpdateFieldHeadquarter"></textarea>
        </div>
      </div>

      <UploadCover
        @input="UpdateImageHeadquarter"
        @delete="deleteImageHeadquarter"/>

      <UploadGallery />
    </div>
  </div>
</div>
</template>

<script>
import { resetForm } from '@components/admin/headquarter/register/formRegister.js'
import UploadGallery from '@components/admin/headquarter/UploadGallery.vue'
import UploadCover from '@components/admin/headquarter/UploadCover.vue'
import Location from '@components/admin/headquarter/Location.vue'
import { textEditor } from '@options/textEditor'
import { mapState, mapActions } from 'vuex'
import { trim } from 'lodash'

export default {
  components: {
    UploadCover,
    UploadGallery,
    Location,
  },
  data() {
    return {
      description: '',
    }
  },
  computed: {
    ...mapState({
      headquarter: ({ headquarter }) => headquarter.newHeadquarter,
      gallery: ({ headquarter }) => headquarter.gallery,
    }),
  },
  methods: {
    ...mapActions('global', ['EditFieldState', 'DelFieldState']),

    UpdateFieldHeadquarter(e) {
      this.EditFieldState({
        state: 'headquarter',
        type: 'newHeadquarter',
        field: e.target.name,
        value: trim(e.target.value),
      })
    },

    UpdateImageHeadquarter(e) {
      this.EditFieldState({
        state: 'headquarter',
        type: 'newHeadquarter',
        field: e.target.name,
        value: e.target.files[0],
      })
    },

    deleteImageHeadquarter(name) {
      this.DelFieldState({
        state: 'headquarter',
        type: 'newHeadquarter',
        field: name,
      })
    },
  },
  deactivated(){
    /* Da un error mientras no esten todos los campos para el validar y resetear */
    resetForm()
  },
  mounted() {
    textEditor('desc_headquarter')
  }
};
</script>
