<template>
  <div class="sm:grid sm:grid-cols-2 md:block gap-4">
    <div class="max-w-lg md:max-w-4xl md:flex mt-4 shadow-md border border-gray-300 rounded-md relative" v-for="(headquarter, index_he) in result" :key="index_he" style="min-width:180px">
      <slot :headquarter="headquarter" :index="index_he"></slot>
    </div>
  </div>
  <div class="flex justify-center mt-8 mb-9 space-x-2 text-base font-semibold text-gray-400 items-center">
    <button class="py-1 px-3 bg-gray-200 rounded" @click="prev" v-show="currentPage!=1"> Prev </button>
    <template v-for="page in lastPage">
      <button href="#" class="py-1 px-3 bg-gray-200 rounded" :class="{'text-gray-600' : currentPage==page}" @click="currentPage=page">
        {{page}}
      </button>
    </template>
    <a class="py-1 px-2 bg-gray-200 rounded" @click="next" v-show="lastPage!=currentPage"> Next </a>
    <p class="">page {{ currentPage }} of {{ lastPage }}</p>
  </div>
</template>

<script>
import { useArrayPagination } from "vue-composable";
import {computed} from 'vue'

export default {
  props:{
    items:{
      type: Array,
      default: [],
    },
  },
  setup(props) {
    let items = computed(()=> props.items)

    let paginate = useArrayPagination(items, { pageSize: 3 });

    return paginate
    /* return {
      result,
      next,
      prev,
      offset,
      total,
      currentPage,
      lastPage
    }; */
  }
};
</script>
