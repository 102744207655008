let validation = []
let validate = {
	fields: {
		title: {
			validators: {
				notEmpty: {
					message: 'Porfavor ingresa el titulo del estudio'
				}
			}
		},
	},
	plugins: {
		trigger: new FormValidation.plugins.Trigger(),
		// Bootstrap Framework Integration
		bootstrap: new FormValidation.plugins.Bootstrap({
			//eleInvalidClass: '',
			eleValidClass: '',
		})
	}
}
let formEl, customClass = {
	confirmButton: "btn font-weight-bold btn-primary",
	cancelButton: "btn font-weight-bold btn-default"
}

let KTContactsUpdate = function() {

  let _initValidation = function(UpdateStudy) {

		validation.push(FormValidation.formValidation(formEl, validate))

    $('#update-form-study').on('click', function () {
	    validation[0].validate().then(function(status) {
				const differentUpdateForm = () => {
					Swal.fire({ //Notificacion de institución registrada correctamente
            text: "No hay datos modificados",
            icon: "warning",
            buttonsStyling: false,
            customClass
          })
				}
				const errorUpdateForm = ()=>{
					Swal.fire({ //Notificacion de institución no registrada
						text: "No se pudo Actualizar el estudio, intente en unos minutos",
						icon: "error",
						buttonsStyling: false,
						customClass
					})
				}
				const successUpdateForm = ()=>{
					$("#btnDeleteLogo").trigger("click")

					Swal.fire({ //Notificacion de institución registrada correctamente
						text: "Estudio actualizado!",
						icon: "success",
						buttonsStyling: false,
						customClass
					})
				}
	      if (status == 'Valid') {
					UpdateStudy({
						successUpdateForm,
						errorUpdateForm,
						differentUpdateForm
					})
	      }
	    })
    })
  }

  return {
    init: function(UpdateStudy) {
      _initValidation(UpdateStudy)
    }
  }
}()

export default (UpdateStudy) => {
  $(function() {
    formEl = KTUtil.getById('validate_update_study')
    KTContactsUpdate.init(UpdateStudy)
  })
}

export const resetForm = () => validation[0].resetForm(true)
