<template>
<div class="pb-5">
  <div class="row">
    <div class="col-xl-12">
      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label">Nombre del tipo de cuenta</label>
        <div class="col-lg-9 col-xl-9">
          <input class="form-control form-control-lg form-control-solid" name="name" type="text" placeholder="Instituto" :value="typeInstitution?.name" @input="UpdateFieldTypeInstitution"/>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
/* Utilidades y ayudantes */
import {mapState, mapActions} from 'vuex'
import {trim} from 'lodash'

export default {
  computed: {
    /* Llamamos a N cantidad de modulos del store */
    ...mapState({
      typeInstitution: ({typeInstitution}) => typeInstitution.typeInstitution
    }),
  },
  methods: {
    /* Llamamos a N cantidad de acciones del store */
    ...mapActions('global', ['EditFieldState', 'WatcherChangeInState']),

    /* Funcion para actualizar el input cuando se hacen cambios */
    UpdateFieldTypeInstitution(e) {

      /* Guarda cambios en el store */
      this.EditFieldState({
        state: 'typeInstitution',
        type: 'typeInstitution',
        field: e.target.name,
        value: trim(e.target.value),
      })

      /* Enviamos la modificacion al state para 
      mostrar que hay un cambio y verificar */
      this.WatcherChangeInState({
        state: 'typeInstitution',
        type:{
          copy:'copyTypeInstitution',
          mutated:'typeInstitution',
        }
      })
    }
  },
};
</script>
