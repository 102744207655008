<template>

<HeaderBackground />

<div class="pt-10 px-1">
  <div class="lg:flex lg:space-x-10 pt-5">

    <LeftActionBar/>

    <Content/>

    <RightActionBar/>

  </div>
</div>
</template>

<script>
import HeaderBackground from '@components/guest/profileInstitution/HeaderBackground.vue'
import RightActionBar from '@components/guest/profileInstitution/RightActionBar.vue'
import LeftActionBar from '@components/guest/profileInstitution/LeftActionBar.vue'
import Content from '@components/guest/profileInstitution/Content.vue'
import {mapActions} from 'vuex'

export default {
  components: {
    HeaderBackground,
    RightActionBar,
    LeftActionBar,
    Content,
  },
  methods:{
    ...mapActions('headquarter', ['ShowAllHeadquarters']),
    ...mapActions('study', ['ShowAllStudies']),
    ...mapActions('currency', ['ShowAllCurrencies']),
  },
  mounted(){
    this.ShowAllHeadquarters()
    this.ShowAllCurrencies()
    this.ShowAllStudies()
  },
}
</script>
